import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Base64HelperService } from "../service/base64-helper.service";
import { Subject } from "rxjs";
import { finalize, takeUntil } from "rxjs/operators";
import { BfcNotificationService, BfcNotificationType } from "@bfl/components/notification";
import { HttpErrorResponse } from "@angular/common/http";
import { BfcTranslationService } from "@bfl/components/translation";
import { DocHubDocumentService } from "../service/doc-hub-document.service";

@Component({
  selector: "app-document-download-component",
  templateUrl: "document-download.component.html",
  styleUrls: ["document-download.component.scss"],
})
export class DocumentDownloadComponent implements OnInit, OnDestroy {

  private unsubscribe: Subject<void> = new Subject();

  loading: boolean = true;

  constructor(private activatedRoute: ActivatedRoute,
    private base64HelperService: Base64HelperService,
    private bfcNotificationService: BfcNotificationService,
    private bfcTranslationService: BfcTranslationService,
    private docHubDocumentService: DocHubDocumentService) {
  }

  ngOnInit(): void {
    this.openDocument();
  }

  openDocument(): void {
    this.loading = true;
    const documentId: string = this.activatedRoute.snapshot.params.documentId;
    this.docHubDocumentService.getDocumentContent(documentId)
      .pipe(takeUntil(this.unsubscribe), finalize(() => this.loading = false))
      .subscribe((base64File: string) => {
        this.base64HelperService.openBase64(base64File, null);
      },
      (error: unknown) => {
        if (error instanceof HttpErrorResponse && error.status === 404) {
          this.showError(this.bfcTranslationService.translate("DOCUMENT_DOWNLOAD.NOT_FOUND"));
        } else {
          this.showError(this.bfcTranslationService.translate("DOCUMENT_DOWNLOAD.ERROR"));
        }
      });
  }

  private showError(message: string) {
    this.bfcNotificationService.showNotification({
      type: BfcNotificationType.ERROR,
      message: message,
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
