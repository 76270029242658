import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";

@Injectable()
export class DocumentUpdateService {
  private documentsLoadedSource: Subject<void> = new Subject<void>();

  private updateCounter: number = 0;

  private totalUpdatesNeeded: number = 0;

  documentsLoaded$: Observable<void> = this.documentsLoadedSource.asObservable();

  setTotalUpdatesNeeded(totalUpdatesNeeded: number): void {
    this.totalUpdatesNeeded = totalUpdatesNeeded;
    this.updateCounter = 0;

    if (this.totalUpdatesNeeded === 0) {
      this.documentsLoadedSource.next();
    }
  }

  notifyDocumentUpdateCompleted(): void {
    this.updateCounter++;

    if (this.updateCounter == this.totalUpdatesNeeded) {
      this.documentsLoadedSource.next();
    }
  }
}