/**
 * ZEV Api
 * Zusammenschluss Eigenverbrauch API
 *
 * The version of the OpenAPI document: v1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ContactDetails { 
    accountId?: string;
    city?: string;
    company?: string;
    contactType?: ContactDetails.ContactTypeEnum;
    email?: string;
    emailValidation?: string;
    firstName?: string;
    houseNumber?: string;
    lastName?: string;
    legalSubjectType?: ContactDetails.LegalSubjectTypeEnum;
    postalCode?: string;
    residentialUnit?: string;
    soleOwnership?: ContactDetails.SoleOwnershipEnum;
    street?: string;
    telephone?: string;
}
export namespace ContactDetails {
    export type ContactTypeEnum = 'CONTACT_PERSON' | 'LANDOWNER' | 'INSTALLER' | 'INVOICE_RECIPIENT';
    export const ContactTypeEnum = {
        ContactPerson: 'CONTACT_PERSON' as ContactTypeEnum,
        Landowner: 'LANDOWNER' as ContactTypeEnum,
        Installer: 'INSTALLER' as ContactTypeEnum,
        InvoiceRecipient: 'INVOICE_RECIPIENT' as ContactTypeEnum
    };
    export type LegalSubjectTypeEnum = 'COMPANY' | 'PRIVATE';
    export const LegalSubjectTypeEnum = {
        Company: 'COMPANY' as LegalSubjectTypeEnum,
        Private: 'PRIVATE' as LegalSubjectTypeEnum
    };
    export type SoleOwnershipEnum = 'YES' | 'NO';
    export const SoleOwnershipEnum = {
        Yes: 'YES' as SoleOwnershipEnum,
        No: 'NO' as SoleOwnershipEnum
    };
}


