<bfc-loading-overlay [loading]="loading">
    <div class="container" fxLayoutAlign="center center">
        <div *ngIf="!loading" fxLayout="column">
            <div fxLayoutAlign="center center">
                <h4>{{ "DOCUMENT_DOWNLOAD.HINT" | bfcTranslate }}</h4>
            </div>
            <p>{{ "DOCUMENT_DOWNLOAD.HINT_TEXT_1" | bfcTranslate }}</p>
            <p>{{ "DOCUMENT_DOWNLOAD.HINT_TEXT_2" | bfcTranslate }}</p>
            <div class="bfc-margin-top" fxLayoutAlign="center center">
                <button mat-button color="primary" (click)="openDocument()">{{ "DOCUMENT_DOWNLOAD.OPEN_DOCUMENT" | bfcTranslate }}</button>
            </div>
        </div>
    </div>
</bfc-loading-overlay>