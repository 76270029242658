import { Injectable } from "@angular/core";

@Injectable()
export class UrlHelperService {

  public getParameterValue(searchParam: string): string {
    const url = window.location.href;

    let paramValue = "";
    if (url && url.indexOf(searchParam) >= 0) {
      const urlQuery = url.split("?");
      if (urlQuery && urlQuery.length > 1){ // we expect 2 entries: the uri path and the queryString
        const keyValuePairs = urlQuery[1].split("&"); // only look at queryString
        if (keyValuePairs && keyValuePairs.length > 0){
          for (let keyValue of keyValuePairs) {
            if (keyValue.startsWith(searchParam)) {
              const splitted: string[] = keyValue.split("=");
              paramValue = splitted && splitted.length > 1 ? splitted[1] : "";
              break;
            }
          }
        }
      }
    }
    return paramValue;
  }
}