export const DE_TRANSLATIONS = {
  BFC_UNAUTHORIZED: {
    TITLE: "Kein Zugriff",
    CONTENT: "Der Service steht Ihnen leider nicht zur Verfügung.",
  },
  CONTACT: {
    TITLE: "Kontakt Kundenservicecenter (CSC):",
    PHONE: "<a href='tel:+41844121113'>+41 844 121 113</a>",
    EMAIL: "<a href='mailto:kundenservice@bkw.ch'>kundenservice@bkw.ch</a>",
  },
  APPLICATION_CREATION_STEPPER: {
    LABELS: {
      CONNECTION_OBJECT: "Angaben",
      LANDOWNERS: "Grundeigentümer",
      INSTALLER: "Konzessionierter Elektroinstallateur",
      APPLICATION_BASICS: "Grundlagen des Antrags",
      SUBMISSION: "Einreichung Antragsformular",
    },
  },
  ONBOARDING: {
    TITLE: "Einen kurzen Augenblick...",
  },
  CONNECTION_OBJECT: {
    INTRO_1: "Antragsformular zur Bildung eines Zusammenschlusses zum Eigenverbrauch (ZEV)",
    INTRO_2: "Herzlich Willkommen – Folgend können Sie den Antrag zur Bildung eines Zusammenschlusses zum Eigenverbrauch ausfüllen und bei Ihrer konzessionierten Elektroinstallateurin oder Ihrem konzessionierten Elektroinstallateur einreichen.",
    INTRO_3: "Sie sind verpflichtet, sämtliche Angaben vollständig und wahrheitsgetreu zu machen. Wenn Sie am Ende des Antragsformulars auf “Antrag abschicken” klicken, wird dieses an Ihre beauftragte Elektroinstallateurin oder Ihren beauftragten Elektroinstallateur weitergeleitet. Ihre konzessionierte Elektroinstallateurin oder Ihr Elektroinstallateur hat dann Ihr Antragsformular mit den entsprechenden Meldeformularen (Installationsanzeige mit Messschema und Situationsplan) zu ergänzen und bei uns einzureichen. Erst nach Eingang aller Unterlagen durch Ihre konzessionierte Elektroinstallateurin oder Ihren Elektroinstallateur prüfen wir den Antrag zur Bildung eines Zusammenschlusses zum Eigenverbrauch. Wir stellen die Bewilligung des Antrags nur Ihrer konzessionierten Elektroinstallateurin oder Ihrem Elektroinstallateur zu. Den uns übermittelten Antrag können Sie nicht mehr ändern. Wird Ihr Antrag bewilligt, sind uns allfällige Änderungen mittels Mutationsmeldung in diesem Portal mitzuteilen.",
    DETAILS: "Angaben zum Anschlussobjekt und zum Ansprechpartner des Zusammenschlusses zum Eigenverbrauch",
    REQUEST: "Die nachfolgenden Grundeigentümerinnen und Grundeigentümer beantragen bei der BKW Energie AG (nachfolgend BKW genannt) einen Zusammenschluss zum Eigenverbrauch (ZEV) gemäss Artikel 17 des Energiegesetzes (EnG; SR 730.0) an folgender Adresse zu bilden:",
    CONNECTION_OBJECT_DATA: "Anschlussobjekt des Zusammenschlusses zum Eigenverbrauch:",
    TOOLTIP: "Jeder Zusammenschluss zum Eigenverbrauch ist gesetzlich verpflichtet, dem Verteilnetzbetreiber (nachfolgend der BKW) eine Ansprechpartnerin oder einen Ansprechpartner zu melden. Das Vertragsverhältnis wird über diese(n) abgewickelt. Konkret bedeutet dies, dass insbesondere die Avisierung von Stromunterbrüchen, die Zustellung der Stromrechnungen sowie die übrige Korrespondenz nur an die Ansprechpartnerin oder den Ansprechpartner des Zusammenschlusses zum Eigenverbrauch erfolgt. Die Grundeigentümerinnen und Grundeigentümer oder sonstige Teilnehmer des Zusammenschlusses werden von der BKW mit Ausnahme der Aufforderung zur periodischen Kontrolle nicht kontaktiert. Die Ansprechpartnerin oder der Ansprechpartner verfügt des Weiteren über die Berechtigung, der BKW Mutationen zu melden (wie unter anderem der Wechsel der Grundeigentümer oder des Rechnungsempfängers).",
    ADDRESS: {
      STREET: "Strasse",
      HOUSE_NUMBER: "Hausnummer",
      ZIP: "PLZ",
      CITY: "Ort",
      CITY_HINT: "* bei Auslandsanschriften bitte Ort und Land angeben",
    },
    LANDOWNER_TITLE: "Ansprechpartnerin/Ansprechpartner des Zusammenschlusses zum Eigenverbrauch",
    LANDOWNER_INFO: "Die Grundeigentümerinnen und Grundeigentümer bevollmächtigen folgende Ansprechpartnerin oder folgenden Ansprechpartner, den Zusammenschluss und somit ihre Interessen im Zusammenhang mit dem Zusammenschluss zum Eigenverbrauch gegenüber der BKW verbindlich zu vertreten und wahrzunehmen:",
    CONTACT_DETAILS: {
      COMPANY: "Firma/Verwaltung",
      LAST_NAME: "Name",
      FIRST_NAME: "Vorname",
      STREET: "Strasse",
      HOUSE_NUMBER: "Hausnummer",
      ZIP: "PLZ",
      CITY: "Ort",
      CITY_HINT: "* bei Auslandsanschriften bitte Ort und Land angeben",
      EMAIL: "E-Mail",
      EMAIL_CONFIRMATION: "Bestätigung E-Mail",
      TELEPHONE: "Telefonnummer",
      LEGAL_SUBJECT: "Firma/Verwaltung oder Privatperson",
      LEGAL_SUBJECTS: {
        COMPANY: "Firma/Verwaltung",
        PRIVATE: "Privatperson",
      },
    },
    ADD_BILLING_PERSON: "Rechnungsempfänger erfassen",
    ADD_BILLING_PERSON_TOOLTIP: "Die Änderung der Rechnungsempfängerin oder des Rechnungsempfängers hat keine Auswirkungen auf das vorliegende Vertragsverhältnis. Alle sonstigen Korrespondenzen zwischen der BKW und dem Zusammenschluss zum Eigenverbrauch erfolgen weiterhin an die Ansprechpartnerin oder den Ansprechpartner.",
    BILLING_PERSON_1: "Die Rechnungen und Vergütung der Überschussenergie für den Zusammenschluss zum Eigenverbrauch erfolgen standardgemäss an die Ansprechpartnerin oder den Ansprechpartner.",
    BILLING_PERSON_2: "Sofern die Rechnungsstellung an eine andere Person / Firma erfolgen soll, ist dies nachfolgend zu beantragen:",
    BILLING_CHECKBOX: "Die Rechnungsempfängerin / der Rechnungsempfänger entspricht der Ansprechpartnerin / dem Ansprechpartner",
  },
  LANDOWNERS: {
    SUBTITLE: "Grundeigentümerinnen und Grundeigentümer, welche einen Zusammenschluss zum Eigenverbrauch bilden",
    SUBTITLE_TOOLTIP: "Für jede Grundeigentümerin und jeden Grundeigentümer ist ein Eigentumsnachweis (Grundbuchauszug oder Auszug aus dem kantonalen Grundstückdaten-Informationssystem) hochzuladen.",
    REQUEST: "Nachfolgend sind für jede Verbrauchstätte (Wohnung, Gewerbe, Stall, etc.) die Grundeigentümerinnen und Grundeigentümer, Stockwerkeigentümerinnen und Stockwerkeigentümer, Baurechtsberechtigte (gemäss Grundbucheintrag) aufzuführen. Aus rechtlicher Sicht ist es notwendig, dass wir die einzelnen Verbrauchstätten (Wohnungen, Gewerbe, Stall, etc.) des Zusammenschlusses kennen und wer die jeweilige Grundeigentümerin und der jeweilige Grundeigentümer ist:",
    PLACE_OF_CONSUMPTION_1: "Verbrauchstätte 1",
    PLACE_OF_CONSUMPTION: "Verbrauchstätte",
    ADD_PLACE_OF_CONSUMPTION: "weitere Verbrauchstätte erfassen",
    REMOVE_PLACE_OF_CONSUMPTION: "Verbrauchstätte entfernen",
    PLACE_OF_CONSUMPTION_TOOLTIP: "Wohneinheit/Verbrauchstätte: Details zur Verbrauchstätte finden Sie bei bestehenden Gebäuden auf Ihrer Stromrechnung. Beispiele: Wohnung EG. Nr. 42, Studio EG rechts, Allgemein und Heizung, Attika DG links, Firma Muster AG, Restaurant Bären",
    PLACE_OF_CONSUMPTION_FORM: {
      RESIDENTIAL_UNIT: "Wohneinheit / Verbrauchstätte (EG, Stall, links/rechts.)",
      RESIDENTIAL_UNIT_LABEL: "Wohneinheit / Verbrauchstätte",
      TYPE: "Typ",
      TYPE_TOOLTIP: "Typ: Neubau: Bezieht sich auf den Neubau eines Wohnhauses, Gewerbes etc. Umbau/Änderung/Erweiterung: Bezieht sich auf den Umbau, Änderung bzw. Erweiterung eines bestehenden Wohnhauses, Gewerbes etc.",
      METER_NUMBER: "BKW Zählernummer",
      METER_NUMBER_TOOLTIP: "Die Zählernummer finden Sie auf Ihrer Stromrechnung. (wenn vorhanden)",
      SUPPLY_POINT: "BKW Bezugsstelle",
      SUPPLY_POINT_TOOLTIP: "Die Bezugsstelle finden Sie auf Ihrer Stromrechnung. Beispiel: Bezugsstelle: Nr. 123456, Musterstrasse 3, 3333 Musterhausen. Es kann entweder die Bezugsstellennummer oder eine textliche Bezeichnung der Verbrauchsstätte angegeben werden.",
      SOLE_OWNERSHIP: "Alleineigentum",
    },
    INFO: "Für alle Verbrauchstätten, die im Eigentum mehrerer Personen (bspw. Ehepaare, Geschwister) sind, müssen alle Personen einzeln aufgeführt werden.",
    LANDOWNER_DATA: "Angaben Grundeigentümer/In 1",
    LANDOWNER: "Grundeigentümer/In",
    ADD_LANDOWNER: "weitere Grundeigentümer/Innen erfassen",
    REMOVE_LANDOWNER: "Grundeigentümer/In entfernen",
    PRINT_POWER_OF_ATTORNEY: "Vollmacht / Erklärung Ansprechpartner Drucken",
    PRINT_MUTATION_LANDOWNER: "Mutationsmeldung Grundeigentümer Drucken",
    PLACE_OF_CONSUMPTION_TYPES: {
      NEW: "Neubau",
      RECONSTRUCTION: "Umbau/Änderung/Erweiterung",
    },
    SOLE_OWNERSHIPS: {
      YES: "Ja",
      NO: "Nein",
    },
    PROOF_OF_OWNERSHIP: "Eigentumsnachweis:",
    PROOF_OF_OWNERSHIP_REQUEST: "Wir bitten Sie folgend für jede Liegenschaft, welche Teil des Zusammenschlusses ist, ein Eigentumsnachweis jeder Grundeigentümerin und jedes Grundeigentümers mittels kantonalem Grundbuchauszug oder Auszug aus dem jeweiligen kantonalen Grundstückdaten-Informationssystem hochzuladen.",
    SIZE_ATTENTION: "Achtung:",
    SIZE_INFO: "Der Upload ist in der Dateigrösse limitiert! Eine Datei darf maximal 4 MB (Megabyte) gross sein. Insgesamt können maximal Anhänge von 35 MB (Megabyte) hochgeladen werden.",
    SIZE_FILE_INFO: "Der Upload ist in der Dateigrösse limitiert! Eine Datei darf maximal 4 MB (Megabyte) gross sein.",
    SIZE_ERROR_MESSAGE: "Die Datei «{{DOC_NAME}}» ist grösser als 4 MB (Megabyte) und kann nicht hochgeladen werden. Eine Datei darf höchstens 4 MB (Megabyte) gross sein.",
    CAN_BE_MORE: "(es können mehrere sein)",
    PLOT_A: "Parzelle A",
    ADD_PLOT: "Parzelle hinzufügen",
    REMOVE_PLOT: "Parzelle entfernen",
    PLOT: "Parzelle",
    ADD_POWER_OF_ATTORNEY: "weiteres Vollmachts- und Erklärungsformular mit Unterschriften hinzufügen",
    ADD_MUTATION_LANDOWNER: "weitere Mutationsmeldung Grundeigentümer mit Unterschrift hinzufügen",
    REMOVE_POWER_OF_ATTORNEY: "Vollmachts- und Erklärungsformular mit Unterschriften entfernen",
    REMOVE_MUTATION_LANDOWNER: "Mutationsmeldung Grundeigentümer mit Unterschrift entfernen",
    POWER_OF_ATTORNEY: "Vollmachts- und Erklärungsformular mit Unterschriften",
    MUTATION_LANDOWNER: "Mutationsmeldung Grundeigentümer mit Unterschrift",
    UPLOAD_LAND_REGISTER: "Grundbuchauszug / Auszug aus dem kantonalen Grundstückdaten-Informationssystem hochladen",
    LINKS_LAND_REGISTERS: "Links zu den kantonalen Grundstückdaten-Informationssystemen:",
    CANTON_OF_BERN: "Kanton Bern:",
    CANTON_OF_BERN_1: "Kantonales Grundbuchamt - Startseite (be.ch)",
    CANTON_OF_BERN_1_LINK: "https://www.gba.dij.be.ch/de/start.html",
    CANTON_OF_BERN_2: "GRUDIS Anmeldung (be.ch)",
    CANTON_OF_BERN_2_LINK: "https://sso.be.ch/auth/realms/capitastra_prod/protocol/openid-connect/auth?response_type=code&client_id=capitastra-client&state=RkdMUzJ4anV6Y3pQRi16V2FiTFhnYWZObVVtcGlrbG4yb3ZvU3VBaC11SDY1%3B%252Fhome%252Fdashboard&redirect_uri=https%3A%2F%2Fwww.grudis.apps.be.ch%2Fgrudis%2Fcapweb%2Fui%2F&scope=openid%20profile%20email%20roles&code_challenge=uusPwdtFlZiPBdBqSCODP-cFWhh6YU9DU49TVjVpbVA&code_challenge_method=S256&nonce=RkdMUzJ4anV6Y3pQRi16V2FiTFhnYWZObVVtcGlrbG4yb3ZvU3VBaC11SDY1&ui_locales=de",
    CANTON_OF_BERN_3: "Geoportal Kanton Bern Basiskarte mit Eigentumsauskunft von Grundstücken",
    CANTON_OF_BERN_3_LINK: "https://www.map.apps.be.ch/pub/synserver?project=a42pub_basisgreika&userprofile=geo&client=core&language=de",
    CANTON_OF_SOLOTHURN: "Kanton Solothurn:",
    CANTON_OF_SOLOTHURN_1: "Grundbuchämter - Kanton Solothurn",
    CANTON_OF_SOLOTHURN_1_LINK: "https://so.ch/verwaltung/finanzdepartement/grundbuchaemter/",
    CANTON_OF_SOLOTHURN_2: "Web GIS Client Kanton Solothurn",
    CANTON_OF_SOLOTHURN_2_LINK: "https://geo.so.ch/map/?realty=&l=ch.so.agi.av.grundstuecke&bl=hintergrundkarte_sw&t=default&c=2618500%2C1238000&s=200000",
    CANTON_OF_JURA: "Kanton Jura:",
    CANTON_OF_JURA_1: "Registre foncier - République et Canton du Jura",
    CANTON_OF_JURA_1_LINK: "https://www.jura.ch/DIN/RFC/Registre-foncier/Registre-foncier.html",
    CANTON_OF_JURA_2: "SIT-Jura Geoportal",
    CANTON_OF_JURA_2_LINK: "https://geo.jura.ch/theme/Cadastre?lang=fr&tree_group_layers_Point%20d%27interets%20-%20Exclusive=Fermeture_provisoire_TourismeLoisirs%2CPOI_Defibrillateurs%2CPOI_Administration&baselayer_ref=background_plan_cadastral&baselayer_opacity=0&theme=Cadastre&tree_groups=Cadastre%2Ctilecloud_cadastre&tree_group_layers_Cadastre=cadastre_pfp_1_2%2Ccadastre_pfp_3%2Cbatiments_projetes%2Cbatiments",
    CANTON_OF_BASELLAND: "Kanton Baselland:",
    CANTON_OF_BASELLAND_1: "Grundbuch — baselland.ch",
    CANTON_OF_BASELLAND_1_LINK: "https://www.baselland.ch/politik-und-behorden/direktionen/sicherheitsdirektion/zivilrechtsverwaltung/grundbuch",
    CANTON_OF_BASELLAND_2: "GeoView BL",
    CANTON_OF_BASELLAND_2_LINK: "https://geoview.bl.ch/",
    SIGNATURE_LANDOWNERS: "Unterschriften Grundeigentümer/Innen",
    SIGNATURE_LANDOWNERS_DESCRIPTION: "Zur rechtsgültigen Bildung eines Zusammenschlusses zum Eigenverbrauch benötigen wir von allen Grundeigentümerinnen und Grundeigentümern eine entsprechende Vollmacht und Unterschrift. Des Weiteren ist auch eine entsprechende Erklärung und Unterschrift der Ansprechpartnerin oder des Ansprechpartners notwendig. Hierfür ist das folgende Vollmachts- und Erklärungsformular auszudrucken, vollständig zu unterschreiben und wieder hier im Antrag hochzuladen. Ein Foto oder ein Scan des Dokuments genügt.",
    SIGNATURE_LANDOWNERS_FORM_1: "Falls das Formular nicht vollständig ausgefüllt ist, ",
    SIGNATURE_LANDOWNERS_FORM_2: "werden die Unterlagen bei Einreichung zurückgewiesen.",
    UPLOAD_POWER_OF_ATTORNEY: "Vollmachts- und Erklärungsformular mit Unterschriften hochladen",
    UPLOAD_MUTATION_LANDOWNER: "Mutationsmeldung Grundeigentümer mit Unterschrift hochladen",
    ERROR_SIZE: "Ihre hochgeladene(n) Datei(en) übersteigt/übersteigen die maximal zulässige Dateigrösse (35 MB).",
  },
  INSTALLER_COMPONENT: {
    TITLE: "Konzessionierter Elektroinstallateur",
    TITLE_TOOLTIP: "Die beauftragte Elektroinstallateurin oder der beauftragte Elektroinstallateur erhält nach Abschluss des Antrages eine Kopie des erfassten Antrages und der Beilagen (PDF) per E-Mail. Mit dieser Mail wird sie oder er gleichzeitig aufgefordert, die Installationsanzeige samt Messschema und Situationsplan zusammen mit dem Antragsformular als offiziellen Antrag via Branchensoftware Elektroform15 / Elektroform online beim Anschlussservice der BKW einzureichen.",
    INFO: "Hier wird der ausführende Elektroinstallateur ausgewählt. Entweder geschieht dies über das ESTI-Verzeichnis (Bei Eingabe des Firmennamens werden die Installateure vorgeschlagen). Falls nicht im ESTI Verzeichnis, kann der gewünschte Fachpartner auch manuell erfasst werden.",
    CHOOSE_INSTALLER: "Konzessionierte Elektroinstallateurin oder konzessionierter Elektroinstallateur",
    MANUAL: "Erfassen Sie Ihre konzessionierte Elektroinstallateurin oder Ihren konzessionierten Elektroinstallateur (manuell).",
    AUTO: "Wählen Sie eine konzessionierte Elektroinstallateurin oder einen konzessionerten Elektroinstallateur aus dem ESTI-Verzeichnis aus.",
    LABELS: {
      NAME: "Name",
      FIRST_NAME: "Vorname",
      STREET: "Strasse",
      ZIP: "PLZ",
      CITY: "Ort",
      PHONE: "Tel",
      EMAIL: "E-Mail",
    },
    FORM: {
      PLACEHOLDERS: {
        COMPANY: "Firma",
        LAST_NAME: "Name",
        FIRST_NAME: "Vorname",
        PHONE: "Telefonnummer",
        EMAIL: "E-Mail",
        EMAIL_CONFIRMATION: "Bestätigung E-Mail",
      },
    },
  },
  APPLICATION_BASICS: {
    TITLE: "Grundlagen des Antrags",
    TEXT_1: "1. Gültigkeit des Antrags",
    TEXT_1_1: "1.1      Folgende Dokumente sind als Bestandteil des Antragsformulars gemeinsam mit dem Antragsformular bei der BKW einzureichen:",
    TEXT_A_1: "A)      Vollmachts- und Erklärungsformular: Dem Antragsformular ist das Vollmachts- und Erklärungsformular beizulegen. Mit der Vollmacht bestätigen sämtliche am Zusammenschluss teilnehmenden Grundeigentümerinnen  und Grundeigentümer, dass sie einen Zusammenschluss zum Eigenverbrauch bilden wollen, stimmen den",
    TEXT_A_2: "Besonderen Geschäftsbedingungen der BKW Energie AG Zusammenschluss zum Eigenverbrauch",
    TEXT_A_3: "zu und bevollmächtigen die Ansprechpartnerin oder den Ansprechpartner zur umfassenden Vertretung gegenüber der BKW. Mit der Erklärung bestätigt die bevollmächtigte Ansprechpartnerin oder der bevollmächtigte Ansprechpartner, die aus ihrer oder seiner Funktion und den",
    TEXT_A_4: "Besonderen Geschäftsbedingungen der BKW Energie AG Zusammenschluss zum Eigenverbrauch",
    TEXT_A_5: "resultierenden Rechte und Pflichten zu kennen und zu akzeptieren.",
    TEXT_B: "B)      Eigentumsnachweis: Dem Antragsformular sind Eigentumsnachweise (Grundbuchauszug bzw. Auszug aus Grundstückdaten-Informationssystem) sämtlicher am Zusammenschluss teilnehmenden Grundeigentümerinnen und Grundeigentümer beizulegen.",
    TEXT_1_2: "1.2      Das Antragsformular samt Beilagen ist gemeinsam mit der Installationsanzeige durch eine konzessionierte Elektroinstallateurin oder einen konzessionierten Elektroinstallateur bei der BKW einzureichen. Der Installationsanzeige sind auch ein detailliertes Messschema und ein Situationsplan beizulegen, aus welchen ersichtlich ist, welcher Grundeigentümerin oder welchem Grundeigentümer die einzelnen Verbrauchstätten (Wohnung, Gewerbe, Energieerzeugungsanlage etc.) und Installationen zugeordnet werden können.",
    TEXT_1_3: "1.3      Für Verbrauchstätten, welche keiner Grundeigentümerin oder keinem Grundeigentümer zugeordnet werden können, übernimmt die Ansprechpartnerin oder der Ansprechpartner die Verantwortung. Daraus ergibt sich, dass die Ansprechpartnerin oder der Ansprechpartner in die Rechte und Pflichten einer Grundeigentümerin oder eines Grundeigentümers für diese Verbrauchstätte eintritt (z.B. Pflicht der Erbringung des Sicherheitsnachweises, Bezahlung von aus dem Netz bezogener Energie inkl. Abgaben für die Verbrauchstätte etc.).",
    TEXT_1_4: "1.4      Das Antragsformular darf nur durch eine von den Grundeigentümerinnen und Grundeigentümern am Anschlussobjekt dafür bevollmächtigte Person ausgefüllt und an die zuständige Elektroinstallateurin oder den zuständigen Elektroinstallateur weitergeleitet werden.",
    TEXT_1_5: "1.5      Bei Mutationen (beispielsweise Wechsel von Grundeigentümerinnen oder Grundeigentümern oder der Ansprechpartnerin oder des Ansprechpartners, Änderungen betreffend Rechnungsstellung etc.) sind diese unverzüglich durch die Ansprechpartnerin oder den Ansprechpartner der BKW mit dem entsprechenden Formular auf dem BKW ZEV-Kundenportal mitzuteilen.",
    TEXT_1_6: "1.6      Änderungen an der Installation des Zusammenschlusses sind mit den entsprechenden Meldeformularen durch die Elektroinstallateurin oder den Elektroinstallateur der BKW zu melden. Solche Änderungen werden nicht in diesem Portal nachgeführt.",
    TEXT_1_7: "1.7      Bei fehlerhaften bzw. fehlenden Informationen oder fehlenden Unterlagen werden der Antrag und somit auch die dazugehörige Installationsanzeige nicht bearbeitet und zur Verbesserung bzw. Vervollständigung retourniert. Erfolgt keine ergänzte Eingabe, wird der Antrag nicht bewilligt. Bei einem bereits bestehenden Zusammenschluss zum Eigenverbrauch behält sich die BKW vor, den Zusammenschluss zum Eigenverbrauch zu beenden. Die Grundeigentümerinnen und Grundeigentümer haften solidarisch für sämtliche Kosten und Schäden, die der BKW aufgrund der Auflösung des Zusammenschlusses zum Eigenverbrauch oder anderer Massnahmen entstehen.",
    TEXT_2: "2. Energielieferung",
    TEXT_2_1: "2.1      Die Lieferung von elektrischer Energie erfolgt im Rahmen der Grundversorgung durch die BKW, sofern der ZEV ein fester Endverbraucher gemäss Artikel 6 Abs. 2 des Stromversorgungsgesetzes (StromVG; SR 734.7) ist oder den Netzzugang gemäss Artikel 13 StromVG nicht beantragt hat.",
    TEXT_2_2: "2.2      Verfügt der ZEV über freien Netzzugang, so erfolgt die Energielieferung durch den vom ZEV gewählten Energielieferanten.",
    TEXT_2_3: "2.3      Der ZEV hat der BKW zwei Monate vor dessen Gründung bzw. Inbetriebnahme seines Anschlusses per E-Mail an <a href=\"mailto:s_verteilnetzbetreiber@bkw.ch\">s_verteilnetzbetreiber@bkw.ch</a> mitzuteilen, ob er von seinem Anspruch auf Netzzugang Gebrauch macht (vgl. Artikel 11 Abs. 3 der Stromversorgungsverordnung, StromVV; SR 734.71).",
    TEXT_2_4: "2.4      Die Bildung eines ZEV hat keine Auswirkungen auf Verpflichtungen aus bestehenden Energielieferverträgen bei freiem Netzzugang. Allfällige Konditionen für die Anpassung oder Auflösung bestehender Energielieferverträge sind mit dem zuständigen Energielieferanten zu klären.",
    TEXT_3: "3. Rechtsverhältnis",
    TEXT_3_1: "3.1      Das Rechtsverhältnis zwischen den Grundeigentümerinnen und Grundeigentümern des Zusammenschlusses zum Eigenverbrauch und der BKW wird durch das vorliegende Antragsformular inkl. den dazugehörigen Beilagen, den",
    TEXT_3_1_2: "Besonderen Geschäftsbedingungen der BKW Energie AG Zusammenschluss zum Eigenverbrauch (ZEV)",
    TEXT_3_1_3: "am oben gennannten Anschlussobjekt sowie den darin aufgeführten weiteren Allgemeinen Geschäftsbedingungen, Vorschriften und Fachnormen geregelt.",
    TEXT_3_2: "3.2      Insbesondere sind auch die einschlägigen Bestimmungen aus der Energie- und Stromversorgungsgesetzgebung einzuhalten, welche unter anderem Regelungen zu den Voraussetzungen für den Zusammenschluss zum Eigenverbrauch, zu den Informations- und Meldepflichten der Grundeigentümerinnen und Grundeigentümer des Zusammenschlusses zum Eigenverbrauch sowie zum Verhältnis zur Netzbetreiberin enthalten. Die Grundeigentümerinnen und Grundeigentümer des Zusammenschlusses zum Eigenverbrauch halten sich an die einschlägigen gesetzlichen Bestimmungen und nehmen zur Kenntnis, dass die BKW als Netzbetreiberin bei Verstössen den Zusammenschluss zum Eigenverbrauch beenden oder andere geeignete Massnahmen vornehmen kann.",
    LINK_TITLE: "Link zu den BGB ZEV und weiteren AGB",
    LINK_TEXT: "AGB & Rechtliches - BKW",
    LINK: "https://www.bkw.ch/de/agb-rechtliches",
    COMMENTS_1: "Haben Sie noch",
    COMMENTS_2: "Anmerkungen zum Zusammenschluss zum Eigenverbrauch?",
    COMMENTS_3: "Feld nicht zwingend",
    COMMENTS_LABEL: "Anmerkungen",
  },
  SUBMISSION: {
    TITLE: "Einreichung Antragsformular",
    TITLE_TOOLTIP: "Wird der Antrag nur gespeichert, kann er jederzeit mutiert werden. Dasselbe gilt, wenn der Antrag an Ihre Elektroinstallateurin oder Ihren Elektroinstallateur gesendet wurde. Sobald wir Ihren Antrag von Ihrer Elektroinstallateurin oder Ihrem Elektroinstallateur erhalten und geprüft haben, wird der Status auf “An BKW übermittelt” gesetzt. Ab diesem Zeitpunkt können keine Daten mehr geändert werden.",
    INFO: "Das Antragsformular wird nach Abschluss des Antrages Ihrer konzessionierten Elektroinstallateurin oder Ihrem konzessionierten Elektroinstallateur automatisch per E-Mail zugestellt. Sie oder er erstellt anschliessend die Installationsanzeige sowie das detaillierte Messschema und den Situationsplan und stellt diese der BKW mit Ihrem Antrag zur Prüfung zu. Wie bereits eingangs beschrieben, prüfen wir Ihren Antrag nach Eingang aller Unterlagen durch Ihre konzessionierte Elektroinstallateurin oder Ihren Elektroinstallateur. Wir stellen die Bewilligung des Antrags nur Ihrer konzessionierten Elektroinstallateurin oder Ihrem Elektroinstallateur zu. Den uns übermittelten Antrag können Sie nicht mehr ändern.",
    AGB_1: "Mit dem Abschicken des Antrags erklären die Grundeigentümerinnen und Grundeigentümer des Zusammenschlusses zum Eigenverbrauch und die Ansprechpartnerin oder der Ansprechpartner, die",
    AGB_2: "Besonderen Geschäftsbedingungen der BKW Energie AG Zusammenschluss zum Eigenverbrauch (ZEV)",
    AGB_3: "sowie die aktuellen und geltenden Werkvorschriften zur Kenntnis genommen, verstanden und akzeptiert zu haben.",
    IMPACT_1: "Auswirkung der Einreichung des Antragsformulars:",
    IMPACT_2: "Durch die Einreichung von Antragsformular und Installationsanzeige, jeweils samt den erforderlichen Beilagen, entsteht zwischen den Grundeigentümerinnen und Grundeigentümern des Zusammenschlusses zum Eigenverbrauch und der BKW Energie AG das Vertragsverhältnis. Voraussetzung für das Zustandekommen des Vertragsverhältnisses ist, dass die technischen und rechtlichen Voraussetzungen vorliegen und der ZEV-Antrag und die dazugehörigen Meldeformulare (Installationsanzeige, detailliertes Messschema, Situationsplan etc.) bewilligt wurden.",
    IMPACT_3: "Bitte beachten Sie, dass es am eingereichten Antrag keine Änderungen geben wird, auch wenn es zu einem späteren Zeitpunkt zu Mutationen innerhalb des bestehenden Zusammenschlusses zum Eigenverbrauch kommt.",
    AGREEMENT_LABEL: "Ich / wir stimmen dem vorliegenden Antrag zu.",
    EMAIL_INFO: "Nach Einreichung des Antrages erhalten Sie eine E-Mail mit Ihrem Antrag als Bestätigung.",
  },
  UPLOAD: {
    HOVER_VIEW_DOCUMENT: "Noch kein Dokument hochgeladen",
    HOVER_REMOVE_DOCUMENT: "Dokument entfernen",
    ERROR: "Fehler beim Lesen oder Validieren der Datei. Erlaubt sind nur Bildformate JPG/JPEG/PNG oder PDF’s sowie eine maximale Dateigrösse von 35 MB. Der Dateiname darf nur Buchstaben, Nummern und Underline beinhalten.",
  },
  VALIDATIONS: {
    REQUIRED_FIELD: "Dies ist ein Pflichtfeld",
    EMAIL_INVALID: "Ungültige E-Mail-Adresse",
    CONFIRMATION_EMAIL_INVALID: "E-Mail-Adressen sind nicht identisch",
    POSTAL_CODE_INVALID: "Ungültige Postleitzahl",
    METER_NUMBER_INVALID: "Ungültige BKW Zählernummer",
    PHONE_NUMBER_INVALID: "Ungültige Telefonnummer",
    HOUSE_NUMBER_INVALID: "Ungültige Hausnummer",
    CONNECTION_OBJECT_NUMBER_INVALID: "Ungültige AO-Nummer",
    GP_NUMBER_INVALID: "Ungültige Kunden-Nummer",
    CONFIRMATION_GP_NUMBER_INVALID: "Die Kunden-Nummern sind nicht identisch",
    MAXIMUM_LENGTH: "die maximal zulässige Länge ist überschritten",
    CANCELLATION_DATE: "die Kündigungsfrist beträgt 3 Monate",
  },
  BUTTONS: {
    NEXT: "Weiter",
    BACK: "Zurück",
    SAVE: "Zwischenspeichern",
    CANCEL: "Abbrechen",
    SUBMIT: "Antrag einreichen",
    RE_SUBMIT: "Antrag Wiedereinreichen",
  },
  PDF: {
    TITLE: "ZEV ID Antrag Nr. {{id}}",
    TITLE_MUTATION: "Mutationsformular – ZEV Anschlussobjekt Nr. {{id}}",
    TITLE_CANCELLATION: "Kündigungsformular – ZEV Anschlussobjekt Nr. {{id}}",
    NOTE_MUTATION: "Das vorliegende Mutationsformular wird nach Versand des Dokuments via ZEV-Kundenportal direkt an die BKW Energie AG gesendet. Die Mutation wird zeitnah umgesetzt. Bei Fragen wenden Sie sich bitte an unseren Kundenservice (kundenservice@bkw.ch).",
    POWER_OF_ATTORNEY: {
      FILE_NAME: "_Vollmacht / Erklärung Ansprechpartner",
      TITLE: "Vollmacht / Erklärung Ansprechpartner",
      SUB_1: "I.      Vollmacht",
      TEXT_CONNECTION_OBJECT: "Die nachfolgenden Grundeigentümerinnen und Grundeigentümer beantragen bei der BKW Energie AG (nachfolgend BKW genannt) einen Zusammenschluss zum Eigenverbrauch (ZEV) gemäss Artikel 17 des Energiegesetzes (EnG; SR 730.0) an folgender Adresse zu bilden:",
      TEXT_CONTACT_PERSON: "Hierzu bevollmächtigen sie die folgende Ansprechpartnerin oder den folgenden Ansprechpartner, die Interessen der Grundeigentümerinnen und Grundeigentümer im Zusammenhang mit dem Zusammenschluss zum Eigenverbrauch gegenüber der BKW verbindlich zu vertreten und wahrzunehmen:",
      TEXT_LANDOWNERS: "Die Grundeigentümerinnen und Grundeigentümer des oben genannten Anschlussobjekts bestätigen mit der Unterschrift der vorliegenden Vollmacht, den von der BKW bewilligten Antrag (inkl. Anhänge und Beilagen) samt Installationsanzeige, die Besonderen Geschäftsbedingungen der BKW Energie AG Zusammenschluss zum Eigenverbrauch sowie die Allgemeinen Geschäftsbedingungen der BKW in den jeweils gültigen Fassungen, insbesondere die AGB der BKW für den Netzanschluss und die Netznutzung, und die jeweiligen Mutationsmeldungen als Vertragsbestandteil für die Errichtung des Zusammenschlusses zum Eigenverbrauch zu akzeptieren. Die Grundeigentümerinnen und Grundeigentümer des oben genannten Anschlussobjekts haften jeweils vollumfänglich für die Umsetzung des Vertrages.",
      SUB_2: "II.      Erklärung Ansprechpartner",
      TEXT_DECLARATION: "Die unterzeichnende Ansprechpartnerin oder der unterzeichnende Ansprechpartner nimmt zur Kenntnis, von den oben aufgeführten Grundeigentümerinnen und Grundeigentümern als Ansprechpartnerin oder Ansprechpartner des Zusammenschlusses zum Eigenverbrauch bezeichnet und bevollmächtigt worden zu sein und erklärt die Übernahme der Funktion als Ansprechpartnerin oder Ansprechpartner. \n\n" +
          "Mit der Unterzeichnung dieser Erklärung erklärt die Ansprechpartnerin oder der Ansprechpartner, die aus ihrer oder seiner Funktion und den Besonderen Geschäftsbedingungen der BKW Energie AG Zusammenschluss zum Eigenverbrauch sowie den Allgemeinen Geschäftsbedingungen der BKW in den jeweils gültigen Fassungen, insbesondere den AGB der BKW für den Netzanschluss und die Netznutzung, resultierenden Rechte und Pflichten zu kennen und zu akzeptieren.",
      PLACE_DATE: "Ort, Datum",
      SIGNATURE_CONTACT_PERSON: "Unterschrift Ansprechpartner",
      NOTE: "Die vorliegende Vollmacht ist vollständig unterzeichnet und als Teil des Antragsformulars zur Bildung eines Zusammenschlusses zum Eigenverbrauch im ZEV-Kundenportal hochzuladen. Ein Foto oder ein Scan des Dokuments genügt. Bei Fragen wenden Sie sich bitte an unseren Kundenservice (kundenservice@bkw.ch).",
      TABLE: {
        NAME: "Name und Vorname",
        PLACE_DATE: "Ort, Datum",
        SIGNATURE: "Unterschrift",
      },
    },
    APPLICATION: {
      FILE_NAME: "_Antragsformular Zusammenschluss zum Eigenverbrauch",
      TITLE: "Antragsformular zur Bildung eines Zusammenschlusses zum Eigenverbrauch (ZEV)",
      SUB_1: "1.      Angaben zum Anschlussobjekt und zum Ansprechpartner des Zusammenschlusses zum Eigenverbrauch",
      TEXT_CONNECTION_OBJECT: "Die nachfolgenden Grundeigentümerinnen und Grundeigentümer beantragen bei der BKW Energie AG (nachfolgend BKW genannt) einen Zusammenschluss zum Eigenverbrauch (ZEV) gemäss Artikel 17 des Energiegesetzes (EnG; SR 730.0) an folgender Adresse zu bilden:",
      TITLE_CONNECTION_OBJECT: "Anschlussobjekt des Zusammenschlusses zum Eigenverbrauch:",
      TITLE_CONTACT_PERSON: "Ansprechpartnerin/Ansprechpartner des Zusammenschlusses zum Eigenverbrauch:",
      TEXT_CONTACT_PERSON: "Die nachfolgenden Grundeigentümerinnen und Grundeigentümer bevollmächtigen folgende Ansprechpartnerin oder folgenden Ansprechpartner, den Zusammenschluss und somit ihre Interessen im Zusammenhang mit dem Zusammenschluss zum Eigenverbrauch gegenüber der BKW verbindlich zu vertreten und wahrzunehmen:",
      SUB_2: "2.      Angaben zu den einzelnen Verbrauchstätten (wie Wohnungen, Gebäude) des Zusammenschlusses zum Eigenverbrauch und den dazugehörenden Grundeigentümerinnen und Grundeigentümer",
      SUB_3: "3.      Neue Rechnungsempfängerin/Neuer Rechnungsempfänger des Zusammenschlusses zum Eigenverbrauch",
      TEXT_INVOICE_RECIPIENT: "Die Grundeigentümerinnen und Grundeigentümer des Zusammenschlusses zum Eigenverbrauch bevollmächtigen die untenstehende Person / Firma mit der Abwicklung der Rechnungen und Vergütung der Überschussenergie für den Zusammenschluss zum Eigenverbrauch:",
      SUB_4: "{{number}}.      Beauftragte(r) konzessionierte Elektroinstallateurin/Konzessionierter Elektroinstallateur",
      TEXT_INSTALLER: "Die Grundeigentümerinnen und Grundeigentümer des Zusammenschlusses zum Eigenverbrauch beauftragen die nachfolgend aufgeführte Elektroinstallateurin bzw. den nachfolgend aufgeführten Elektroinstallateur, die Installationsanzeige samt Messschema und Situationsplan zusammen mit dem Antragsformular als offiziellen Antrag via Branchensoftware Elektroform15 / Elektroform online beim Anschlussservice der BKW einzureichen.",
      SUB_5: "{{number}}.      GRUNDLAGEN DES ANTRAGS",
      BASICS: "1.      Gültigkeit des Antrags\n\n" +
          "1.1      Folgende Dokumente sind als Bestandteil des Antragsformulars gemeinsam mit dem Antragsformular bei der BKW einzureichen:\n\n" +
          "A)       Vollmachts- und Erklärungsformular: Dem Antragsformular ist das Vollmachts- und Erklärungsformular beizulegen. Mit der Vollmacht bestätigen sämtliche am Zusammenschluss teilnehmenden Eigentümerinnen und Eigentümer, dass sie einen Zusammenschluss zum Eigenverbrauch bilden wollen, stimmen den Besonderen Geschäftsbedingungen der BKW Energie AG Zusammenschluss zum Eigenverbrauch zu und bevollmächtigen die Ansprechpartnerin oder den Ansprechpartner zur umfassenden Vertretung gegenüber der BKW. Mit der Erklärung bestätigt die bevollmächtigte Ansprechpartnerin oder der bevollmächtigte Ansprechpartner, die aus ihrer oder seiner Funktion und den Besonderen Geschäftsbedingungen der BKW Energie AG Zusammenschluss zum Eigenverbrauch resultierenden Rechte und Pflichten zu kennen und zu akzeptieren.\n\n" +
          "B)       Eigentumsnachweis: Dem Antragsformular sind Eigentumsnachweise (Grundbuchauszüge bzw. Auszug aus Grundstückdaten-Informationssystem) sämtlicher am Zusammenschluss teilnehmenden Eigentümerinnen und Eigentümer beizulegen.\n\n" +
          "1.2      Das Antragsformular samt Beilagen ist gemeinsam mit der Installationsanzeige durch eine konzessionierte Elektroinstallateurin oder einen konzessionierten Elektroinstallateur bei der BKW einzureichen. Der Installationsanzeige sind auch ein detailliertes Messschema und ein Situationsplan beizulegen, aus welchen ersichtlich ist, welcher Grundeigentümerin oder welchem Grundeigentümer die einzelnen Verbrauchstätten (Wohnung, Gewerbe, Energieerzeugungsanlage etc.) und Installationen zugeordnet werden können.\n\n" +
          "1.3      Für Verbrauchstätten, welche keiner Grundeigentümerin oder keinem Grundeigentümer zugeordnet werden können, übernimmt die Ansprechpartnerin oder der Ansprechpartner die Verantwortung. Daraus ergibt sich, dass die Ansprechpartnerin oder der Ansprechpartner in die Rechte und Pflichten einer Grundeigentümerin oder eines Grundeigentümers für diese Verbrauchstätte eintritt (z.B. Pflicht der Erbringung des Sicherheitsnachweises, Bezahlung von aus dem Netz bezogener Energie inkl. Abgaben für die Verbrauchstätte etc.).\n\n" +
          "1.4      Das Antragsformular darf nur durch eine von den Grundeigentümerinnen und Grundeigentümern am Anschlussobjekt dafür bevollmächtigte Person ausgefüllt und an die zuständige Elektroinstallateurin oder den zuständigen Elektroinstallateur weitergeleitet werden.\n\n" +
          "1.5      Bei Mutationen (beispielsweise Wechsel von Grundeigentümerinnen oder Grundeigentümern oder der Ansprechpartnerin oder des Ansprechpartners, Änderungen betreffend Rechnungsstellung etc.) sind diese unverzüglich durch die Ansprechpartnerin oder den Ansprechpartner der BKW mit dem entsprechenden Formular auf dem BKW ZEV-Kundenportal mitzuteilen.\n\n" +
          "1.6      Änderungen an der Installation des Zusammenschlusses sind mit den entsprechenden Meldeformularen durch die Elektroinstallateurin oder den Elektroinstallateur der BKW zu melden. Solche Änderungen werden nicht in diesem Portal nachgeführt.\n\n" +
          "1.7      Bei fehlerhaften bzw. fehlenden Informationen oder fehlenden Unterlagen werden der Antrag und somit auch die dazugehörige Installationsanzeige nicht bearbeitet und zur Verbesserung bzw. Vervollständigung retourniert. Erfolgt keine ergänzte Eingabe, wird der Antrag nicht bewilligt. Bei einem bereits bestehenden Zusammenschluss zum Eigenverbrauch behält sich die BKW vor, den Zusammenschluss zum Eigenverbrauch zu beenden. Die Grundeigentümerinnen und Grundeigentümer haften solidarisch für sämtliche Kosten und Schäden, die der BKW aufgrund der Auflösung des Zusammenschlusses zum Eigenverbrauch oder anderer Massnahmen entstehen.\n\n\n" +
          "2.       Energielieferung\n\n" +
          "2.1      Die Lieferung von elektrischer Energie erfolgt im Rahmen der Grundversorgung durch die BKW, sofern der ZEV ein fester Endverbraucher gemäss Artikel 6 Abs. 2 des Stromversorgungsgesetzes (StromVG; SR 734.7) ist oder den Netzzugang gemäss Artikel 13 StromVG nicht beantragt hat.\n\n" +
          "2.2      Verfügt der ZEV über freien Netzzugang, so erfolgt die Energielieferung durch den vom ZEV gewählten Energielieferanten.\n\n" +
          "2.3      Der ZEV hat der BKW zwei Monate vor dessen Gründung bzw. Inbetriebnahme seines Anschlusses per E-Mail an s_verteilnetzbetreiber@bkw.ch mitzuteilen, ob er von seinem Anspruch auf Netzzugang Gebrauch macht (vgl. Artikel 11 Abs. 3 der Stromversorgungsverordnung, StromVV; SR 734.71).\n\n" +
          "2.4      Die Bildung eines ZEV hat keine Auswirkungen auf Verpflichtungen aus bestehenden Energielieferverträgen bei freiem Netzzugang. Allfällige Konditionen für die Anpassung oder Auflösung bestehender Energielieferverträge sind mit dem zuständigen Energielieferanten zu klären.\n\n\n" +
          "3.       Rechtsverhältnis\n\n" +
          "3.1      Das Rechtsverhältnis zwischen den Grundeigentümerinnen und Grundeigentümern des Zusammenschlusses zum Eigenverbrauch und der BKW wird durch das vorliegende Antragsformular inkl. den dazugehörigen Beilagen, den Besonderen Geschäftsbedingungen der BKW Energie AG Zusammenschluss zum Eigenverbrauch (ZEV) am oben gennannten Anschlussobjekt sowie den darin aufgeführten weiteren Allgemeinen Geschäftsbedingungen, Vorschriften und Fachnormen geregelt.\n\n" +
          "3.2      Insbesondere sind auch die einschlägigen Bestimmungen aus der Energie- und Stromversorgungsgesetzgebung einzuhalten, welche unter anderem Regelungen zu den Voraussetzungen für den Zusammenschluss zum Eigenverbrauch, zu den Informations- und Meldepflichten der Grundeigentümerinnen und Grundeigentümer des Zusammenschlusses zum Eigenverbrauch sowie zum Verhältnis zur Netzbetreiberin enthalten. Die Grundeigentümerinnen und Grundeigentümer des Zusammenschlusses zum Eigenverbrauch halten sich an die einschlägigen gesetzlichen Bestimmungen und nehmen zur Kenntnis, dass die BKW als Netzbetreiberin bei Verstössen den Zusammenschluss zum Eigenverbrauch beenden oder andere geeignete Massnahmen vornehmen kann.\n\n",
      LINK_TITLE: "Link zu den BGB ZEV und weiteren AGB",
      LINK_TEXT: "AGB & Rechtliches - BKW",
      SUB_6: "{{number}}.      Anmerkungen",
      SUBMISSION_TITLE: "Einreichung Antragsformular",
      SUBMISSION_TEXT: "Mit dem Abschicken des Antrags erklären die Grundeigentümerinnen und Grundeigentümer des Zusammenschlusses zum Eigenverbrauch und die Ansprechpartnerin oder der Ansprechpartner, die Besonderen Geschäftsbedingungen der BKW Energie AG Zusammenschluss zum Eigenverbrauch (ZEV) sowie die aktuellen und geltenden Werkvorschriften zur Kenntnis genommen, verstanden und akzeptiert zu haben.\n\n" +
          "Durch die Einreichung von Antragsformular und Installationsanzeige, jeweils samt den erforderlichen Beilagen, entsteht zwischen den Grundeigentümerinnen und Grundeigentümern des Zusammenschlusses zum Eigenverbrauch und der BKW Energie AG das Vertragsverhältnis. Voraussetzung für das Zustandekommen des Vertragsverhältnisses ist, dass die technischen und rechtlichen Voraussetzungen vorliegen und der Antrag bewilligt wurde.",
    },
    POWER_OF_ATTORNEY_INVOICE_RECIPIENT: {
      FILE_NAME: "_Vollmacht Rechnungsempfänger",
      TITLE: "Vollmacht Rechnungsempfänger",
      INTRO: "Die Grundeigentümerinnen und Grundeigentümer des Zusammenschlusses zum Eigenverbrauch beantragen folgend, dass die Rechnungen und Vergütung der Überschussenergie für den Zusammenschluss zum Eigenverbrauch nicht von der Ansprechpartnerin oder dem Ansprechpart-ner abgewickelt werden, sondern sie bevollmächtigen hierzu die untenstehende Person / Firma:",
      INVOICE_RECIPIENT: "Rechnungsempfängerin oder Rechnungsempfänger des folgenden Anschlussobjekts",
      TABLE_HEADER: "Anschlussobjekt (Ort des Netzanschlusspunkts des Zusammenschlusses)",
      ADDRESS: "Adresse:",
      WILL: "wird",
      INFO: "Die Avisierung von Stromunterbrüchen sowie die übrige Korrespondenz erfolgt an die Ansprechpartnerin oder den Ansprechpartner des Zusammenschlusses zum Eigenverbrauch.",
    },
    POWER_OF_ATTORNEY_INVOICE_RECIPIENT_ADDRESS: {
      FILE_NAME: "_Vollmacht Rechnungsempfänger Adressänderung",
      TITLE: "Vollmacht Rechnungsempfänger",
      INTRO: "Dieses Mutationsformular dient der Erfassung einer Adressänderung der Rechnungsempfängerin oder des Rechnungsempfängers des Anschlussobjekts an folgender Adresse: {{address}}",
      INVOICE_RECIPIENT: "Folgende neue Adressänderung der Rechnungsempfängerin oder des Rechnungsempfängers des Zusammenschlusses zum Eigenverbrauch wird festgehalten:",
      INFO: "Die Avisierung von Stromunterbrüchen sowie die übrige Korrespondenz erfolgt an die Ansprechpartnerin oder den Ansprechpartner des Zusammenschlusses zum Eigenverbrauch.",
    },
    CANCELLATION: {
      FILE_NAME: "_Kündigung Zusammenschluss zum Eigenverbrauch",
      TITLE: "Kündigung Zusammenschluss zum Eigenverbrauch",
      INTRO: "Die Grundeigentümerinnen und Grundeigentümer kündigen unter Einhaltung der Kündigungsfrist von drei Monaten per Ende {{cancellationDate}} den an folgender Adresse bestehenden Zusammenschluss zum Eigenverbrauch:",
      TABLE_HEADER: "Anschlussobjekt (Ort des Netzanschlusspunkts des Zusammenschlusses)",
      ADDRESS: "Adresse:",
      INFO: "Mit Ablauf der Kündigungsfrist endet der Eigenverbrauch am Anschlussobjekt.\n\n" +
        "Eine Kündigung des Zusammenschlusses zum Eigenverbrauch hat keine Kündigung des Netzanschlusses zur Folge. Sind netzanschlussseitig Anpassungen vorzunehmen, so sind diese unabhängig von dieser Kündigung fristgerecht beim Netzbetreiber zu beantragen.\n\n" +
        "Für die Versorgung der einzelnen Verbrauchsstätten haben die Grundeigentümerinnen und Grundeigentümer respektive eine konzessionierte Elektroinstallateurin oder ein konzessionierter Elektroinstallateur die entsprechenden Meldeformulare einzureichen und in diesem Zusammenhang auch die notwendigen Einrichtungen für die Messinfrastruktur der BKW bereit zu stellen.\n\n" +
        "Die Kündigung wird erst dann wirksam, wenn zeitgleich auch die Meldeformulare eingereicht werden.",
      TABLE: {
        NAME: "Name und Vorname",
        PLACE_DATE: "Ort, Datum",
        SIGNATURE: "Unterschrift",
      },
    },
    MUTATION_LANDOWNER_INTERN: {
      FILE_NAME: "_Grundeigentümerwechsel",
      TITLE: "Mutationsmeldung Grundeigentümer",
      INTRO: "Dieses Mutationsformular dient der Erfassung einer Änderung in der Zusammensetzung der Grundeigentümerinnen und Grundeigentümer des Anschlussobjekts an folgender Adresse: {{address}}",
      TEXT_OLD_LANDOWNER: "Folgend werden die austretende Grundeigentümerin bzw. der austretende Grundeigentümer sowie die oder der an ihre oder seine Stelle eintretende Grundeigentümerin oder eintretende Grundeigentümer festgehalten:",
      EXITING_LANDOWNER: "Austretende Grundeigentümerin, austretender Grundeigentümer:",
      NEW_LANDOWNER: "Aus dem ZEV übernehmende Grundeigentümerin, aus dem ZEV übernehmender Grundeigentümer:",
      INFO: "Die aus dem ZEV übernehmende Grundeigentümerin oder der aus dem ZEV übernehmende Grundeigentümer des oben genannten Anschlussobjekts tritt vorbehaltlos in die Rechtsstellung der austretenden Grundeigentümerin oder des austretenden Grundeigentümers ein.",
    },
    MUTATION_LANDOWNER_EXTERN: {
      FILE_NAME: "_Grundeigentümerwechsel",
      TITLE: "Mutationsmeldung Grundeigentümer",
      INTRO: "Dieses Mutationsformular dient der Erfassung einer Änderung in der Zusammensetzung der Grundeigentümerinnen und Grundeigentümer des Anschlussobjekts an folgender Adresse: {{address}}",
      TEXT_OLD_NEW_LANDOWNER: "Folgende Ein- und Austritte der Grundeigentümerinnen und Grundeigentümer werden festgehalten:",
      EXITING_LANDOWNER: "Austretende Person bzw. Personen:",
      NEW_LANDOWNER: "Neu eintretende Person bzw. Personen:",
      AGREEMENT_TEXT: "Die neu eintretende Grundeigentümerin oder der neu eintretende Grundeigentümer bzw. die neu eintretenden Grundeigentümerinnen und Grundeigentümer des oben genannten Anschlussobjekts tritt bzw. treten vorbehaltlos in die Rechtsstellung der austretenden Grundeigentümerin oder des austretenden Grundeigentümers bzw. der austretenden Grundeigentümerinnen und Grundeigentümer ein und bestätigt bzw. bestätigen mit der Unterschrift der vorliegenden Mutationsmeldung, den von der BKW bewilligten Antrag (inkl. Anhänge und Beilagen) samt Installationsanzeige, die Besonderen Geschäftsbedingungen der BKW Energie AG Zusammenschluss zum Eigenverbrauch (ZEV) sowie die Allgemeinen Geschäftsbedingungen der BKW in den jeweils gültigen Fassungen, insbesondere die AGB der BKW für den Netzanschluss und die Netznutzung, und die jeweiligen Mutationsmeldungen als Vertragsbestandteil des Zusammenschlusses zum Eigenverbrauch zu akzeptieren. Die Grundeigentümerinnen und Grundeigentümer des oben genannten Anschlussobjekts haften jeweils vollumfänglich für die Umsetzung des Vertrages.",
    },
    MUTATION_CONTACT_PERSON_NEW: {
      FILE_NAME: "_Ansprechpartnerwechsel",
      TITLE: "Mutationsmeldung Ansprechpartner",
      INTRO: "Dieses Mutationsformular dient der Erfassung eines Wechsels der Ansprechpartnerin oder des Ansprechpartners des Anschlussobjekts an folgender Adresse: {{address}}",
      TEXT_CHANGE_CONTACT_PERSON: "Folgender Wechsel der Ansprechpartnerin oder des Ansprechpartners des Zusammenschlusses zum Eigenverbrauch wird festgehalten:",
      EXITING_CONTACT_PERSON: "Austretende Ansprechpartnerin, austretender Ansprechpartner:",
      NEW_CONTACT_PERSON: "Neue Ansprechpartnerin, neuer Ansprechpartner:",
      AGREEMENT_TEXT: "Die unterzeichnende Ansprechpartnerin oder der unterzeichnende Ansprechpartner nimmt zur Kenntnis, von den Grundeigentümerinnen und Grundeigentümern des Zusammenschlusses zum Eigenverbrauch als Ansprechpartnerin oder Ansprechpartner des Zusammenschlusses zum Eigenverbrauch bezeichnet und bevollmächtigt worden zu sein, die Interessen der Grundeigentümerinnen und Grundeigentümer im Zusammenhang mit dem Zusammenschluss zum Eigenverbrauch gegenüber der BKW verbindlich zu vertreten und wahrzunehmen. Die unterzeichnende Ansprechpartnerin oder der unterzeichnende Ansprechpartner erklärt die Übernahme der Funktion als Ansprechpartnerin oder Ansprechpartner.\n\n" +
        "Mit der Unterzeichnung dieser Mutationsmeldung erklärt die Ansprechpartnerin oder der Ansprechpartner, die aus ihrer oder seiner Funktion und den Besonderen Geschäftsbedingungen der BKW Energie AG Zusammenschluss zum Eigenverbrauch (ZEV) sowie den Allgemeinen Geschäftsbedingungen der BKW in den jeweils gültigen Fassungen, insbesondere den AGB der BKW für den Netzanschluss und die Netznutzung, resultierenden Rechte und Pflichten zu kennen und zu akzeptieren.",
      AGREEMENT_LABEL: "Ort, Datum, Unterschrift neue Ansprechpartnerin, neuer Ansprechpartner:",
    },
    MUTATION_CONTACT_PERSON_ADDRESS: {
      FILE_NAME: "_Ansprechpartner Adressänderung",
      TITLE: "Mutationsmeldung Ansprechpartner",
      INTRO: "Dieses Mutationsformular dient der Erfassung einer Adressänderung der Ansprechpartnerin oder des Ansprechpartners des Anschlussobjekts an folgender Adresse: {{address}}",
      ADDRESS_CHANGE: "Folgende neue Adressänderung der Ansprechpartnerin oder des Ansprechpartners des Zusammenschlusses zum Eigenverbrauch wird festgehalten:",
    },
    MUTATION_MIGRATION_ACTIVATION: {
      FILE_NAME: "_Mutationsformular - «Bestehende ZEV-Verträge aufschalten»",
      SUBTITLE: "Mutationsformular - «Bestehende ZEV-Verträge aufschalten»",
      TITLE: "Mutationsmeldung «Bestehende ZEV-Verträge aufschalten»",
      INTRO: "Übersicht Aufschaltung der ZEV-Verträge (Vertrag über die Einrichtung des Eigenverbrauchs) auf dem ZEV Kundenportal.",
      TABLE: {
        ACCEPTED: "Vertrag übernommen",
      },
      NOTE: "Nach der erfolgreichen Aufschaltung der ZEV-Verträge können Sie über das ZEV Portal Mutationen zu den einzelnen Zusammenschlüssen zum Eigenverbrauch einreichen.",
      MISSING_APPLICATIONS: "Fehlende ZEV-Verträge",
    },
    TABLE: {
      COMPANY: "Firma:",
      NAME: "Name:",
      PHONE: "Tel:",
      EMAIL: "E-Mail:",
    },
    TABLE_LANDOWNER: {
      NAME: "Name und Vorname",
      STREET: "Strasse, Nr.",
      ZIP: "PLZ, Ort",
      PLACE_OF_CONSUMPTION: "Betroffene Wohneinheit / Verbrauchstelle des ZEV (wie EG, links/rechts, etc.)",
      METER_NUMBER: "BKW Zählernummer (Serialnummer), wenn vorhanden",
    },
    ACTIVATE_MIGRATED_APPLICATIONS: {
      COMMENT: "Anmerkungen:",
    },
  },
  APPLICATION_OVERVIEW: {
    TITLE: "Übersicht der Anträge für ZEV",
    NEW_APPLICATION: "Neues Antragsformular ausfüllen",
    MIGRATE_APPLICATIONS: "Aufschaltung Verträge im ZEV Kundenportal",
    ACTIVATE_MIGRATED_APPLICATIONS: "Bestehende ZEV-Verträge aufschalten",
    FIELD_LABEL: "Feld",
    SEARCH_PLACEHOLDER: "Suche",
    SEARCH_HINT: "Betätigen Sie das Suchsymbol oder die ENTER-Taste um die Suche zu starten",
    TYPE_LABEL: "Formularart",
    STATUS_LABEL: "Status",
    FIELD_SELECTION_OPTIONS: {
      ID: "ID Vertrag",
      ADDRESS: "ZEV Adresse",
      INSTALLER: "Konzessionierter Elektroinstallateur",
      AO: "AO-Nr.",
      FORM_TYPE: "Formularart",
      STATUS: "Status",
    },
    COLUMN_TITLES: {
      ID: "ID des Antrages",
      CONNECTION_OBJECT_NUMBER: "AO-Nr.",
      ADDRESS: "ZEV-Adresse",
      INSTALLER: "Konzessionierter Elektroinstallateur",
      LAST_UPDATE: "Letzte Änderung",
      FORM_TYPE: "Formularart",
      STATUS: "Status",
      AUTHOR: "Erfasser",
    },
    FORM_TYPE: {
      APPLICATION: "Antrag",
      INVOICE_RECIPIENT_ADDRESS: "Mutation Rechnungsempfänger (Adresse)",
      INVOICE_RECIPIENT_NEW: "Mutation Rechnungsempfänger (Neu)",
      LANDOWNER_CHANGE: "Mutation Grundeingentümerwechsel",
      CONTACT_PERSON_ADDRESS: "Mutation Ansprechpartner (Adresse)",
      CONTACT_PERSON_NEW: "Mutation Ansprechpartner (Neu)",
      CANCELLATION: "Kündigung",
      LANDOWNER_CHANGE_INTERN: "Mutation Eigentümerwechsel",
      LANDOWNER_CHANGE_EXTERN: "Mutation Eigentümerwechsel",
      MIGRATED_MUTATION: "Mutation",
    },
    STATUS: {
      ALL: "Alle",
      DRAFT: "Erfasst",
      COMPLETE: "Eingereicht",
      APPROVED: "An BKW übermittelt",
      CANCELLED: "Gekündigt",
      INVALID: "Ungültig",
      CHECKED: "Geprüft",
      MIGRATED: "Migriert",
    },
    STATUS_TOOLTIP:{
      DRAFT: "Daten können verändert werden",
      APPROVED: "Ihre Elektroinstallateurin bzw. Ihr Elektroinstallateur hat Ihren ZEV-Antrag der BKW übermittelt. Die BKW bearbeitet die eingereichten Unterlagen und teilt Ihrer Elektroinstallateurin bzw. Ihrem Elektroinstallateur mit, ob der ZEV gebildet werden kann. Nachträgliche Änderungen/Ergänzungen des Antrags sind uns durch Mutationsformulare zu melden.",
      CANCELLED: "Antrag wurde gekündigt",
      CHECKED: "Die Unterschrift wurde geprüft",
      MIGRATED: {
        APPLICATION: "Der ZEV-Vertrag wurde migriert aber noch nicht aufgeschaltet.",
        MUTATION: "Die Mutation wurde migriert aber noch nicht aufgeschaltet.",
      },
      INVALID: {
        APPLICATION: "Antrag ist ungültig",
        MUTATION: "Mutation ist ungültig",
      },
      COMPLETE: {
        APPLICATION: "wurde am Elektroinstallateur zur Einreichung zugestellt. Daten können verändert werden und neu eingereicht werden",
        MUTATION: "wurde zur Mutation den entsprechenden Stellen zugestellt",
      },
    },
    ACTIONS: {
      EDIT: "Bearbeiten",
      DOCUMENT_VIEW: "Dokumentenansicht",
      GENERATE_PDFS: {
        BUTTON_TEXT: "Generiere PDFs",
        SUCCESS: "PDFs konnten erfolgreich generiert werden",
        FAILED: "Es ist ein Fehler aufgetreten. Die PDFs konnten nicht erstellt werden!",
      },
      MUTATION: "Mutation",
      CANCEL: "Kündigung",
      DELETE: "Löschen",
    },
  },
  DOCUMENTS_OVERVIEW: {
    TITLE: "Dokumentenansicht",
    APPLICATION_DOCUMENT_LABEL: "Details Antrag",
    APPLICATION_DOCUMENT: "Antragsformular",
    LAND_REGISTERS_LABEL: "Eigentumsnachweise",
    POWER_OF_ATTORNEY_LABEL: "Unterschriften Grundeigentümer/Innen",
    POWER_OF_ATTORNEY: "Vollmacht / Erklärung Ansprechpartner",
    POWER_OF_ATTORNEY_INVOICE_RECIPIENT: "Vollmacht Rechnungsempfänger",
    POWER_OF_ATTORNEY_INVOICE_RECIPIENT_ADDRESS: "Vollmacht Rechnungsempfänger Adressänderung",
    CANCELLATION: "Kündigung",
    MERGED_PDF: "Gesamt-PDF",
    MUTATION_LANDOWNER: "Grundeigentümer Eigentümerwechsel",
    MUTATION_CONTACT_PERSON_ADDRESS: "Ansprechpartner Adressänderung",
    MUTATION_CONTACT_PERSON_NEW: "Ansprechpartnerwechsel",
    MIGRATED_MUTATION_LABEL: "Mutationen",
    MIGRATED_MUTATION: "Mutation",
    MUTATION_MIGRATION_ACTIVATION: "Mutationsmeldung «Bestehende ZEV-Verträge aufschalten»",
  },
  MUTATION_OVERVIEW: {
    TITLE: "Mutationsauswahl",
    SUBTITLE: "Hier können Sie die gewünschte Mutation auswählen",
    LANDOWNER: "Mutieren Grundeigentümer/In",
    INVOICE_RECIPIENT_ADDRESS: "Mutieren Rechnungsempfänger/In (Adresse)",
    INVOICE_RECIPIENT_NEW: "Mutieren Rechnungsempfänger/In (Neu)",
    CONTACT_PERSON_ADDRESS: "Mutieren Ansprechpartner (Adresse)",
    CONTACT_PERSON_NEW: "Mutieren Ansprechpartner (Neu)",
  },
  APPLICATION_APPROVAL_DIALOG: {
    ID: "ID des Antrages: ",
    CONNECTION_OBJECT_NUMBER: "AO-Nummer",
    APPROVED: "Antrag Bewilligt",
    CANCEL: "Abbrechen",
    CONFIRM: "Bestätigen",
  },
  CHECK_CONTACT_PERSON_MUTATION: {
    ID: "ID der Mutation: ",
    CHECKED: "Unterschrift geprüft",
    INVALID: "Mutation Ungültig",
    CANCEL: "Abbrechen",
    CONFIRM: "Bestätigen",
  },
  CANCELLATION_APPROVAL_DIALOG: {
    ID: "ID der Kündigung: ",
    DRAFT: "Status auf “Erfasst“ zurückstellen",
    APPROVED: "Kündigung Bewilligt",
    CANCEL: "Abbrechen",
    CONFIRM: "Bestätigen",
  },
  APPLICATION_NOTIFICATION_MESSAGES: {
    APPLICATION: {
      DRAFT: "Antrag erfolgreich gespeichert. Mit dem Butten “Bearbeiten“ können Sie den Antrag weiter bearbeiten.",
      COMPLETE: "Besten Dank für Ihren Antrag. Der Antrag wurde am Elektroinstallateur zur Einreichung der Meldeformulare weitergeleitet.",
      UPDATED: "Der Antrag wurde erfolgreich aktualisiert.",
      DELETED: "Der Antrag wurde erfolgreich gelöscht.",
      DELETION_FAILED: "Es ist zu einem Fehler beim löschen des Antrags gekommen.",
      UPDATE_FAILED: "Es ist zu einem Fehler beim aktualisieren des Antrags gekommen.",
    },
    MUTATION: {
      DRAFT: "Erfolgreich gespeichert. Damit die Mutation per E-Mail versandt wird, wählen Sie bitte den Button “Einreichen der Mutation”.",
      COMPLETE: "Besten Dank für Ihre Mutationsmeldung. Die Mutation wird zeitnah umgesetzt.",
      UPDATED: "Die Mutation wurde erfolgreich aktualisiert.",
      DELETED: "Die Mutation wurde erfolgreich gelöscht.",
      DELETION_FAILED: "Es ist zu einem Fehler beim löschen der Mutation gekommen.",
      UPDATE_FAILED: "Es ist zu einem Fehler beim aktualisieren der Mutation gekommen.",
    },
    CONNECTION_OBJECT_NUMBER_UPDATED: "Die AO-Nummer wurde erfolgreich aktualisiert.",
    APPROVED: "Der Antrag wurde erfolgreich Bewilligt.",
    CANCELLATION_COMPLETE: "Besten Dank für Ihre Mutation. Wir werden Ihre Kündigung prüfen.",
    CANCELLATION_APPROVED: "Die Kündigung wurde erfolgreich Bewilligt.",
    CANCELLATION_REVERTED: "Der Kündigungsstatus wurde erfolgreich zurückgesetzt.",
    STATE_CHANGED: "Der Status wurde erfolgreich geändert.",
    FAILED: "Ihre Angaben sind nicht korrekt, bitte überprüfen Sie die Felder. Besten Dank.",
    MIGRATION_IN_PROGRESS: "Die Migration der ZEV-Verträge ist im Gange. Bitte haben Sie etwas Geduld.",
    MIGRATION_FAILED: "Es ist zu einem Fehler bei der Migration der ZEV-Verträge gekommen.",
    MIGRATED: "Die Migration der ZEV-Verträge ist abgeschlossen.",
    ACTIVATED: "Besten Dank für Ihre Mutationsmeldung.",
    ERROR_DOCUMENTS_UPDATE: "Es ist zu einem Fehler beim aktualisieren der Dokumente gekommen.",
  },
  DELETE_APPLICATION_DIALOG: {
    APPLICATION: {
      TITLE: "Antrag löschen",
      CONFIRMATION_TEXT: "Sind Sie sicher, dass Sie den Antrag löschen wollen?",
    },
    MUTATION: {
      TITLE: "Mutation löschen",
      CONFIRMATION_TEXT: "Sind Sie sicher, dass Sie die Mutation löschen wollen?",
    },
    CANCEL: "Abbrechen",
    CONFIRM: "Löschen",
  },
  MUTATION_INVOICE_RECIPIENT_DIALOG: {
    TITLE_NEW: "Mutieren Rechnungsempfänger/In (Neu)",
    TITLE_ADDRESS: "Mutieren Rechnungsempfänger/In (Adresse)",
    INTRO: "Sollen die Rechnungen und die Vergütung der Überschussenergie für den Zusammenschluss zum Eigenverbrauch nicht durch die Ansprechpartnerin oder den Ansprechpartner, sondern durch eine Drittperson oder Firma abgewickelt werden, so ist uns das Formular “Vollmacht Rechnungsempfänger” unvzerzüglich einzureichen.",
    INVOICE_RECIPIENT: "Rechnungsempfängerin/Rechnungsempfänger des Zusammenschlusses zum Eigenverbrauch",
    CANCEL: "Abbrechen",
    SAVE: "Speichern",
    COMPLETE: "Einreichen der Mutation",
  },
  MUTATION_CONTACT_PERSON_DIALOG: {
    TITLE_ADDRESS: "Mutieren Ansprechpartner (Adresse)",
    TITLE_NEW: "Mutieren Ansprechpartner (Neu)",
    HELPTEXT: "Tritt die bestehende Ansprechpartnerin oder der bestehende Ansprechpartner aus und übernimmt diese Funktion und Verantwortung eine andere Ansprechpartnerin oder ein anderer Ansprechpartner, so ist uns das Formular “Mutationsmeldung Ansprechpartner” unverzüglich einzureichen.",
    TOOLTIP: "Jeder Zusammenschluss zum Eigenverbrauch ist gesetzlich verpflichtet, dem Verteilnetzbetreiber (nachfolgend der BKW) eine Ansprechpartnerin oder einen Ansprechpartner zu melden. Das Vertragsverhältnis wird über diese(n) abgewickelt. Konkret bedeutet dies, dass insbesondere die Avisierung von Stromunterbrüchen sowie die übrige Korrespondenz nur an die Ansprechpartnerin oder den Ansprechpartner des Zusammenschlusses zum Eigenverbrauch erfolgt. Die Grundeigentümerinnen und Grundeigentümer oder sonstige Teilnehmer des Zusammenschlusses werden von der BKW mit Ausnahme der Aufforderung zur periodischen Kontrolle nicht kontaktiert. Die Ansprechpartnerin oder der Ansprechpartner verfügt des Weiteren über die Berechtigung, der BKW Mutationen zu melden (wie unter anderem der Wechsel der Grundeigentümer oder des Rechnungsempfängers).",
    SUBTITLE_ADDRESS: "Angaben zur Adressänderung Ansprechpartner",
    SUBTITLE_NEW: "Angaben zum neuen Ansprechpartner",
    INTRO_ADDRESS: "Ändert sich bei Ihnen die Adresse, so ist uns die neue Adresse unverzüglich mitzuteilen, damit wir künftige Korrespondenz an Ihre neue Adresse senden können. Geben Sie Ihre neue Adresse in den nachfolgenden Felder ein und reichen Sie uns die Meldung mit dem Button “Einreichen der Mutation“ ein.",
    INTRO_NEW: "Die Grundeigentümerinnen und Grundeigentümer bevollmächtigen folgende Ansprechpartnerin oder folgenden Ansprechpartner, den Zusammenschluss und somit ihre Interessen im Zusammenhang mit dem Zusammenschluss zum Eigenverbrauch gegenüber der BKW verbindlich zu vertreten und wahrzunehmen:",
    SUBTITLE_MUTATION: "Mutationsmeldung Ansprechpartner",
    MUTATION_1: "Erklärung: Mit der Unterzeichnung dieser Mutationsmeldung erklärt die Ansprechpartnerin oder der Ansprechpartner, die aus ihrer oder seiner Funktion und den",
    MUTATION_2: "Besonderen Geschäftsbedingungen der BKW Energie AG Zusammenschluss zum Eigenverbrauch",
    MUTATION_3: "sowie den Allgemeinen Geschäftsbedingungen der BKW in den jeweils gültigen Fassungen, insbesondere den AGB der BKW für den Netzanschluss und die Netznutzung, resultierenden Rechte und Pflichten zu kennen und zu akzeptieren.",
    NOTE_1: "Falls das Formular nicht vollständig ausgefüllt ist,",
    NOTE_2: "werden die Unterlagen bei Einreichung zurückgewiesen.",
    PRINT: "Mutationsmeldung Ansprechpartner drucken",
    UPLOAD: "Mutationsmeldung Ansprechpartner mit Unterschriften hochladen",
    NEW_CONTACT_PERSON_NOTE: "Damit wir den ZEV der neuen Ansprechpartnerin bzw. dem neuen Ansprechpartner zuordnen können, muss diese bzw. dieser sich im BKW ZEV-Kundenportal registrieren. Wir werden Sie per Mail informieren, sobald der ZEV-Antrag der neuen Ansprechpartnerin bzw. dem neuen Ansprechpartner zugewiesen ist.",
    CANCEL: "Abbrechen",
    SAVE: "Speichern",
    COMPLETE: "Einreichen der Mutation",
  },
  CANCELLATION_DIALOG: {
    INTRO_1: "Wollen Sie wirklich den ganzen ZEV kündigen?",
    INTRO_2: "Wollen sie den Zusammenschluss zum Eigenverbrauch auf ein bestimmtes Datum auflösen? So ist uns mindestens drei Monate im Voraus das Formular “Kündigung Zusammenschluss zum Eigenverbrauch” einzureichen. Bitte beachten Sie, dass die Kündigung erst dann wirksam wird, wenn zeitgleich auch die entsprechenden Meldeformulare für die Versorgung der einzelnen Verbrauchsstätten von Ihrer Elektroinstallateurin oder Ihrem Elektroinstallateur eingereicht werden.",
    INTRO_3: "Hier können Sie das Kündigungsformular ausdrucken, mit den Unterschriften sämtlicher Grundeigentümerinnen und Grundeigentümer ergänzen und einreichen.",
    DATE_CONFIRMATION: "Ich/wir bestätigen die vorliegende Kündigung per Ende",
    SIGNATURES_LANDOWNERS: "Unterschriften Grundeigentümer",
    NOTE_1: "Falls das Formular nicht vollständig ausgefüllt ist,",
    NOTE_2: "wird das Kündigungsformular zur Vervollständigung zurückgewiesen.",
    PRINT_CANCELLATION_FORM: "Kündigungsformular drucken",
    UPLOAD_CANCELLATION_FORM: "Kündigung Zusammenschluss zum Eigenverbrauch mit Unterschriften hochladen",
    COMPLETE: "Kündigung einreichen",
  },
  MIGRATE_APPLICATIONS_DIALOG: {
    QUESTION_1: "Wollen Sie die Migration ZEV-Verträge ins Kundenportal wirklich starten?",
    QUESTION_2: "Ist das Excel “Datenübernahme” für die Migration aktualisiert? Das Excel darf während der Migration nicht in Bearbeitung sein.",
    STATEMENT: "Zur Migration sind {{count}} Ansprechpartner bereit.",
  },
  LANDOWNER_MUTATION: {
    TITLE: "Mutieren Grundeigentümer/In",
    SUBTITLE_1: "Abmeldung: Sie haben die Möglichkeit, entweder eine bzw. einen oder alle Grundeingentümerinnen bzw. Grundeigentümer zu einer Verbrauchsstätte abzumelden. Wollen Sie bei einer Verbrauchsstätte einzelne Grundeigentümerinnen bzw. Grundeigentümer abmelden, haben Sie uns für jede abgemeldete Grundeigetnümerin bzw. Grundeigentümer eine separate Mutationsmeldung einzureichen.",
    SUBTITLE_2: "Anmeldung: Sie können pro Verbrauchsstätte eine bzw. einen oder mehrere Grundeigentümerinnen bzw. Grundeigentümer anmelden.",
    TOOLTIP: "Tritt eine bestehende Grundeigentümerin oder ein bestehender Grundeigentümer aus dem ZEV aus und tritt an deren oder dessen Stelle eine bestehende oder neue Grundeigentümerin oder ein bestehender oder neuer Grundeigentümer in den ZEV ein, so ist uns das Formular “Mutationsmeldung Grundeigentümer” unverzüglich einzureichen.",
    DEREGISTER: "abmelden",
    BACK_LINK: "« Zurück zu der Mutationsauswahl",
    PLACE_OF_CONSUMPTION: "Betroffene Verbrauchstätte: ",
    LANDOWNER: "Grundeigentümer/Innen zum abmelden",
    NEW_LANDOWNER: "Bitte erfassen Sie die Angaben zur neuen Grundeigentümerin bzw. Grundeigentümer",
    INPUT_CONFIRMATION: "Eingabebestätigung",
  },
  ACTIVATE_MIGRATED_APPLICATIONS_DIALOG: {
    INTRO_1: "Hier können Sie alle Ihre Papier ZEV-Verträge (Vertrag über die Einrichtung des Eigenverbrauchs)",
    INTRO_12:" die vor dem August 2022 erstellt wurden",
    INTRO_13: "auf diesem Portal aufschalten. Geben Sie bitte die Kunden-Nummer im nachfolgenden Feld ein und wählen Sie die Papier ZEV-Verträge aus, die Sie in Ihrer Funktion als Ansprechpartnerin bzw. Ansprechpartner betreuen. Die Kunden-Nummer finden Sie auf Ihrer ZEV-Rechnung.",
    INTRO_2: "Nach der erfolgreichen Aufschaltung der Papier ZEV-Verträge können Sie über dieses Portal Mutationen zu den einzelnen Zusammenschlüssen zum Eigenverbrauch einreichen.",
    INTRO_3: "Hinweis: Die Aufschaltung für ZEV-Verträge ist nicht für ZEV-Anträge gedacht, welche bereits über dieses Portal eingereicht worden sind.",
    GP_NUMBER: "Kunden-Nummer",
    GP_NUMBER_TOOLTIP: "Die Kunden-Nummer finden Sie auf Ihrer ZEV-Rechnung.",
    GP_NUMBER_CONFIRMATION: "Bestätigung Kunden-Nummer",
    INPUT_CONFIRMATION: "Eingabebestätigung",
    CONFIRM_TEXT: "Bitte bestätigen Sie die Betreuung und Aufschaltung der aufgeführten ZEV-Verträge mit Ja/Nein.",
    RADIO_BUTTONS: {
      YES: "Ja",
      NO: "Nein",
    },
    ACTIVATION_MISSING: "Es fehlt eine Auswahl. Bitte bestätigen Sie mit Ja/Nein.",
    ACCEPTANCE_INFO: "Die Grundeigentümerinnen und Grundeigentümer des Zusammenschlusses zum Eigenverbrauch und die Ansprechpartnerin oder der Ansprechpartner erklären, die Besonderen Geschäftsbedingungen der BKW Energie AG Zusammenschluss zum Eigenverbrauch (ZEV) sowie die aktuellen und geltenden Werkvorschriften zur Kenntnis genommen, verstanden und akzeptiert zu haben.",
    LINK_TITLE: "Link zu den BGB ZEV und weiteren AGB",
    LINK_TEXT: "AGB & Rechtliches - BKW",
    LINK: "https://www.bkw.ch/de/agb-rechtliches",
    AGREEMENT_LABEL: "Ich/wir stimmen den Besonderen Geschäftsbedingungen der BKW Energie AG Zusammenschluss zum Eigenverbrauch (ZEV) zu.",
    NOT_FOUND: {
      APPLICATIONS_NOT_FOUND: "Wir haben für Ihre Kunden-Nummer keine ZEV-Verträge zur Aufschaltung gefunden.",
      MISSING: "Fehlen Ihnen ZEV-Verträge zur Aufschaltung?",
      FILL_IN: "Bitte geben Sie die Angaben zu den fehlenden ZEV-Vertägen an.",
      FILL_IN_TEXT: "gilt nur für Kunden mit alten Papierverträgen",
      CONNECTION_OBJECT_NUMBER: "Anschlussobjekt-Nummer",
      ADDRESS: "ZEV-Adresse (Strasse, Nummer, PLZ, Ort)",
      ADD_APPLICATION: "weiteren ZEV-Vertrag erfassen",
      REMOVE_APPLICATION: "ZEV-Vertrag entfernen",
      COMMENT: "Haben Sie noch Anmerkungen?",
      INFO: "Ihre Angaben zu den fehlenden ZEV-Verträgen und Bemerkungen werden beim «Einreichen der Mutation» an die zuständige Sachbearbeiterperson weitergeleitet, welche sich bei Ihnen melden wird. Die Bearbeitung wird eine gewisse Zeit in Anspruch nehmen. Wir bitten Sie daher um etwas Geduld.",
    },
    CANCEL: "Abbrechen",
    COMPLETE: "Einreichen der Mutation",
  },
  DOCUMENT_DOWNLOAD: {
    NOT_FOUND: "Das Dokument konnte nicht gefunden werden.",
    ERROR: "Es ist zu einem Fehler gekommen.",
    HINT: "Hinweis",
    HINT_TEXT_1: "Wenn sich das Dokument nicht automatisch öffnet, sollten Sie den Popup Blocker deaktivieren. Am oberen Rand Ihres Browsers auf der rechten Seite können Sie den Popup Blocker deaktivieren.",
    HINT_TEXT_2: "Oder Sie können den nachfolgenden Link betätigen, um das Dokument zu öffnen.",
    OPEN_DOCUMENT: "Dokument öffnen",
  },
  APPLICATION_CREATION_HINT: {
    TITLE: "Achtung",
    TEXT_1_1: "Das Antragsformular darf nur durch die von den Grundeigentümerinnen und Grundeigentümern",
    TEXT_1_2: "bevollmächtigte Ansprechpartnerin bzw. Ansprechpartner",
    TEXT_1_3: "eingereicht werden.",
    TEXT_2_1: "Das Login ist kundenbezogen. Spätere Änderungen bzw. Mutationen müssen durch dieses Portal (Login) gemeldet werden.",
  },
  BACK_LINK: "« Zurück zu Ihren Anträgen",
};
