import { Inject, Injectable } from "@angular/core";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { BfcAuthenticationService } from "@bfl/components/authentication";
import { AccountService } from "../service/account.service";
import { UrlHelperService } from "../service/url.helper.service";
import { AuthGuardZev } from "../auth/auth-guard-zev.service";

@Injectable()
export class InitializerProvider {

  private readonly comesFromParam: string = "comesFrom";

  constructor(
    @Inject(BfcConfigurationService) private bfcConfigurationService: BfcConfigurationService,
    @Inject(BfcAuthenticationService) private bfcAuthenticationService: BfcAuthenticationService,
    @Inject(AccountService) private accountService: AccountService,
    @Inject(UrlHelperService) private urlHelperService: UrlHelperService,
    @Inject(AuthGuardZev) private authGuard: AuthGuardZev,
  ) {
  }

  initialize(): Promise<void> {

    return new Promise((resolve) => {

      const globalNavElements = document.getElementsByTagName(
        "bfe-global-navigation",
      );
      if (globalNavElements.length !== 1) {
        throw new Error("too many or no global navigation element found");
      }

      this.grantAccountIfComesFromLandingpage(resolve); // resolve() will be done inside this method
    });

  }

  private grantAccountIfComesFromLandingpage(resolve: () => void) {
    if (this.comesFromLandingPage()) {
      const isAuthenticated: boolean =
          this.bfcAuthenticationService.authenticated;

      if (!isAuthenticated) {
        this.bfcAuthenticationService.login();
      } else {
        // we come from landingpage, so check for ROLE_ZEV / ROLE_ZEV_ADMIN, if not present, grant the user
        const isUser: boolean =
            this.bfcAuthenticationService.hasRealmRole(this.bfcConfigurationService.configuration.userRole);
        const isAdmin: boolean =
            this.bfcAuthenticationService.hasRealmRole(this.bfcConfigurationService.configuration.adminRole);

        if (!isUser && !isAdmin) {
          // if we got parameter "comesFrom" and value "zev-landingpage", then grant the user:
          this.accountService.grantMyAccount()
            .subscribe((succeeded: boolean) => {
              if (succeeded) {
                const targetUrl = window.location.href;
                if (this.authGuard.isUrlAllowed(targetUrl)) {
                  window.location.href = "/onboarding?from=" + encodeURIComponent(targetUrl); // to force reload / refresh token
                }
                resolve();
              }
            },
            );
        } else {
          resolve();
        }
      }
    } else {
      resolve();
    }

  }

  private comesFromLandingPage(): boolean {
    const paramValue = this.urlHelperService.getParameterValue(this.comesFromParam);
    return paramValue && (paramValue === "zev-landingpage" || paramValue.startsWith("zev-landingpage"));
  }
}
