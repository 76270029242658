/**
 * ZEV Api
 * Zusammenschluss Eigenverbrauch API
 *
 * The version of the OpenAPI document: v1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DocHubDocument } from './docHubDocument';
import { ConnectionObjectDetails } from './connectionObjectDetails';
import { ContactDetails } from './contactDetails';
import { EstiInstaller } from './estiInstaller';
import { PlaceOfConsumption } from './placeOfConsumption';


export interface Application { 
    _id?: string;
    accountId?: string;
    applicationCancellationDocument?: DocHubDocument;
    applicationDocuments?: Array<DocHubDocument>;
    applicationId?: number;
    cancellationCompletedOrApproved?: boolean;
    cancellationDate?: string;
    comments?: string;
    connectionObjectDetails?: ConnectionObjectDetails;
    connectionObjectNumber?: number;
    contactDetails?: Array<ContactDetails>;
    customInstallerEmail?: string;
    customInstallerEmailValidation?: string;
    gpNumber?: number;
    installer?: EstiInstaller;
    landRegisterDocuments?: Array<DocHubDocument>;
    lastUpdate?: string;
    mergedDocumentName?: string;
    migratedMutationDocuments?: Array<DocHubDocument>;
    mutatedApplicationObjectId?: string;
    mutationContactPersonDocument?: DocHubDocument;
    mutationId?: number;
    mutationLandownerDocument?: DocHubDocument;
    mutationMigrationActivationDocument?: DocHubDocument;
    mutationType?: Application.MutationTypeEnum;
    newContactPerson?: ContactDetails;
    newLandowners?: Array<ContactDetails>;
    oldLandowner?: ContactDetails;
    placesOfConsumption?: Array<PlaceOfConsumption>;
    powerOfAttorneyDocuments?: Array<DocHubDocument>;
    powerOfAttorneyInvoiceRecipientAddressDocument?: DocHubDocument;
    powerOfAttorneyInvoiceRecipientDocument?: DocHubDocument;
    revisionId?: number;
    status?: Application.StatusEnum;
    type?: Application.TypeEnum;
    uploadedMutationContactPersonDocument?: DocHubDocument;
    uploadedMutationLandownerDocuments?: Array<DocHubDocument>;
    welcomeEmailSent?: boolean;
}
export namespace Application {
    export type MutationTypeEnum = 'INVOICE_RECIPIENT_ADDRESS' | 'INVOICE_RECIPIENT_NEW' | 'LANDOWNER_CHANGE_INTERN' | 'LANDOWNER_CHANGE_EXTERN' | 'CONTACT_PERSON_ADDRESS' | 'CONTACT_PERSON_NEW' | 'CANCELLATION' | 'MIGRATED_MUTATION';
    export const MutationTypeEnum = {
        InvoiceRecipientAddress: 'INVOICE_RECIPIENT_ADDRESS' as MutationTypeEnum,
        InvoiceRecipientNew: 'INVOICE_RECIPIENT_NEW' as MutationTypeEnum,
        LandownerChangeIntern: 'LANDOWNER_CHANGE_INTERN' as MutationTypeEnum,
        LandownerChangeExtern: 'LANDOWNER_CHANGE_EXTERN' as MutationTypeEnum,
        ContactPersonAddress: 'CONTACT_PERSON_ADDRESS' as MutationTypeEnum,
        ContactPersonNew: 'CONTACT_PERSON_NEW' as MutationTypeEnum,
        Cancellation: 'CANCELLATION' as MutationTypeEnum,
        MigratedMutation: 'MIGRATED_MUTATION' as MutationTypeEnum
    };
    export type StatusEnum = 'DRAFT' | 'COMPLETE' | 'APPROVED' | 'CANCELLED' | 'INVALID' | 'CHECKED' | 'MIGRATED';
    export const StatusEnum = {
        Draft: 'DRAFT' as StatusEnum,
        Complete: 'COMPLETE' as StatusEnum,
        Approved: 'APPROVED' as StatusEnum,
        Cancelled: 'CANCELLED' as StatusEnum,
        Invalid: 'INVALID' as StatusEnum,
        Checked: 'CHECKED' as StatusEnum,
        Migrated: 'MIGRATED' as StatusEnum
    };
    export type TypeEnum = 'APPLICATION' | 'MUTATION';
    export const TypeEnum = {
        Application: 'APPLICATION' as TypeEnum,
        Mutation: 'MUTATION' as TypeEnum
    };
}


