import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { BfcTranslationModule } from "@bfl/components/translation";
import { DocumentDownloadComponent } from "./document-download.component";
import { DocumentService } from "../service/document.service";
import { BfcLoadingModule } from "@bfl/components/loading";
import { BfcNotificationModule } from "@bfl/components/notification";
import { BfcSinglePageLayoutModule } from "@bfl/components/single-page-layout";
import { BfcBasePageLayoutModule } from "@bfl/components/base-page-layout";
import { BfcButtonModule } from "@bfl/components/button";

@NgModule({
  imports: [
    BfcTranslationModule,
    FlexLayoutModule,
    CommonModule,
    BfcSinglePageLayoutModule,
    BfcLoadingModule,
    BfcNotificationModule,
    BfcBasePageLayoutModule,
    BfcButtonModule,
  ],
  declarations: [
    DocumentDownloadComponent,
  ],
  providers: [
    DocumentService,
  ],
})
export class DocumentDownloadModule {
}