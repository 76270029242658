import { IZevConfiguration } from "../configuration";

export const GENERAL_ENV: IZevConfiguration = {
  userRole: "ROLE_ZEV",
  adminRole: "ROLE_ZEV_ADMIN",
  cscAdminRole: "ROLE_ZEV_CSC_ADMIN",
  dateFormat: "dd.MM.yyyy",
  textAreaMaxLength: 600,
  migratedApplicationMinId: 7000,
  uploadConfigs: {
    allowedExtensions: ".jpg,.png,.jpeg,.pdf",
    maxFileSizeToUpload: 4194304, // 4 MB
    maxAttachmentsSize: 35000000, // 35MB
  },
};
