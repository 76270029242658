import { Injectable } from "@angular/core";

/**
 *  This generator is used for unique Character generation from B to Z, examples:
 *  B, C, D, ....,Z, AA, AB, AC,...,AZ...,BA...
 */
@Injectable()
export class StringIdGenerator {

  private readonly chars: string = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

  private readonly _nextId: number[];

  constructor() {
    // "A" is per default already taken, so start form index 1;
    this._nextId = [1];
  }

  next() {
    const result = [];
    for (const char of this._nextId) {
      result.unshift(this.chars[char]);
    }
    this.increment();
    return result.join("");
  }

  private increment() {
    for (let i = 0; i < this._nextId.length; i++) {
      const val = ++this._nextId[i];
      if (val >= this.chars.length) {
        this._nextId[i] = 0;
      } else {
        return;
      }
    }
    this._nextId.push(0);
  }
}