export class PatternConstants {
  public static readonly PHONE_NUMBER_PATTERN: string = "^(\\+\\d)?[0-9 ]+$";

  public static readonly POSTAL_CODE_PATTERN: string = "^[\\w\\d\\s#-]{4,}$";

  public static readonly METER_NUMBER_PATTERN: string = "^\\d{1,14}$";

  public static readonly HOUSE_NUMBER_PATTERN: string = "^[\\w\\d\\s]+$";

  public static readonly GP_NUMBER_PATTERN: string = "^\\d{8}$";
}