import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuardZev } from "./core/auth/auth-guard-zev.service";
import { translations } from "./config/translations/translations";
import { OnboardingComponent } from "./core/onboarding/onboarding.component";
import { DocumentDownloadComponent } from "./core/document-download/document-download.component";

const routes: Routes = [
  {
    path: "application",
    canActivate: [AuthGuardZev],
    loadChildren: () => import("./application-creation/application-creation.module")
      .then((m) => m.ApplicationCreationModule),
  },
  {
    path: "",
    pathMatch: "full",
    redirectTo: "application",
  },
  {
    path: "unauthorized",
    loadChildren: () => import("@bfl/components/unauthorized").then(m => m.BfcUnauthorizedModule.asChild(translations)),
  },
  {
    path: "onboarding",
    component: OnboardingComponent,
  },
  {
    path: "documents/:documentId",
    component: DocumentDownloadComponent,
  },
  {
    path: "**",
    redirectTo: "",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
