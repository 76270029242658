import { Injectable } from "@angular/core";
import { CanActivate, Router } from "@angular/router";
import { BfcAuthenticationService } from "@bfl/components/authentication";
import { BfcConfigurationService } from "@bfl/components/configuration";

@Injectable()
export class AuthGuardZev implements CanActivate {

  constructor(private bfcAuthenticationService: BfcAuthenticationService,
    private bfcConfigurationService: BfcConfigurationService,
    private router: Router) {

  }

  canActivate() {
    const isAuthenticated: boolean = this.bfcAuthenticationService.authenticated;

    if (isAuthenticated) {
      const isUser: boolean =
        this.bfcAuthenticationService.hasRealmRole(this.bfcConfigurationService.configuration.userRole);

      const isAdmin: boolean =
        this.bfcAuthenticationService.hasRealmRole(this.bfcConfigurationService.configuration.adminRole);

      const isCscAdmin: boolean =
        this.bfcAuthenticationService.hasRealmRole(this.bfcConfigurationService.configuration.cscAdminRole);

      if (!isUser && !isAdmin && !isCscAdmin) {
        this.router.navigate(["/unauthorized"]);
        return false;
      }
      return true;
    } else {
      this.bfcAuthenticationService.login();
    }
  }

  isUrlAllowed(url: string): boolean {
    return url.startsWith(this.bfcConfigurationService.configuration.siteUrl);
  }
}