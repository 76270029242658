import { Component, HostBinding, OnDestroy } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { NavigationEnd, Router, RouterEvent } from "@angular/router";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

@Component({
  selector: "app-component",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnDestroy {
  @HostBinding("class")
  classes = "bfc-base";

  title = "zev-webapp";

  private readonly unsubscribe: Subject<void> = new Subject();

  constructor(private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private router: Router) {

    this.matIconRegistry.addSvgIcon(
      "plus",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/BKW_Icon_Plus.svg"),
    );

    this.matIconRegistry.addSvgIcon(
      "upload",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/BKW_Icon_Upload.svg"),
    );

    this.matIconRegistry.addSvgIcon(
      "upload-error",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/BKW_Icon_Upload_Error.svg"),
    );

    this.matIconRegistry.addSvgIcon(
      "delete",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/BKW_Icon_Delete.svg"),
    );

    this.matIconRegistry.addSvgIcon(
      "document-search",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/BKW_Icon_Document_Search.svg"),
    );

    router.events.pipe(takeUntil(this.unsubscribe)).subscribe((event: RouterEvent) => {
      if (event instanceof NavigationEnd) {
        scrollTo({ top: 0, behavior: "smooth" });
      }
    });

  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
