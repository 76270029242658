import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { Observable, of, Subscriber, throwError } from "rxjs";
import { catchError, filter, shareReplay, switchMap } from "rxjs/operators";
import { BfcNotificationService, BfcNotificationType } from "@bfl/components/notification";
import { BfcTranslationService } from "@bfl/components/translation";

@Injectable()
export class DocHubDocumentService {
  private readonly apiUrl;

  private documentContentCache$: Map<string, Observable<string>> = new Map();

  private documentContentCacheForPDF$: Map<string, Observable<ArrayBuffer>> = new Map();

  constructor(private httpClient: HttpClient,
    private bfcConfigurationService: BfcConfigurationService,
    private bfcNotificationService: BfcNotificationService,
    private bfcTranslationService: BfcTranslationService) {
    this.apiUrl = this.bfcConfigurationService.configuration.apiUrl;
  }

  getDocumentContent(documentId: string, applicationId?: string): Observable<string> {
    if (!this.documentContentCache$.has(documentId)) {
      let url: string = "";
      if (applicationId) {
        url = `${this.apiUrl}/applications/${applicationId}/documents/${documentId}/file`;
      } else {
        url = `${this.apiUrl}/documents/${documentId}/file`;
      }
      this.documentContentCache$.set(documentId, this.httpClient.get(url, { responseType: "blob" })
        .pipe(
          filter((blob: Blob) => !!blob),
          switchMap((data: Blob): Observable<string> =>
            new Observable<string>((observable: Subscriber<string>) => {
              const reader: FileReader = new FileReader();
              reader.onload = () => {
                observable.next((reader.result as string).split(",")[1]);
                observable.complete();
              };
              reader.readAsDataURL(data);
            }),
          ),
          catchError((error: unknown) => {
            if ((error as HttpErrorResponse)?.status === 502) {
              this.bfcNotificationService.showNotification({
                type: BfcNotificationType.WARNING,
                message: this.bfcTranslationService.translate("DOCUMENT_DOWNLOAD.NOT_FOUND"),
                options: { duration: 3000 },
              });
              return of(null);
            } else {
              return throwError(error);
            }
          }),
          shareReplay({ bufferSize: 1, refCount: true }),
        ));
    }
    return this.documentContentCache$.get(documentId);
  }

  getDocumentContentForPDF(documentId: string, applicationId: string): Observable<ArrayBuffer> {
    if (!this.documentContentCacheForPDF$.has(documentId)) {
      const url: string = `${this.apiUrl}/applications/${applicationId}/documents/${documentId}/file`;

      this.documentContentCacheForPDF$.set(documentId, this.httpClient.get(url, { responseType: "blob" })
        .pipe(
          filter((blob: Blob) => !!blob),
          switchMap((blob: Blob): Observable<ArrayBuffer> =>
            new Observable<ArrayBuffer>((observable: Subscriber<ArrayBuffer>) => {
              const reader: FileReader = new FileReader();
              reader.onload = () => {
                observable.next(reader.result as ArrayBuffer);
                observable.complete();
              };
              reader.onerror = () => {
                observable.error(reader.error);
              };
              reader.readAsArrayBuffer(blob);
            }),
          ),
          catchError((error: unknown) => {
            if ((error as HttpErrorResponse)?.status === 502) {
              this.bfcNotificationService.showNotification({
                type: BfcNotificationType.WARNING,
                message: this.bfcTranslationService.translate("DOCUMENT_DOWNLOAD.NOT_FOUND"),
                options: { duration: 3000 },
              });
              return of(null);
            } else {
              return throwError(error);
            }
          }),
          shareReplay({ bufferSize: 1, refCount: true }),
        ));
    }
    return this.documentContentCacheForPDF$.get(documentId);
  }
}
