import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { Observable } from "rxjs";
import { Document } from "../../generated/zev-backend/model/document";
import { take } from "rxjs/operators";
import { BfcNotificationService, BfcNotificationType } from "@bfl/components/notification";
import { BfcTranslationService } from "@bfl/components/translation";

@Injectable()
export class DocumentService {
  private readonly linkedApplicationIdParameter: string = "linkedApplicationId";

  private readonly gpNumberParameter: string = "gpNumber";

  constructor(private httpClient: HttpClient,
    private bfcConfigurationService: BfcConfigurationService,
    private bfcNotificationService: BfcNotificationService,
    private bfcTranslationService: BfcTranslationService) {
  }

  public addMergedDocument(
    base64MergedDocumentsPdf: string,
    linkedApplicationId?: string,
    gpNumber?: number,
  ): void {
    this.addDocumentGetObservable(
      base64MergedDocumentsPdf, linkedApplicationId, gpNumber,
    ).pipe(take(1)).subscribe(
      () => {
      }, () => {
        this.showApplicationErrorNotification();
      });
  }

  public addDocumentGetObservable(
    documentData: string, linkedApplicationId?: string, gpNumber?: number): Observable<Document> {

    let apiUrl: string = this.bfcConfigurationService.configuration.apiUrl + "/documents";

    let params: HttpParams = new HttpParams();

    if (linkedApplicationId) {
      apiUrl += "/standard";
      params = params.set(this.linkedApplicationIdParameter, linkedApplicationId);
    }

    if (gpNumber) {
      apiUrl += "/activation";
      params = params.set(this.gpNumberParameter, gpNumber);
    }

    return this.httpClient.post<Document>(apiUrl, documentData, { params: params });
  }

  private showApplicationErrorNotification() {
    this.bfcNotificationService.showNotification({
      type: BfcNotificationType.ERROR,
      message: this.bfcTranslationService.translate("APPLICATION_NOTIFICATION_MESSAGES.FAILED"),
      options: {
        duration: 0,
      },
    });
  }

}
