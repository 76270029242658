import { Injectable } from "@angular/core";
import { MimeTypeEnum } from "../model/mime-type.enum";

@Injectable()
export class Base64HelperService {

  public openBase64(base64Data: string, type?: string): void {
    if (!!type || base64Data.startsWith("data:")) {
      //it's an uploaded file, so it needs to be trimmed
      const index: number = base64Data.indexOf(",");
      base64Data = base64Data.substring(index + 1);
    }

    const blob: Blob = this.base64toBlob(base64Data, type);
    const blobUrl = URL.createObjectURL(blob);

    window.open(blobUrl);

  }

  private base64toBlob(base64Data: string, type?: string): Blob {
    const sliceSize: number = 1024;
    const byteCharacters: string = window.atob(base64Data);
    const bytesLength: number = byteCharacters.length;
    const slicesCount: number = Math.ceil(bytesLength / sliceSize);
    const byteArrays: Uint8Array[] = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }

    let blob: Blob;

    if (!!type) {
      blob = new Blob(byteArrays, { type: type });
    } else {
      blob = new Blob(byteArrays, { type: MimeTypeEnum.PDF });
    }

    return blob;
  }
}