export enum PdfType {
  POWER_OF_ATTORNEY = "POWER_OF_ATTORNEY",
  POWER_OF_ATTORNEY_INVOICE_RECIPIENT = "POWER_OF_ATTORNEY_INVOICE_RECIPIENT",
  POWER_OF_ATTORNEY_INVOICE_RECIPIENT_ADDRESS = "POWER_OF_ATTORNEY_INVOICE_RECIPIENT_ADDRESS",
  APPLICATION = "APPLICATION",
  CANCELLATION = "CANCELLATION",
  MUTATION_LANDOWNER_INTERN = "MUTATION_LANDOWNER_INTERN",
  MUTATION_LANDOWNER_EXTERN = "MUTATION_LANDOWNER_EXTERN",
  MUTATION_CONTACT_PERSON_NEW = "MUTATION_CONTACT_PERSON_NEW",
  MUTATION_CONTACT_PERSON_ADDRESS = "MUTATION_CONTACT_PERSON_ADDRESS",
  MUTATION_MIGRATION_ACTIVATION = "MUTATION_MIGRATION_ACTIVATION",
  LAND_REGISTER = "LAND_REGISTER",
}