import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BfcTranslationService } from "@bfl/components/translation";
import * as pdfMake from "pdfmake/build/pdfmake";
import { Observable, of, Subject } from "rxjs";
import { map, switchMap, take, tap } from "rxjs/operators";
import * as moment from "moment";
import { EstiInstaller } from "../../generated/zev-backend/model/estiInstaller";
import { ContactDetails } from "src/app/generated/zev-backend/model/contactDetails";
import { Application } from "../../generated/zev-backend/model/application";
import { PdfRow } from "./pdf-row.enum";
import { PlaceOfConsumption } from "../../generated/zev-backend/model/placeOfConsumption";
import { ConnectionObjectDetails } from "../../generated/zev-backend/model/connectionObjectDetails";
import { PdfType } from "./pdf-type.enum";
import { ApplicationService } from "../service/application.service";
import { PDFDocument, PDFImage, PDFPage } from "pdf-lib";
import { MimeTypeEnum } from "../model/mime-type.enum";
import { MigratedApplicationsStatusUpdate } from "../../generated/zev-backend/model/migratedApplicationsStatusUpdate";
import { DocHubDocumentService } from "../service/doc-hub-document.service";
import { DocHubDocument } from "../../generated/zev-backend/model/docHubDocument";
import { PdfEntity } from "./pdf-entity";
import { MissingApplication } from "../model/missing-application";
import TypeEnum = Application.TypeEnum;
import { MissingApplicationsAndComment } from "../model/missing-applications-and-comment";
import { BfcConfigurationService } from "@bfl/components/configuration";

/**
 * PDF helper for the Application details
 */
@Injectable()
export class PdfHelperService {
  private pdfFont$: Observable<any>;

  private application: Application;

  private contactPerson: ContactDetails;

  private invoiceRecipient: ContactDetails;

  private installerContact: ContactDetails;

  private installer: EstiInstaller;

  private migratedApplicationsStatusUpdates: MigratedApplicationsStatusUpdate[];

  private missingApplicationsAndComment: MissingApplicationsAndComment;

  private isMigratedApplication: boolean = false;

  private readonly filenameDateFormat = "YYYYMMDD";

  private readonly dateFormat = "DD.MM.YYYY";

  private landowners: ContactDetails[] = [];

  baseApplicationDocument: PDFDocument;

  base64MergedDocumentsPdf$: Subject<string>;

  constructor(
    private httpClient: HttpClient,
    private bfcTranslationService: BfcTranslationService,
    private applicationService: ApplicationService,
    private docHubDocumentService: DocHubDocumentService,
    private bfcConfigurationService: BfcConfigurationService) {
  }

  createBase64Pdf(application: Application,
    pdfType: PdfType,
    applications?: Application[],
    migratedApplicationsStatusUpdates?: MigratedApplicationsStatusUpdate[],
    missingApplicationsAndComment?: MissingApplicationsAndComment): Observable<string> {

    return this.getBKWFont$()
      .pipe(take(1),
        tap(pdfFont => {
          pdfMake.vfs = pdfFont;
          pdfMake.fonts = {
            KlintLTPro: {
              normal: "KlintLTPro-Regular.ttf",
              bold: "KlintLTPro-Bold.ttf",
              italics: "KlintLTPro-Regular.ttf",
              bolditalics: "KlintLTPro-Bold.ttf",
            },
          };
        }),
        map(() => this.createPdf(application, pdfType, applications,
          migratedApplicationsStatusUpdates, missingApplicationsAndComment)),
        switchMap(pdf => this.getBase64AsObservable(pdf)),
      );
  }

  openPdf(application: Application, pdfType: PdfType): void {
    this.getBKWFont$().pipe(take(1)).subscribe(pdfFont => {
      pdfMake.vfs = pdfFont;
      pdfMake.fonts = {
        KlintLTPro: {
          normal: "KlintLTPro-Regular.ttf",
          bold: "KlintLTPro-Bold.ttf",
          italics: "KlintLTPro-Regular.ttf",
          bolditalics: "KlintLTPro-Bold.ttf",
        },
      };

      this.createPdf(application, pdfType).open({}, window.open());
    });
  }

  private createPdf(application: Application,
    pdfType: PdfType,
    applications?: Application[],
    migratedApplicationsStatusUpdates?: MigratedApplicationsStatusUpdate[],
    missingApplicationsAndComment?: MissingApplicationsAndComment): any {
    this.application = application;

    if (this.application) {
      if (this.application.contactDetails && this.application.contactDetails.length > 0) {
        this.contactPerson = this.application.contactDetails.find((contact: ContactDetails) =>
          contact?.contactType === ContactDetails.ContactTypeEnum.ContactPerson);
        this.invoiceRecipient = this.application.contactDetails.find((contact: ContactDetails) =>
          contact?.contactType === ContactDetails.ContactTypeEnum.InvoiceRecipient);
        this.installerContact = this.application.contactDetails.find((contact: ContactDetails) =>
          contact?.contactType === ContactDetails.ContactTypeEnum.Installer);
      }

      this.installer = this.application.installer;
    }

    if (migratedApplicationsStatusUpdates) {
      this.migratedApplicationsStatusUpdates = migratedApplicationsStatusUpdates;
    } else {
      this.migratedApplicationsStatusUpdates = [];
    }

    if (missingApplicationsAndComment) {
      this.missingApplicationsAndComment = missingApplicationsAndComment;
    } else {
      this.missingApplicationsAndComment = null;
    }

    let pdfTypeFileName: string = "";

    switch (pdfType) {
      case PdfType.POWER_OF_ATTORNEY:
        pdfTypeFileName = this.bfcTranslationService.translate("PDF.POWER_OF_ATTORNEY.FILE_NAME");
        break;
      case PdfType.APPLICATION:
        pdfTypeFileName = this.bfcTranslationService.translate("PDF.APPLICATION.FILE_NAME");
        break;
      case PdfType.POWER_OF_ATTORNEY_INVOICE_RECIPIENT:
        pdfTypeFileName = this.bfcTranslationService.translate("PDF.POWER_OF_ATTORNEY_INVOICE_RECIPIENT.FILE_NAME");
        break;
      case PdfType.POWER_OF_ATTORNEY_INVOICE_RECIPIENT_ADDRESS:
        pdfTypeFileName = this.bfcTranslationService.translate("PDF.POWER_OF_ATTORNEY_INVOICE_RECIPIENT_ADDRESS.FILE_NAME");
        break;
      case PdfType.CANCELLATION:
        pdfTypeFileName = this.bfcTranslationService.translate("PDF.CANCELLATION.FILE_NAME");
        break;
      case PdfType.MUTATION_LANDOWNER_INTERN:
        pdfTypeFileName = this.bfcTranslationService.translate("PDF.MUTATION_LANDOWNER_INTERN.FILE_NAME");
        break;
      case PdfType.MUTATION_LANDOWNER_EXTERN:
        pdfTypeFileName = this.bfcTranslationService.translate("PDF.MUTATION_LANDOWNER_EXTERN.FILE_NAME");
        break;
      case PdfType.MUTATION_CONTACT_PERSON_NEW:
        pdfTypeFileName = this.bfcTranslationService.translate("PDF.MUTATION_CONTACT_PERSON_NEW.FILE_NAME");
        break;
      case PdfType.MUTATION_CONTACT_PERSON_ADDRESS:
        pdfTypeFileName = this.bfcTranslationService.translate("PDF.MUTATION_CONTACT_PERSON_ADDRESS.FILE_NAME");
        break;
      case PdfType.MUTATION_MIGRATION_ACTIVATION:
        pdfTypeFileName = this.bfcTranslationService.translate("PDF.MUTATION_MIGRATION_ACTIVATION.FILE_NAME");
        break;
    }

    const filename: string = `${moment().format(this.filenameDateFormat)}${pdfTypeFileName}.pdf`;

    const content = [];

    switch (pdfType) {
      case PdfType.APPLICATION:
        content.push(...this.generateApplication());
        break;
      case PdfType.POWER_OF_ATTORNEY:
        content.push(...this.generatePowerOfAttorney());
        break;
      case PdfType.POWER_OF_ATTORNEY_INVOICE_RECIPIENT:
        content.push(...this.generatePowerOfAttorneyInvoiceRecipient());
        break;
      case PdfType.POWER_OF_ATTORNEY_INVOICE_RECIPIENT_ADDRESS:
        content.push(...this.generatePowerOfAttorneyInvoiceRecipientAddress());
        break;
      case PdfType.CANCELLATION:
        content.push(...this.generateCancellation());
        break;
      case PdfType.MUTATION_LANDOWNER_EXTERN:
        content.push(...this.generateMutationLandownerExtern());
        break;
      case PdfType.MUTATION_CONTACT_PERSON_NEW:
        content.push(...this.generateMutationContactPersonNew());
        break;
      case PdfType.MUTATION_CONTACT_PERSON_ADDRESS:
        content.push(...this.generateMutationContactPersonAddress());
        break;
      case PdfType.MUTATION_MIGRATION_ACTIVATION:
        content.push(...this.generateMutationMigrationActivation(applications, missingApplicationsAndComment));
        break;
    }

    return this.generatePDF(content, filename);
  }

  async createMergedPDF(application: Application) {
    this.base64MergedDocumentsPdf$ = new Subject();
    this.baseApplicationDocument = null;

    if (application?.applicationDocuments?.length > 0) {
      try {
        const firstDocumentId: string = application.applicationDocuments[0].documentId;
        const firstDocumentData: ArrayBuffer = await this.fetchDocumentData(firstDocumentId);
        this.baseApplicationDocument = await PDFDocument.load(firstDocumentData);

        // Process additional application documents
        const additionalDocuments: DocHubDocument[] = application.applicationDocuments.slice(1);

        if (additionalDocuments?.length > 0) {
          await this.processDocuments(additionalDocuments);
        }

        // Process other document types
        await this.processOptionalDocuments(application.powerOfAttorneyDocuments, "powerOfAttorneyDocuments");
        await this.processOptionalDocuments([application.powerOfAttorneyInvoiceRecipientDocument], "powerOfAttorneyInvoiceRecipientDocument");
        await this.processOptionalDocuments([application.mutationLandownerDocument], "mutationLandownerDocument");
        await this.processOptionalDocuments(application.uploadedMutationLandownerDocuments, "uploadedMutationLandownerDocuments");
        await this.processOptionalDocuments(application.landRegisterDocuments, "landRegisterDocuments");

        await this.finalizePDF(application);
      } catch (error) {
      }
    }
  }

  async processDocuments(documents: DocHubDocument[]) {
    for (const document of documents) {
      if (!!document) {
        const documentData: ArrayBuffer = await this.fetchDocumentData(document.documentId);
        const pdfEntity: PdfEntity = { arrayBuffer: documentData, type: document.type };
        await this.addFile(pdfEntity);
      }
    }
  }

  async processOptionalDocuments(documents: DocHubDocument[], attributeName: string) {
    if (!!this.application?.[attributeName] && documents?.length > 0) {
      await this.processDocuments(documents);
    }
  }

  async fetchDocumentData(documentId: string): Promise<ArrayBuffer> {
    return this.docHubDocumentService.getDocumentContentForPDF(documentId, this.application._id)
      .pipe(take(1))
      .toPromise();
  }

  async finalizePDF(application: Application) {
    let titleKey: string = "PDF.TITLE";
    let id: string = "";

    if (application?.type === TypeEnum.Application) {
      id = this.applicationService.getDisplayId(application);
    } else if (application?.type === TypeEnum.Mutation) {
      titleKey = titleKey.concat("_MUTATION");
      id = String(application?.connectionObjectNumber);
    }

    const filename: string = `${moment().format(this.filenameDateFormat)}_${this.bfcTranslationService.translate(titleKey, { id: id })}.pdf`;
    this.baseApplicationDocument.setTitle(filename);

    try {
      const base64Document: string = await this.baseApplicationDocument.saveAsBase64();
      this.base64MergedDocumentsPdf$.next(base64Document);
    } catch (error) {
    } finally {
      this.base64MergedDocumentsPdf$.complete();
    }
  }

  private getBase64AsObservable(pdf: pdfMake.TCreatedPdf): Observable<string> {
    return new Observable((subscriber) => {
      pdf.getBase64((encodedString: string | undefined) => {
        if (encodedString) {
          subscriber.next(encodedString);
          subscriber.complete();
        } else {
          subscriber.error(new Error("Base64 string is undefined."));
        }
      });
    });
  }

  private async addPdf(data: ArrayBuffer): Promise<void> {
    const document: PDFDocument = await PDFDocument.load(data);
    const copiedPages: PDFPage[] = await this.baseApplicationDocument.copyPages(document, document.getPageIndices());
    copiedPages.forEach((page: PDFPage) => {
      this.baseApplicationDocument.addPage(page);
    });
  }

  private embedAndAddImage(data: ArrayBuffer, type: string): void {
    if (type === MimeTypeEnum.PNG) {
      this.baseApplicationDocument.embedPng(data).then((image: PDFImage) => {
        this.addImage(image);
      });
    } else if (type === MimeTypeEnum.JPG || type === MimeTypeEnum.JPEG) {
      this.baseApplicationDocument.embedJpg(data).then((image: PDFImage) => {
        this.addImage(image);
      });
    }
  }

  private addImage(image: PDFImage): void {
    const imageDims = image.scale(1);
    const page: PDFPage = this.baseApplicationDocument.addPage();

    if (imageDims.width > page.getWidth() || imageDims.height > page.getHeight()) {
      page.drawImage(image, {
        width: page.getWidth(),
        height: page.getHeight(),
      });
    } else {
      page.drawImage(image, {
        x: page.getWidth() / 2 - image.width / 2,
        y: page.getHeight() / 2 - image.height / 2,
        width: image.width,
        height: image.height,
      });
    }
  }

  private async addFile(file: PdfEntity): Promise<void> {
    if (file.type === MimeTypeEnum.PDF) {
      await this.addPdf(file.arrayBuffer);
    } else {
      this.embedAndAddImage(file.arrayBuffer, file.type);
    }
  }

  private generatePowerOfAttorney(): Array<any> {
    return [
      { text: this.bfcTranslationService.translate("PDF.POWER_OF_ATTORNEY.TITLE"), style: "header", margin: [0, 24] },
      { text: this.bfcTranslationService.translate("PDF.POWER_OF_ATTORNEY.SUB_1"), style: "subHeader" },
      { text: this.bfcTranslationService.translate("PDF.POWER_OF_ATTORNEY.TEXT_CONNECTION_OBJECT") },
      this.getConnectionObjectDetails(),
      { text: this.bfcTranslationService.translate("PDF.POWER_OF_ATTORNEY.TEXT_CONTACT_PERSON") },
      this.getContactPersonDetails(),
      { text: this.bfcTranslationService.translate("PDF.POWER_OF_ATTORNEY.TEXT_LANDOWNERS"), pageBreak: "after" },
      {
        table: {
          headerRows: 1,
          widths: ["35%", "25%", "30%"],
          body: [
            [
              { text: this.bfcTranslationService.translate("PDF.POWER_OF_ATTORNEY.TABLE.NAME"), style: "subHeader" },
              {
                text: this.bfcTranslationService.translate("PDF.POWER_OF_ATTORNEY.TABLE.PLACE_DATE"),
                style: "subHeader",
              },
              { text: this.bfcTranslationService.translate("PDF.POWER_OF_ATTORNEY.TABLE.SIGNATURE"), style: "subHeader" },
            ],
            ...this.getLandowners(),
          ],
        },
        margin: [0, 12, 0, 12],
        // make a page brake if table is a bit bigger
        pageBreak: this.landowners?.length > 5 ? "after" : "",
      },
      { text: this.bfcTranslationService.translate("PDF.POWER_OF_ATTORNEY.SUB_2"), style: "subHeader" },
      { text: this.bfcTranslationService.translate("PDF.POWER_OF_ATTORNEY.TEXT_DECLARATION") },
      {
        table: {
          headerRows: 1,
          widths: ["30%", "40%"],
          body: [
            [
              { text: "" },
              { text: "" },
            ],
            [
              { text: this.bfcTranslationService.translate("PDF.POWER_OF_ATTORNEY.PLACE_DATE") },
              { text: this.bfcTranslationService.translate("PDF.POWER_OF_ATTORNEY.SIGNATURE_CONTACT_PERSON") },
            ],
          ],
        },
        margin: [0, 36, 0, 12],
        layout: "lightHorizontalLines",
      },
      {
        table: {
          headerRows: 1,
          widths: ["100%"],
          body: [
            [
              { text: this.bfcTranslationService.translate("PDF.POWER_OF_ATTORNEY.NOTE") },
            ],
          ],
        },
        margin: [0, 48, 0, 12],
      },
    ];
  }

  private generateApplication(): Array<any> {
    return [
      { text: this.bfcTranslationService.translate("PDF.APPLICATION.TITLE"), style: "header", margin: [0, 24] },
      { text: this.bfcTranslationService.translate("PDF.APPLICATION.SUB_1"), style: "subHeader" },
      { text: this.bfcTranslationService.translate("PDF.APPLICATION.TEXT_CONNECTION_OBJECT") },
      { text: this.bfcTranslationService.translate("PDF.APPLICATION.TITLE_CONNECTION_OBJECT"), style: "subHeader" },
      this.getConnectionObjectDetails(),
      { text: this.bfcTranslationService.translate("PDF.APPLICATION.TITLE_CONTACT_PERSON"), style: "subHeader" },
      { text: this.bfcTranslationService.translate("PDF.APPLICATION.TEXT_CONTACT_PERSON") },
      this.getContactPersonDetails(),
      { text: this.bfcTranslationService.translate("PDF.APPLICATION.SUB_2"), style: "subHeader", margin: [0, 12] },
      ...this.getPlacesOfConsumption(),
      ...this.getInvoiceRecipientSection(),
      ...this.getInstallerDetails(),
      ...this.getApplicationBasics(),
      ...this.getCommentAndNote(),
    ];
  }

  private generatePowerOfAttorneyInvoiceRecipient(): Array<any> {
    const connectionObjectDetails: ConnectionObjectDetails = this.application?.connectionObjectDetails;

    return [
      {
        text: this.bfcTranslationService.translate("PDF.POWER_OF_ATTORNEY_INVOICE_RECIPIENT.TITLE"),
        style: "header",
        margin: [0, 24],
      },
      { text: this.bfcTranslationService.translate("PDF.POWER_OF_ATTORNEY_INVOICE_RECIPIENT.INTRO") },
      {
        text: this.bfcTranslationService.translate("PDF.POWER_OF_ATTORNEY_INVOICE_RECIPIENT.INVOICE_RECIPIENT"),
        margin: [0, 24, 0, 0],
      },
      {
        text: this.bfcTranslationService.translate("PDF.POWER_OF_ATTORNEY_INVOICE_RECIPIENT.TABLE_HEADER"),
        margin: [12, 24, 0, 0],
      },
      {
        table: {
          headerRows: 1,
          widths: ["25%", "65%"],
          body: [
            [
              this.bfcTranslationService.translate("PDF.POWER_OF_ATTORNEY_INVOICE_RECIPIENT.ADDRESS"),
              `${connectionObjectDetails?.street}, ${connectionObjectDetails?.houseNumber}, ${connectionObjectDetails?.postalCode}, ${connectionObjectDetails?.city}`,
            ],
          ],
        },
        margin: [12, 12, 0, 0],
        layout: "noBorders",
      },
      {
        text: this.bfcTranslationService.translate("PDF.POWER_OF_ATTORNEY_INVOICE_RECIPIENT.WILL"),
        margin: [0, 24, 0, 0],
      },
      ...this.getInvoiceRecipientDetails(),
      { text: this.bfcTranslationService.translate("PDF.POWER_OF_ATTORNEY_INVOICE_RECIPIENT.INFO") },
      {
        table: {
          headerRows: 1,
          widths: ["100%"],
          body: [
            [
              { text: this.bfcTranslationService.translate("PDF.NOTE_MUTATION") },
            ],
          ],
        },
        margin: [0, 48, 0, 0],
      },
    ];
  }

  private generatePowerOfAttorneyInvoiceRecipientAddress(): Array<any> {
    const connectionObjectDetails: ConnectionObjectDetails = this.application?.connectionObjectDetails;

    return [
      {
        text: this.bfcTranslationService.translate("PDF.POWER_OF_ATTORNEY_INVOICE_RECIPIENT_ADDRESS.TITLE"),
        style: "header",
        margin: [0, 24],
      },
      {
        text: this.bfcTranslationService.translate("PDF.POWER_OF_ATTORNEY_INVOICE_RECIPIENT_ADDRESS.INTRO",
          { address: `${connectionObjectDetails?.street}, ${connectionObjectDetails?.houseNumber}, ${connectionObjectDetails?.postalCode}, ${connectionObjectDetails?.city}` }),
      },

      {
        text: this.bfcTranslationService.translate("PDF.POWER_OF_ATTORNEY_INVOICE_RECIPIENT_ADDRESS.INVOICE_RECIPIENT"),
        margin: [0, 24, 0, 0],
      },
      ...this.getInvoiceRecipientDetails(),
      { text: this.bfcTranslationService.translate("PDF.POWER_OF_ATTORNEY_INVOICE_RECIPIENT_ADDRESS.INFO") },
      {
        table: {
          headerRows: 1,
          widths: ["100%"],
          body: [
            [
              { text: this.bfcTranslationService.translate("PDF.NOTE_MUTATION") },
            ],
          ],
        },
        margin: [0, 48, 0, 0],
      },
    ];
  }

  private generateCancellation(): Array<any> {
    return [
      { text: this.bfcTranslationService.translate("PDF.CANCELLATION.TITLE"), style: "header", margin: [0, 24] },
      {
        text: this.bfcTranslationService.translate("PDF.CANCELLATION.INTRO",
          { cancellationDate: moment(this.application?.cancellationDate)?.format(this.dateFormat) }),
      },
      { text: this.bfcTranslationService.translate("PDF.CANCELLATION.TABLE_HEADER"), margin: [0, 12, 0, 12] },
      { text: this.bfcTranslationService.translate("PDF.CANCELLATION.ADDRESS") },
      this.getConnectionObjectDetails(),
      { text: this.bfcTranslationService.translate("PDF.CANCELLATION.INFO") },
      {
        table: {
          headerRows: 1,
          widths: ["35%", "25%", "30%"],
          body: [
            [
              { text: this.bfcTranslationService.translate("PDF.POWER_OF_ATTORNEY.TABLE.NAME"), style: "subHeader" },
              {
                text: this.bfcTranslationService.translate("PDF.POWER_OF_ATTORNEY.TABLE.PLACE_DATE"),
                style: "subHeader",
              },
              { text: this.bfcTranslationService.translate("PDF.POWER_OF_ATTORNEY.TABLE.SIGNATURE"), style: "subHeader" },
            ],
            ...this.getLandowners(),
          ],
        },
        margin: [0, 12, 0, 0],
      },
      {
        table: {
          headerRows: 1,
          widths: ["100%"],
          body: [
            [
              { text: this.bfcTranslationService.translate("PDF.NOTE_MUTATION") },
            ],
          ],
        },
        margin: [0, 48, 0, 0],
      },
    ];
  }

  private generateMutationLandownerExtern(): Array<any> {
    const connectionObjectDetails: ConnectionObjectDetails = this.application?.connectionObjectDetails;

    // all Applications that are migrated have an applicationId greater or equal 7000
    this.isMigratedApplication =
      this.application?.applicationId >= this.bfcConfigurationService.configuration.migratedApplicationMinId;

    let placeOfConsumption: PlaceOfConsumption;
    let oldLandowners: ContactDetails[];
    let newLandowners: ContactDetails[];
    let residentialUnit: string;

    if (this.application?.placesOfConsumption?.length === 1) {
      placeOfConsumption = this.application?.placesOfConsumption[0];
      oldLandowners = placeOfConsumption.oldLandowners;
      newLandowners = placeOfConsumption.newLandowners;
      residentialUnit = placeOfConsumption.residentialUnit;
    } else if (this.isMigratedApplication) {
      placeOfConsumption = null;
      oldLandowners = [this.application?.oldLandowner];
      newLandowners = this.application?.newLandowners;
      if (oldLandowners?.length > 0) {
        residentialUnit = oldLandowners[0]?.residentialUnit;
      }
    }

    return [
      {
        text: this.bfcTranslationService.translate("PDF.MUTATION_LANDOWNER_EXTERN.TITLE"),
        style: "header",
        margin: [0, 24],
      },
      {
        text: this.bfcTranslationService.translate("PDF.MUTATION_LANDOWNER_EXTERN.INTRO",
          {
            address: `${connectionObjectDetails?.street}, ${connectionObjectDetails?.houseNumber}, ${connectionObjectDetails?.postalCode}, ${connectionObjectDetails?.city}`,
          }),
      },
      {
        text: this.bfcTranslationService.translate("PDF.MUTATION_LANDOWNER_EXTERN.TEXT_OLD_NEW_LANDOWNER"),
        margin: [0, 24, 0, 0],
      },
      {
        text: this.bfcTranslationService.translate("PDF.MUTATION_LANDOWNER_EXTERN.EXITING_LANDOWNER"),
        decoration: "underline",
        margin: [12, 24, 0, 0],
      },
      ...this.getLandownersContent(oldLandowners, residentialUnit, false),
      {
        text: this.bfcTranslationService.translate("PDF.MUTATION_LANDOWNER_EXTERN.NEW_LANDOWNER"),
        decoration: "underline",
        margin: [12, 24, 0, 0],
      },
      ...this.getLandownersContent(newLandowners, residentialUnit, true),
      { text: this.bfcTranslationService.translate("PDF.MUTATION_LANDOWNER_EXTERN.AGREEMENT_TEXT") },
      {
        table: {
          headerRows: 1,
          widths: ["35%", "25%", "30%"],
          body: [
            [
              { text: this.bfcTranslationService.translate("PDF.POWER_OF_ATTORNEY.TABLE.NAME"), style: "subHeader" },
              {
                text: this.bfcTranslationService.translate("PDF.POWER_OF_ATTORNEY.TABLE.PLACE_DATE"),
                style: "subHeader",
              },
              { text: this.bfcTranslationService.translate("PDF.POWER_OF_ATTORNEY.TABLE.SIGNATURE"), style: "subHeader" },
            ],
            ...this.getLandowners(true),
          ],
        },
        margin: [0, 12, 0, 0],
      },
      {
        table: {
          headerRows: 1,
          widths: ["100%"],
          body: [
            [
              { text: this.bfcTranslationService.translate("PDF.NOTE_MUTATION") },
            ],
          ],
        },
        margin: [0, 48, 0, 0],
      },
    ];
  }

  private generateMutationContactPersonNew(): Array<any> {
    const connectionObjectDetails: ConnectionObjectDetails = this.application?.connectionObjectDetails;

    let newContactPerson: ContactDetails;

    if (!!this.application?.newContactPerson) {
      newContactPerson = this.application.newContactPerson;
    }

    return [
      {
        text: this.bfcTranslationService.translate("PDF.MUTATION_CONTACT_PERSON_NEW.TITLE"),
        style: "header",
        margin: [0, 24],
      },
      {
        text: this.bfcTranslationService.translate("PDF.MUTATION_CONTACT_PERSON_NEW.INTRO",
          { address: `${connectionObjectDetails?.street}, ${connectionObjectDetails?.houseNumber}, ${connectionObjectDetails?.postalCode}, ${connectionObjectDetails?.city}` }),
      },
      {
        text: this.bfcTranslationService.translate("PDF.MUTATION_CONTACT_PERSON_NEW.TEXT_CHANGE_CONTACT_PERSON"),
        margin: [0, 24, 0, 0],
      },
      {
        text: this.bfcTranslationService.translate("PDF.MUTATION_CONTACT_PERSON_NEW.EXITING_CONTACT_PERSON"),
        decoration: "underline",
        margin: [12, 24, 0, 0],
      },
      ...this.getContactPersonDetails(),
      {
        text: this.bfcTranslationService.translate("PDF.MUTATION_CONTACT_PERSON_NEW.NEW_CONTACT_PERSON"),
        decoration: "underline",
        margin: [12, 24, 0, 0],
      },
      {
        table: {
          headerRows: 1,
          widths: ["35%", "55%"],
          body: [
            [
              this.getContactDetail(PdfRow.COMPANY, newContactPerson),
            ],
            [
              this.getContactDetail(PdfRow.NAME, newContactPerson),
            ],
            [
              this.getContactDetail(PdfRow.STREET_NUMBER, newContactPerson),
            ],
            [
              this.getContactDetail(PdfRow.ZIP_CITY, newContactPerson),
            ],
            [
              this.getContactDetail(PdfRow.PHONE, newContactPerson),
            ],
            [
              this.getContactDetail(PdfRow.EMAIL, newContactPerson),
            ],
          ],
        },
        pageBreak: "after",
        margin: [24, 24],
        layout: "noBorders",
      },
      { text: this.bfcTranslationService.translate("PDF.MUTATION_CONTACT_PERSON_NEW.AGREEMENT_TEXT") },
      { text: this.bfcTranslationService.translate("PDF.MUTATION_CONTACT_PERSON_NEW.AGREEMENT_LABEL"), margin: [0, 24] },
      {
        table: {
          headerRows: 1,
          widths: ["40%"],
          body: [
            [
              { text: "" },
            ],
            [
              { text: "" },
            ],
          ],
        },
        margin: [0, 12, 0, 0],
        layout: "lightHorizontalLines",
      },
      {
        table: {
          headerRows: 1,
          widths: ["100%"],
          body: [
            [
              { text: this.bfcTranslationService.translate("PDF.NOTE_MUTATION") },
            ],
          ],
        },
        margin: [0, 48, 0, 0],
      },
    ];
  }

  private generateMutationContactPersonAddress(): Array<any> {
    const connectionObjectDetails: ConnectionObjectDetails = this.application?.connectionObjectDetails;

    return [
      {
        text: this.bfcTranslationService.translate("PDF.MUTATION_CONTACT_PERSON_ADDRESS.TITLE"),
        style: "header",
        margin: [0, 24],
      },
      {
        text: this.bfcTranslationService.translate("PDF.MUTATION_CONTACT_PERSON_ADDRESS.INTRO",
          { address: `${connectionObjectDetails?.street}, ${connectionObjectDetails?.houseNumber}, ${connectionObjectDetails?.postalCode}, ${connectionObjectDetails?.city}` }),
      },

      {
        text: this.bfcTranslationService.translate("PDF.MUTATION_CONTACT_PERSON_ADDRESS.ADDRESS_CHANGE"),
        margin: [0, 24, 0, 0],
      },
      ...this.getContactPersonDetails(),
      {
        table: {
          headerRows: 1,
          widths: ["100%"],
          body: [
            [
              { text: this.bfcTranslationService.translate("PDF.NOTE_MUTATION") },
            ],
          ],
        },
        margin: [0, 48, 0, 0],
      },
    ];
  }

  private generateMutationMigrationActivation(
    applications: Application[],
    missingApplicationsAndComment: MissingApplicationsAndComment,
  ): Array<any> {
    return [
      {
        text: this.bfcTranslationService.translate("PDF.MUTATION_MIGRATION_ACTIVATION.TITLE"),
        style: "header",
        margin: [0, 24],
      },
      { text: this.bfcTranslationService.translate("PDF.MUTATION_MIGRATION_ACTIVATION.INTRO") },
      {
        table: {
          headerRows: 1,
          widths: ["15%", "20%", "30%", "15%", "20%"],
          body: [
            [
              { text: this.bfcTranslationService.translate("APPLICATION_OVERVIEW.COLUMN_TITLES.ID"), style: "subHeader" },
              {
                text: this.bfcTranslationService.translate("APPLICATION_OVERVIEW.COLUMN_TITLES.CONNECTION_OBJECT_NUMBER"),
                style: "subHeader",
              },
              {
                text: this.bfcTranslationService.translate("APPLICATION_OVERVIEW.COLUMN_TITLES.ADDRESS"),
                style: "subHeader",
              },
              {
                text: this.bfcTranslationService.translate("APPLICATION_OVERVIEW.COLUMN_TITLES.LAST_UPDATE"),
                style: "subHeader",
              },
              {
                text: this.bfcTranslationService.translate("PDF.MUTATION_MIGRATION_ACTIVATION.TABLE.ACCEPTED"),
                style: "subHeader",
              },
            ],
            ...this.getMigratedApplications(applications),
          ],
        },
        margin: [0, 12, 0, 0],
      },
      {
        table: {
          headerRows: 1,
          widths: ["100%"],
          body: [
            [
              { text: this.bfcTranslationService.translate("PDF.MUTATION_MIGRATION_ACTIVATION.NOTE") },
            ],
          ],
        },
        margin: [0, 48, 0, 0],
      },
      ...this.getMissingApplicationsSection(missingApplicationsAndComment),
    ];
  }

  private generatePDF(content: any[], filename: string): any {
    const docDefinition = {
      pageSize: "A4",
      pageMargins: [40, 140, 40, 60],
      info: {
        title: filename,
      },
      header: {
        columns: [
          {
            text: this.getTitle(),
            margin: [40, 40, 0, 0],
          },
          {
            image: "data:image/png;" +
              "base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAw1BMVEWFz+gSEhL/ZBgALWkAAACJ1e2K1/GC0et71" +
              "fGjvMn/XAD/YxL4bTUJAAAAKWeH0usPDAtzs8ghLTEAG2AAIGIAJGRJb3w8WmQVFxhBdJwnT38tWolalbg3aJMAImMYRHkQNm4AFl51u9d" +
              "/x+FZiJhBYm0YQHZ7v9ZglKVwrsNmnbBpqslem7x4utA0TVYZHiBQh6wAElwxSFAdJSgoOT9Sfo0/X2pKf6UnU4MAAFckMzhPeIZHbHktQ" +
              "UfxdklemrwAAFiOOD+iAAALpElEQVR4nO2dC1fiyBLH44bO7t7ddGdMMiPIKwTxASrozMDqeL3f/1Pdqn7nMaPLuAJ76nc8R+g0of/p6qr" +
              "qCgeCgCAIgiAIgiAIgiAIgiAI4uf49TVUXyJaaJy39VDtBT/u8sK5Xs3vr6Ei8fxDg+tzFlXfXlzbg66RucbLc/9EpevjTmrPJ2zHy7+v7" +
              "9ff//MKfvnNe0n0rdPG05fryNMY3dgjbqSXrntSBuLCPpuKli7uXHe2zbsUr1f4y4v88WdF4cf4qIUk7jxdRq7XJ90rOXG6k8T07lwLmOa" +
              "OfhZ/NC8U09jvos9l2pLP7g3eXyEOoPPIfqCQ3bnRX+FQhRGcPJmRRzeuz4V5Ydm8EjtSCKP6ZofaUBh96dhuaqTRU1K/CuLWTnNszgWW2" +
              "xC9M4UwOUZPXaEb51Gsp4w9mjN1znWnc9vpKDk2CqdW4fV7K0wUnsLk9nsKgxNrkyel1nNlh35Vb8FG84bf7IX4+0b6kwpvFQm4xvok1hX" +
              "a59DFxAE3Y/FULWDm20fnUp/KGvPdeyuMTSgOrh5sm3YGNYXR1C3CCztOYeY1PlMKo8+eQcTT2uzHzpG9l0IbnVhwX7vOVYUuMBx1zryQY" +
              "jynDQP+Eo9vZKObabvId6AwYGaOknvWotBegKP4szcP4otdYSqL++AtQ7OmvdV6vkOF1lMmScs6dIswiUs/37yqekk29RVqRcwkAdaL7Ua" +
              "hGavx8b7C6MLZ6GUly64ZYPQ11pfJbzyLK1a7I4XWo8efGgqZtwin1UFGJtTEX+R0KWOOp8rhKK8VHVf6vK9Cu62JGmmHU3gbuUX4tTYLt" +
              "pe6MDo961yqt1D+R9hTX76/wku9p7k6M6NI4vrYj05sqpk81IdoXY00bmPqneDCPPDd8BYbi59VeGQ3Na5lWs9pYPRWfcMXulQOLV6oLC6" +
              "5j7QqzA3cCr/dIhq+feb9qZF5ewcvm0O02wYUE92p5fctErG+XsxNc/xpG0fzxgrjr3aSmgrjx5YRMuNG5PpVfgceRjdKK6xbt1SnezCH8" +
              "ZUV0VSYHLUEbBMfwG8ys+IgNuqJS47BSu012MrRvL2V2n1500qT26AxRhvOIdgJ5V+SE8/nQH7gr9R3VxhrXLrcMVG5xdMcxQ+N9/dcTaR" +
              "DuwzsJm5cRR9qqcS7Kky+nkm+PsVeTK/FQ9hjOYktWYmLBdGDWnwY2HUMhbVr8qH4bAcK4yDSiAsXL4KqQrBNJ7HTGGbklllg4r2wqRrsV" +
              "EwdYJsKxs8r9LK2i1pS4+el186MO3WHarPOzsWlC/OBXpNguzfmCnzYrUJnbbqA5CtkrkTj738DXwoY5FT7T5mq6eXXOTcG0Am2480URjZ" +
              "zuWso9GbYFBKdQutJ7j6ZeC8PqJfHH83O+mm7ZfiGCmtV2+oOOProSfxQidylfWFSsXKV3xzVqyM7U+i8ftscViq9tfS0Upo5shVD8ViNp" +
              "9s6mjfbPZXHdofUXIfyZcdO4q2/z3e1Qt1dXzK/ruiEv7dCu3v66JUTm75Ujrj0Iv+xdzpxUZFiQ+Z5VWGypcA33z3ZXVyz5u1VmeIbN95" +
              "q9clt5KNb33q3KpW+gcIWOu31Umzx7K7jbfZFRaHNr9mZ/zZblUr/EYXxvenVvPfk3ZrxI78taasDVnil7rZVqfSfUBifGD/Zdv8wOmuL/" +
              "JXzJQ/2TmLFercqlb69wrhzUzZ2T/4dUuHuIMKsaLureE17Sw3wpna7UqlR+MfL1BUmTeK407mp3gM2d6b8wd2615pEEza+7jRe2IueXN/" +
              "tSqVa4S9/vsx/fYXi8f64wcPN41VZuY9/Znr5m0Jx7r3m/ly3Pri2W2eN4ot7n/vtKhhK4m+vofISEbXBanbkjnz3tc2ukX+R/L7bCyQIg" +
              "iAIgiAIgiAIgiAIgiAIgiAIgiAIgiAIgiAIgiAIgiAIgiAIgiAIgiAIgiAIgiAIgiAIgiAIgiC+hwiCUhIw87VvgjHVoL5b1X5RnHDfGcf" +
              "0t8Sx6nfJMX2uUtS/oG53sM2YZ2lRFMNxbxnor4vtjvK0SHlvXYpATGaj0WyOP2nUh0ej2QQesjU86sEDbFFgF9YL5cnSfHa62JevyWMzH" +
              "mp4xvH7Y8W8yFUbH+YoJ+M8W8J42WDIOU+fUWEv58NTaEu5IV2AwrE5GefFZk9mkY1gUHmaphn8Hw6Y1owtOfwDFdiD6wP8lOdSLBwb4oM" +
              "M1OQZ8pdUGOqTwf+0vx8Scfz5YDKZzHt4/XEKUxh9dz6ZD0DGaiJENw/5DG0uD/NuzkG0mECfFC0XpOTdBfI8CaTCfA0n68O5+GY/7FQq7" +
              "DIhGCorwL2sUVAkoAUUZgshFiBjWEpZfJnzmVBXIUWTzrALMz+CigqzPp5sCQfGezSHXfQjcl4mgg2kZDx0ytEUxaSQB1DpeJ6FKfqcIcw" +
              "39pEKrRKlUGhDCHenyscqDMpCWh7b8FA6loB1c6WVSxlsOeQzuAzyKsCRgVNofse2qpBv96Mib01NIUwVNAylk0CFuOpQc75mMLnwbCXF9" +
              "vAqYB804P4EQTlOIVr2eKfCLFUrLSBUz4xCsdYK8f+AiRnPu5EUi7MqHY30pUNwnukKz6EURpA6gIHz3h55mjUOCl0nV8FCz6FWKBagfcy" +
              "CAke/4WCecmVKG8xMMB2utcJwtNn0QjhXsUfRIszzfLjC8Adry1c4DF1sCHAJzsFy+TgCf8NHcoZQYQYZUfE/M4cY7THurAb7IVAp1GlIl" +
              "uNPg83q6zBgaJQTWFpFyZ6zMBcQC5SjkZ7mWee1WmGeoaOdTfbDRrVCPsyG+Wwj81L0IsOlUSiFYNhI50t4xtBJpiUcyJQNqnhoT4YRvzv" +
              "vZ9Jn7UxTFedL9XZAR8HARAsUvRyCIrBPyFJKCIjzDdeOpjUeMrxIcvL3Ap3TeA021tmYgBOXryElhX5iDJJmsMxU79aIr1KefZlEN4cag" +
              "TbGS8aYTT6DEvK1QY+jZTLMvcc6UTVWKhEuHqK3gmCxH7PYVIihgIeDwSm6oEy14a5iJOWCg+WD0E47Xo1RDxnNhVUo40s+7u7FLDYUgpl" +
              "iCOByj1jIBSktN1QhUGYr6EDVSzIVHYBiIbycRu7ABnsxiWycZqvaxT5dZXnO89weEMsVbgFzmfr8JXeDepXJdskKFYZwMrlyJ6ucZ/uhU" +
              "Cz6/f68qpBN+t3B6aD7XJohln1EzZt82NcH+g7QLJ7Vf5TYPR0s9sJKA7sxqLah6xC1XqL+MLA/h6Sb/F5sf4pRBEEQxFsArl3+qaK+dPT" +
              "2aeDFAHdoZ0PdClbO++t1d7mYT+RGYtFfroHlYqJ1TeZzd2i57q6X8/KAgp0oT9MUNsD5MEvHuN3dFPgMcrasGM/lLmqwSldyJzyCjrJnl" +
              "m7Kg5EoZkNdXOF5T2/oTa2FrzCfsztG1nOH8j2p976M6GN1OgthCzQKT43CMWyHQlAuK4I1hdATS2mqanwAYLk3zOdqE2tq+Xk3gidd0JH" +
              "W51AdwnLo+EAUCqxar/0qxqkua+gbGQ2FLPDuzBwApS1VaKxCdQh8aIvCcp8KMT/G3HFyLfU5bFOIFbcDU+jt+rRCfUMRTbFFIVYv0vkBK" +
              "cTbn+YGkvQig8lkvhiHqqT2HYXZYSlkeP8oxeqLjBZc3YvnWHz5tyjMh1yvulP74YxwJEX8GxTixxG6I+4Ujmf4uRFe2lrioa9DLJNFEOC" +
              "NQgjr0cyqafOlhxMtXDxkFYVMZnMrGdRb4mEjxuwxMqdZwl6opjAQmF4PolZPw7Dwne566K9E3T98npTlgFcUMrxDky0bWdugLMv5eH9u0" +
              "7+I3lvgB/XCikL5IZMwG9dymjAvCtl3dRiOBhC9zIaHikLWL1TIr+2e1C3xYl9u07+G5SxVZCHa5CbNiq66y12khZzDNEuVwsL0HO3JBy1" +
              "eB2wLVcomVHCwv6zMSv1BIPOjyqIsVXYXHFCZRtF2e0a1t/Y8NHkEQRAEQRAEQRAEQRAEQRCO/wOBImFInu5XDgAAAABJRU5ErkJggg==",
            fit: [80, 80],
            margin: [40, 40, 0, 0],
            alignment: "right",
          },
        ],
      },
      content: content,
      styles: {
        header: {
          fontSize: 16,
          bold: true,
          margin: [0, 24],
        },
        headerNotes: {
          fontSize: 12,
          bold: true,
          margin: [0, 0, 0, 12],
        },
        subHeader: {
          fontSize: 12,
          bold: true,
          margin: [12, 12, 0, 12],
        },
      },
      defaultStyle: {
        font: "KlintLTPro",
        fontSize: 11,
        lineHeight: 1.2,
      },
      footer: (currentPage, pageCount) => {
        return {
          margin: 30,
          columns: [
            {
              fontSize: 10,
              text: [
                {
                  text: "",
                },
                {
                  text: `${currentPage.toString()}/${pageCount}`,
                },
              ],
              alignment: "right",
            },
          ],
        };
      },
    };

    return pdfMake.createPdf(docDefinition);
  }

  private getLandowners(isMutationLandowner?: boolean): Array<String> {
    const landownersContent = [];

    this.landowners = [];

    if (isMutationLandowner) {
      if (this.application?.newLandowners?.length > 0) {
        this.application.newLandowners.forEach((landowner: ContactDetails) => {
          this.addLandowner(landowner);
        });
      } else if (this.application?.placesOfConsumption?.length > 0 &&
        this?.application?.placesOfConsumption[0]?.newLandowners?.length > 0) {
        this.application.placesOfConsumption[0].newLandowners.forEach((landowner: ContactDetails) => {
          this.addLandowner(landowner);
        });
      }
    } else {
      this.application?.placesOfConsumption?.forEach((placeOfConsumption: PlaceOfConsumption) => {
        placeOfConsumption.landowners.forEach((landowner: ContactDetails) => {
          this.addLandowner(landowner);
        });
      });
    }

    this.landowners.forEach((landowner: ContactDetails) => {
      landownersContent.push(
        [
          { text: `${landowner?.lastName} ${landowner?.firstName}` },
          {},
          {},
        ],
      );
    });

    return landownersContent;
  }

  private addLandowner(landowner: ContactDetails) {
    if (!this.isExistingLandowner(landowner)) {
      this.landowners.push(landowner);
    }
  }

  private isExistingLandowner(newLandowner: ContactDetails): boolean {
    return this.landowners?.some((landowner: ContactDetails) => (
      landowner?.firstName.trim() === newLandowner?.firstName.trim() &&
      landowner?.lastName.trim() === newLandowner?.lastName.trim()
    ));
  }

  private getConnectionObjectDetails(): any {
    return {
      table: {
        body: [
          [
            { text: `${this.application?.connectionObjectDetails?.street} ${this.application.connectionObjectDetails?.houseNumber}` },
          ],
          [
            { text: `${this.application?.connectionObjectDetails?.postalCode} ${this.application.connectionObjectDetails?.city}` },
          ],
        ],
      },
      margin: [24, 24],
      layout: "noBorders",
    };
  }

  private getContactPersonDetails(): any {
    return [
      {
        table: {
          body: [
            [
              this.getContactDetail(PdfRow.COMPANY, this.contactPerson),
            ],
            [
              this.getContactDetail(PdfRow.NAME, this.contactPerson),
            ],
            [
              this.getContactDetail(PdfRow.STREET_NUMBER, this.contactPerson),
            ],
            [
              this.getContactDetail(PdfRow.ZIP_CITY, this.contactPerson),
            ],
            [
              this.getContactDetail(PdfRow.PHONE, this.contactPerson),
            ],
            [
              this.getContactDetail(PdfRow.EMAIL, this.contactPerson),
            ],
          ],
        },
        margin: [24, 24],
        layout: "noBorders",
      },
    ];
  }

  private getInvoiceRecipientSection(): any {
    let invoiceRecipientContent = [];

    if (!!this.invoiceRecipient) {
      invoiceRecipientContent = [
        { text: this.bfcTranslationService.translate("PDF.APPLICATION.SUB_3"), style: "subHeader" },
        { text: this.bfcTranslationService.translate("PDF.APPLICATION.TEXT_INVOICE_RECIPIENT") },
        ...this.getInvoiceRecipientDetails(),
      ];
    }
    return invoiceRecipientContent;
  }

  private getInvoiceRecipientDetails(): any {
    return [
      {
        table: {
          body: [
            [
              this.getContactDetail(PdfRow.COMPANY, this.invoiceRecipient),
            ],
            [
              this.getContactDetail(PdfRow.NAME, this.invoiceRecipient),
            ],
            [
              this.getContactDetail(PdfRow.STREET_NUMBER, this.invoiceRecipient),
            ],
            [
              this.getContactDetail(PdfRow.ZIP_CITY, this.invoiceRecipient),
            ],
            [
              this.getContactDetail(PdfRow.PHONE, this.invoiceRecipient),
            ],
            [
              this.getContactDetail(PdfRow.EMAIL, this.invoiceRecipient),
            ],
          ],
        },
        margin: [24, 24],
        layout: "noBorders",
      },
    ];
  }

  private getInstallerDetails(): any {
    if (this.application.contactDetails && this.application.contactDetails.length > 0) {
      this.installerContact = this.application.contactDetails.find((contact: ContactDetails) =>
        contact.contactType === ContactDetails.ContactTypeEnum.Installer);
    } else {
      this.installerContact = null;
    }

    this.installer = this.application.installer;

    return [
      // the subheader number depends on the invoiceRecipient
      {
        text: this.bfcTranslationService.translate("PDF.APPLICATION.SUB_4",
          { number: !!this.invoiceRecipient ? String(4) : String(3) }), style: "subHeader",
      },
      { text: this.bfcTranslationService.translate("PDF.APPLICATION.TEXT_INSTALLER") },
      {
        table: {
          headerRows: 1,
          widths: ["25%", "45%"],
          body: [
            [
              this.bfcTranslationService.translate(`PDF.TABLE.${PdfRow.COMPANY}`),
              this.getInstallerDetail(PdfRow.COMPANY),
            ],
            [
              this.bfcTranslationService.translate(`PDF.TABLE.${PdfRow.NAME}`),
              this.getInstallerDetail(PdfRow.NAME),
            ],
            [
              this.bfcTranslationService.translate(`PDF.TABLE.${PdfRow.PHONE}`),
              this.getInstallerDetail(PdfRow.PHONE),
            ],
            [
              this.bfcTranslationService.translate(`PDF.TABLE.${PdfRow.EMAIL}`),
              this.getInstallerDetail(PdfRow.EMAIL),
            ],
          ],
        },
        margin: [24, 24],
        layout: "noBorders",
      },
    ];
  }

  private getPlacesOfConsumption(): Array<any> {
    const placesOfConsumptionContent = [];

    // all migrated Applications have an applicationId greater or equal 7000, they don't have placesOfConsumption
    if (this.application?.applicationId >= this.bfcConfigurationService.configuration.migratedApplicationMinId) {
      placesOfConsumptionContent.push(
        this.getLandownersFromPlaceOfConsumption(null, this.application?.newLandowners),
      );
    } else {
      this.application?.placesOfConsumption?.forEach((placeOfConsumption: PlaceOfConsumption, index: number) => {
        if (placeOfConsumption.type !== null) {
          placesOfConsumptionContent.push(
            [
              {
                text: `${this.bfcTranslationService.translate("LANDOWNERS.PLACE_OF_CONSUMPTION")} ${index + 1}:`,
                decoration: "underline",
                margin: [0, 12],
              },
              {
                table: {
                  headerRows: 1,
                  widths: ["35%", "45%"],
                  body: [
                    [
                      `${this.bfcTranslationService.translate("LANDOWNERS.PLACE_OF_CONSUMPTION_FORM.RESIDENTIAL_UNIT_LABEL")}:`,
                      placeOfConsumption.residentialUnit,
                    ],
                    [
                      `${this.bfcTranslationService.translate("LANDOWNERS.PLACE_OF_CONSUMPTION_FORM.TYPE")}:`,
                      this.bfcTranslationService.translate(`LANDOWNERS.PLACE_OF_CONSUMPTION_TYPES.${placeOfConsumption.type.toUpperCase()}`),
                    ],
                    [
                      `${this.bfcTranslationService.translate("LANDOWNERS.PLACE_OF_CONSUMPTION_FORM.METER_NUMBER")}:`,
                      { text: placeOfConsumption.meterNumber },
                    ],
                    [
                      `${this.bfcTranslationService.translate("LANDOWNERS.PLACE_OF_CONSUMPTION_FORM.SUPPLY_POINT")}:`,
                      { text: placeOfConsumption.supplyPoint },
                    ],
                  ],
                },
                layout: "noBorders",
              },
              ...this.getLandownersFromPlaceOfConsumption(placeOfConsumption, null),
            ],
          );
        }
      });
    }

    return placesOfConsumptionContent;
  }

  private getLandownersFromPlaceOfConsumption(placeOfConsumption: PlaceOfConsumption,
    newLandowners: ContactDetails[]): Array<any> {
    const landownersContent = [];

    const landowners: ContactDetails[] = newLandowners ? newLandowners : placeOfConsumption?.landowners;

    landowners?.forEach((landowner: ContactDetails, index: number) => {
      landownersContent.push(
        [
          { text: `${this.bfcTranslationService.translate("LANDOWNERS.LANDOWNER")} ${index + 1}:`, margin: [0, 12] },
          [
            { text: `${landowner?.lastName} ${landowner?.firstName}` },
          ],
          [
            { text: `${landowner?.street} ${landowner?.houseNumber}` },
          ],
          [
            { text: `${landowner?.postalCode} ${landowner?.city}` },
          ],
        ],
      );
    });

    return landownersContent;
  }

  private getApplicationBasics(): any {
    return [
      // the subheader number depends on the invoiceRecipient
      {
        text: this.bfcTranslationService.translate("PDF.APPLICATION.SUB_5",
          { number: !!this.invoiceRecipient ? String(5) : String(4) }), style: "subHeader",
      },
      { text: this.bfcTranslationService.translate("PDF.APPLICATION.BASICS") },
      { text: this.bfcTranslationService.translate("PDF.APPLICATION.LINK_TITLE") },
      {
        text: this.bfcTranslationService.translate("PDF.APPLICATION.LINK_TEXT"),
        link: this.bfcTranslationService.translate("APPLICATION_BASICS.LINK"), decoration: "underline",
      },
    ];
  }

  private getCommentAndNote(): any {
    return [
      // the subheader number depends on the invoiceRecipient
      {
        text: this.bfcTranslationService.translate("PDF.APPLICATION.SUB_6",
          { number: !!this.invoiceRecipient ? String(6) : String(5) }), style: "subHeader",
      },
      { text: this.application?.comments ?? "----" },
      { text: this.bfcTranslationService.translate("PDF.APPLICATION.SUBMISSION_TITLE"), style: "subHeader" },
      { text: this.bfcTranslationService.translate("PDF.APPLICATION.SUBMISSION_TEXT") },
    ];
  }

  private getMeterNumber(): any {
    let meterNumberRow = [];

    if (!this.isMigratedApplication) {
      meterNumberRow.push(
        [
          this.bfcTranslationService.translate("PDF.TABLE_LANDOWNER.METER_NUMBER"),
          { text: this.application?.placesOfConsumption[0]?.meterNumber ?? "-", margin: [0, 6, 0, 0] },
        ],
      );
    }
    return meterNumberRow;
  }

  private getContactDetail(pdfRow: PdfRow, contact: ContactDetails): string {
    let attributeValue = "";

    switch (pdfRow) {
      case PdfRow.COMPANY:
        if (contact) {
          attributeValue = contact.company;
        } else {
          attributeValue = this.contactPerson.company;
        }
        break;
      case PdfRow.NAME:
        if (contact) {
          attributeValue = `${contact.firstName} ${contact.lastName}`;
        } else {
          attributeValue = `${this.contactPerson.firstName} ${this.contactPerson.lastName}`;
        }
        break;
      case PdfRow.STREET_NUMBER:
        if (contact) {
          attributeValue = `${contact.street} ${contact.houseNumber}`;
        } else {
          attributeValue = `${this.contactPerson.street} ${this.contactPerson.houseNumber}`;
        }
        break;
      case PdfRow.ZIP_CITY:
        if (contact) {
          attributeValue = `${contact.postalCode} ${contact.city}`;
        } else {
          attributeValue = `${this.contactPerson.postalCode} ${this.contactPerson.city}`;
        }
        break;
      case PdfRow.PHONE:
        if (contact) {
          attributeValue = contact.telephone;
        } else {
          attributeValue = this.contactPerson.telephone;
        }
        break;
      case PdfRow.EMAIL:
        if (contact) {
          attributeValue = contact.email;
        } else {
          attributeValue = this.contactPerson.email;
        }
        break;
    }

    return attributeValue;
  }

  private getInstallerDetail(pdfRow: PdfRow): string {
    let attributeValue = "";

    switch (pdfRow) {
      case PdfRow.COMPANY:
        if (!!this.installerContact) {
          attributeValue = this.installerContact.company ? this.installerContact.company : "-";
        } else if (!!this.installer) {
          if (this.installer.unternehmen === "Ja") {
            attributeValue = (this.installer.vorname && this.installer.name) ? `${this.installer.vorname} ${this.installer.name}` : this.installer.name;
          } else {
            attributeValue = "-";
          }
        }
        break;
      case PdfRow.NAME:
        if (this.installerContact) {
          attributeValue = `${this.installerContact.firstName} ${this.installerContact.lastName}`;
        } else if (this.installer) {
          if (this.installer.unternehmen === "Nein") {
            attributeValue = (this.installer.vorname && this.installer.name) ? `${this.installer.vorname} ${this.installer.name}` : this.installer.name;
          } else {
            attributeValue = "-";
          }
        }
        break;
      case PdfRow.STREET_NUMBER:
        if (this.installerContact) {
          attributeValue = (this.installerContact.street && this.installerContact.houseNumber) ? `${this.installerContact.street} ${this.installerContact.houseNumber}` : "-";
        } else if (this.installer) {
          attributeValue = this.installer.strasse ? this.installer.strasse : "-";
        }
        break;
      case PdfRow.ZIP_CITY:
        if (this.installerContact) {
          attributeValue = (this.installerContact.postalCode && this.installerContact.city) ? `${this.installerContact.postalCode} ${this.installerContact.city}` : "-";
        } else if (this.installer) {
          attributeValue = (this.installer.plz && this.installer.ort) ? `${this.installer.plz} ${this.installer.ort}` : "-";
        }
        break;
      case PdfRow.PHONE:
        if (this.installerContact) {
          attributeValue = this.installerContact.telephone ? this.installerContact.telephone : "-";
        } else if (this.installer) {
          attributeValue = this.installer.telefon ? this.installer.telefon : "-";
        }
        break;
      case PdfRow.EMAIL:
        if (this.installerContact) {
          attributeValue = this.installerContact.email ? this.installerContact.email : "-";
        } else if (this.installer) {
          attributeValue = this.installer.email ? this.installer.email : this.application?.customInstallerEmail;
        }
        break;
    }

    return attributeValue;
  }

  private getTitle(): string {
    let title: string = "";
    if (this.application?.type === Application.TypeEnum.Application && !!this.application.applicationId) {
      title = this.bfcTranslationService.translate("PDF.TITLE",
        { id: this.applicationService.getDisplayId(this.application) });
    } else if (this.application?.type === Application.TypeEnum.Mutation &&
      this.application.mutationType == Application.MutationTypeEnum.Cancellation &&
      !!this.application.connectionObjectNumber) {
      title = this.bfcTranslationService.translate("PDF.TITLE_CANCELLATION",
        { id: String(this.application.connectionObjectNumber) });
    } else if (this.application?.type === Application.TypeEnum.Mutation &&
      !!this.application.connectionObjectNumber) {
      title = this.bfcTranslationService.translate("PDF.TITLE_MUTATION",
        { id: String(this.application.connectionObjectNumber) });
    } else if (!this.application &&
      (this.migratedApplicationsStatusUpdates?.length > 0 || this.missingApplicationsAndComment !== null)) {
      title = this.bfcTranslationService.translate("PDF.MUTATION_MIGRATION_ACTIVATION.SUBTITLE");
    }
    return title;
  }

  private getMigratedApplications(applications: Application[]): Array<string> {
    const migratedApplicationsContent = [];

    applications?.forEach((application: Application) => {
      migratedApplicationsContent.push(
        [
          { text: this.getDisplayId(application) },
          { text: application.connectionObjectNumber },
          { text: this.address(application) },
          { text: this.formatDate(new Date(application?.lastUpdate)) },
          { text: this.accepted(application, this.migratedApplicationsStatusUpdates) },
        ],
      );
    });

    return migratedApplicationsContent;
  }

  private getMissingApplicationsSection(missingApplicationsAndComment: MissingApplicationsAndComment): Array<string> {
    const missingApplicationsSectionContent = [];

    if (missingApplicationsAndComment?.missingApplications?.length > 0) {
      missingApplicationsSectionContent.push(
        {
          text: this.bfcTranslationService.translate("PDF.MUTATION_MIGRATION_ACTIVATION.MISSING_APPLICATIONS"),
          style: "header",
          margin: [0, 36, 0, 0],
        },
        {
          table: {
            headerRows: 1,
            widths: ["30%", "70%"],
            body: [
              [
                {
                  text: this.bfcTranslationService.translate("ACTIVATE_MIGRATED_APPLICATIONS_DIALOG.NOT_FOUND.CONNECTION_OBJECT_NUMBER"),
                  style: "subHeader",
                },
                {
                  text: this.bfcTranslationService.translate("ACTIVATE_MIGRATED_APPLICATIONS_DIALOG.NOT_FOUND.ADDRESS"),
                  style: "subHeader",
                },
              ],
              ...this.getMissingApplications(missingApplicationsAndComment),
            ],
          },
          margin: [0, 24, 0, 12],
        },
        ...this.getMissingApplicationsComment(missingApplicationsAndComment),
      );
    }

    return missingApplicationsSectionContent;
  }

  private getMissingApplications(missingApplicationsAndComment: MissingApplicationsAndComment): Array<string> {
    const missingApplicationsContent = [];

    if (missingApplicationsAndComment?.missingApplications?.length > 0) {
      missingApplicationsAndComment?.missingApplications?.forEach((missingApplication: MissingApplication) => {
        missingApplicationsContent.push(
          [
            { text: missingApplication.connectionObjectNumber },
            { text: missingApplication.address },
          ],
        );
      });
    }

    return missingApplicationsContent;
  }

  private getMissingApplicationsComment(missingApplicationsAndComment: MissingApplicationsAndComment): Array<string> {
    const missingApplicationsCommentContent = [];

    if (missingApplicationsAndComment?.comment) {
      missingApplicationsCommentContent.push(
        {
          table: {
            headerRows: 1,
            widths: ["25%", "75%"],
            body: [
              [
                `${this.bfcTranslationService.translate("PDF.ACTIVATE_MIGRATED_APPLICATIONS.COMMENT")}:`,
                missingApplicationsAndComment.comment,
              ],
            ],
          },
          margin: [0, 24, 24, 0],
          layout: "noBorders",
        },
      );
    }

    return missingApplicationsCommentContent;
  }

  private getLandownersContent(landowners: ContactDetails[], residentialUnit: string, isNewLandowners: boolean): any {
    const landownersContent = [];

    landowners?.forEach((landowner: ContactDetails, index: number) => {
      landownersContent.push(
        {
          table: {
            headerRows: 1,
            widths: ["35%", "55%"],
            body: [
              [
                this.bfcTranslationService.translate("PDF.TABLE_LANDOWNER.NAME"),
                `${landowner?.lastName} ${landowner?.firstName}`,
              ],
              [
                this.bfcTranslationService.translate("PDF.TABLE_LANDOWNER.STREET"),
                `${landowner?.street}, ${landowner?.houseNumber}`,
              ],
              [
                this.bfcTranslationService.translate("PDF.TABLE_LANDOWNER.ZIP"),
                `${landowner?.postalCode} ${landowner?.city}`,
              ],
              [
                this.bfcTranslationService.translate("PDF.TABLE_LANDOWNER.PLACE_OF_CONSUMPTION"),
                { text: residentialUnit, margin: [0, 12, 0, 0] },
              ],
              ...this.getMeterNumber(),
            ],
          },
          ...this.getLandownerMargins(isNewLandowners, index),
        },
      );
    });

    return landownersContent;
  }

  private getLandownerMargins(isNewLandowner: boolean, index: number): any {
    if (isNewLandowner) {
      return {
        pageBreak: index == 0 ? "after" : "",
        margin: [12, 12, 0, 24],
        layout: "noBorders",
      };
    } else {
      return {
        margin: [12, 12, 0, 12],
        layout: "noBorders",
      };
    }
  }

  private getDisplayId(application: Application): string {
    return this.applicationService.getDisplayId(application, true);
  }

  private address(application: Application): string {
    return this.applicationService.getAddress(application);
  }

  private formatDate(inputDate: Date): string {
    return this.applicationService.formatDate(inputDate);
  }

  private accepted(application: Application,
    migratedApplicationsStatusUpdates: MigratedApplicationsStatusUpdate[]): string {
    let accepted: string = "";

    const migratedApplicationsStatusUpdate: MigratedApplicationsStatusUpdate = migratedApplicationsStatusUpdates.find(
      (update: MigratedApplicationsStatusUpdate) => update.applicationEntryId === application._id);

    if (migratedApplicationsStatusUpdate) {
      if (migratedApplicationsStatusUpdate.migrationUpdateStatus ===
        MigratedApplicationsStatusUpdate.MigrationUpdateStatusEnum.Accepted) {
        accepted = this.bfcTranslationService.translate("ACTIVATE_MIGRATED_APPLICATIONS_DIALOG.RADIO_BUTTONS.YES");
      } else if (migratedApplicationsStatusUpdate.migrationUpdateStatus ===
        MigratedApplicationsStatusUpdate.MigrationUpdateStatusEnum.Refused) {
        accepted = this.bfcTranslationService.translate("ACTIVATE_MIGRATED_APPLICATIONS_DIALOG.RADIO_BUTTONS.NO");
      }
    }

    return accepted;
  }

  private getBKWFont$(): Observable<any> {
    if (this.pdfFont$) {
      return this.pdfFont$;
    } else {
      // load font from assets to avoid loading font before export is triggered
      return this.httpClient.get("assets/pdffonts.json").pipe(
        tap(pdfFontJson => {
          this.pdfFont$ = of(pdfFontJson);
        }),
      );
    }
  }
}
