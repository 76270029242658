import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { BfcSinglePageLayoutModule } from "@bfl/components/single-page-layout";
import { BfcTranslationModule } from "@bfl/components/translation";
import { OnboardingComponent } from "./onboarding.component";
import { BfcLoadingModule } from "@bfl/components/loading";

@NgModule({
  imports: [
    BfcTranslationModule,
    FlexLayoutModule,
    CommonModule,
    BfcSinglePageLayoutModule,
    BfcLoadingModule,
  ],

  declarations: [
    OnboardingComponent,
  ],
})
export class OnboardingModule {
}