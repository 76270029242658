/**
 * ZEV Api
 * Zusammenschluss Eigenverbrauch API
 *
 * The version of the OpenAPI document: v1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface MigratedApplicationsStatusUpdate { 
    applicationEntryId?: string;
    applicationId?: number;
    migrationUpdateStatus?: MigratedApplicationsStatusUpdate.MigrationUpdateStatusEnum;
}
export namespace MigratedApplicationsStatusUpdate {
    export type MigrationUpdateStatusEnum = 'ACCEPTED' | 'REFUSED';
    export const MigrationUpdateStatusEnum = {
        Accepted: 'ACCEPTED' as MigrationUpdateStatusEnum,
        Refused: 'REFUSED' as MigrationUpdateStatusEnum
    };
}


