import { Injectable } from "@angular/core";
import { BfcTranslationService } from "@bfl/components/translation";
import { AbstractControl } from "@angular/forms";
import { PatternConstants } from "./pattern.constants";

@Injectable()
export class ValidationErrorMessageService {

  constructor(private bfcTranslationService: BfcTranslationService) {
  }

  validationErrorMessage(formControl: AbstractControl): string {
    let errorMessageKey = "";
    if (formControl.hasError("required")) {
      errorMessageKey = "VALIDATIONS.REQUIRED_FIELD";
    } else if (formControl.hasError("pattern")) {
      const requiredPattern = formControl.getError("pattern").requiredPattern;
      switch (requiredPattern) {
        case PatternConstants.POSTAL_CODE_PATTERN:
          errorMessageKey = "VALIDATIONS.POSTAL_CODE_INVALID";
          break;
        case PatternConstants.METER_NUMBER_PATTERN:
          errorMessageKey = "VALIDATIONS.METER_NUMBER_INVALID";
          break;
        case PatternConstants.PHONE_NUMBER_PATTERN:
          errorMessageKey = "VALIDATIONS.PHONE_NUMBER_INVALID";
          break;
        case PatternConstants.HOUSE_NUMBER_PATTERN:
          errorMessageKey = "VALIDATIONS.HOUSE_NUMBER_INVALID";
          break;
        case PatternConstants.GP_NUMBER_PATTERN:
          errorMessageKey = "VALIDATIONS.GP_NUMBER_INVALID";
          break;
      }
    } else if (formControl.hasError("email")) {
      errorMessageKey = "VALIDATIONS.EMAIL_INVALID";
    } else if (formControl.hasError("noMatch")) {
      errorMessageKey = "VALIDATIONS.CONFIRMATION_EMAIL_INVALID";
    }  else if (formControl.hasError("gpNumberNoMatch")) {
      errorMessageKey = "VALIDATIONS.CONFIRMATION_GP_NUMBER_INVALID";
    } else if (formControl.hasError("maxlength")) {
      errorMessageKey = "VALIDATIONS.MAXIMUM_LENGTH";
    }

    return this.bfcTranslationService.translate(errorMessageKey);
  }

}
