export const FR_TRANSLATIONS = {
  BFC_UNAUTHORIZED: {
    TITLE: "Accès refusé",
    CONTENT: "Vous ne disposez pas des autorisations requises pour ce service.",
  },
  CONTACT: {
    TITLE: "Centre de contact (CSC):",
    PHONE: "<a href='tel:+41844121113'>+41 844 121 113</a>",
    EMAIL: "<a href='mailto:service-clients@bkw.ch'>service-clients@bkw.ch</a>",
  },
  APPLICATION_CREATION_STEPPER: {
    LABELS: {
      CONNECTION_OBJECT: "Données",
      LANDOWNERS: "Propriétaires fonciers",
      INSTALLER: "Installateur-électricien sous concession",
      APPLICATION_BASICS: "Fondements de la demande",
      SUBMISSION: "Remise du formulaire de demande",
    },
  },
  ONBOARDING: {
    TITLE: "Un instant...",
  },
  CONNECTION_OBJECT: {
    INTRO_1: "Formulaire de demande de création d’un regroupement dans le cadre de la consommation propre (RCP)",
    INTRO_2: "Bienvenue! Vous pouvez remplir ci-dessous la demande de création d’un regroupement dans le cadre de la consommation propre et la remettre à votre installatrice-électricienne ou installateur-électricien sous concession.",
    INTRO_3: "Vous êtes tenu(e) de fournir toutes les informations demandées de manière complète et véridique. Si vous cliquez sur «Envoyer la demande» à la fin du formulaire de demande, celui-ci sera transmis à l’installatrice-électricienne ou l’installateur-électricien que vous avez mandaté(e). Votre installatrice-électricienne ou installateur-électricien sous concession doit alors compléter votre formulaire de demande avec les formulaires d’annonces correspondants (avis d’installation avec schéma de mesure et plan de situation) et nous les faire parvenir. Ce n’est qu’après réception de tous les documents par votre installatrice-électricienne ou installateur-électricien sous concession que nous examinerons la demande de création d’un regroupement dans le cadre de la consommation propre. Nous ne remettons l’approbation de la demande qu’à votre installatrice-électricienne ou installateur-électricien sous concession. Vous ne pouvez plus modifier la demande qui nous a été transmise. Si votre demande est acceptée, les éventuelles modifications doivent nous être signalées sur ce portail au moyen d’une annonce de mutation.",
    DETAILS: "Données relatives à l’objet de raccordement et à l’interlocuteur compétent du regroupement dans le cadre de la consommation propre",
    REQUEST: "Les propriétaires foncières et propriétaires fonciers ci-après demandent à BKW Energie SA (ci-après BKW) de créer un regroupement dans le cadre de la consommation propre (RCP) au sens de l’article 17 de la loi sur l’énergie (LEne; RS 730.0) à l’adresse suivante:",
    CONNECTION_OBJECT_DATA: "Objet de raccordement du regroupement dans le cadre de la consommation propre:",
    TOOLTIP: "Chaque regroupement dans le cadre de la consommation propre est légalement tenu de communiquer au gestionnaire de réseau de distribution (ci-après BKW) le nom d’une interlocutrice compétente ou d’un interlocuteur compétent. La relation contractuelle est gérée par ce dernier ou cette dernière. Concrètement, cela signifie notamment que l’avis de coupure de courant, les factures d’électricité et toute autre correspondance sont adressés uniquement à l’interlocutrice compétente ou à l’interlocuteur compétent du regroupement dans le cadre de la consommation propre. Les propriétaires foncières et propriétaires fonciers ou autres participants au regroupement ne sont pas contactés par BKW, à l’exception de la demande de contrôle périodique. L’interlocutrice compétente ou l’interlocuteur compétent dispose en outre du droit d’annoncer à BKW les mutations (comme entre autres le changement de propriétaire foncier ou de destinataire de la facture).",
    ADDRESS: {
      STREET: "Rue",
      HOUSE_NUMBER: "Numéro",
      ZIP: "NPA",
      CITY: "Lieu",
      CITY_HINT: "* pour les adresses à l'étranger, indiquer S.V.P. le lieu et le pays",
    },
    LANDOWNER_TITLE: "Interlocutrice/Interlocuteur compétent du regroupement dans le cadre de la consommation propre",
    LANDOWNER_INFO: "Les propriétaires foncières et propriétaires fonciers chargent l’interlocutrice compétente ou l’interlocuteur compétent suivant(e) de représenter et de défendre de manière contraignante le regroupement ainsi que leurs intérêts vis-à-vis de BKW concernant le regroupement dans le cadre de la consommation propre:",
    CONTACT_DETAILS: {
      COMPANY: "Société/Gérance",
      LAST_NAME: "Nom",
      FIRST_NAME: "Prénom",
      STREET: "Rue",
      HOUSE_NUMBER: "Numéro",
      ZIP: "NPA",
      CITY: "Lieu",
      CITY_HINT: "* pour les adresses à l'étranger, indiquer S.V.P. le lieu et le pays",
      EMAIL: "E-mail",
      EMAIL_CONFIRMATION: "Confirmation de l’e-mail",
      TELEPHONE: "N° de téléphone",
      LEGAL_SUBJECT: "Société/Gérance ou Particulier",
      LEGAL_SUBJECTS: {
        COMPANY: "Société/Gérance",
        PRIVATE: "Particulier",
      },
    },
    ADD_BILLING_PERSON: "Saisir le destinataire de la facture",
    ADD_BILLING_PERSON_TOOLTIP: "Le changement du/de la destinataire de la facture n’a aucune incidence sur la présente relation contractuelle. Toutes les autres correspondances entre BKW et le regroupement dans le cadre de la consommation propre continuent d’être adressées à l’interlocutrice compétente ou l’interlocuteur compétent.",
    BILLING_PERSON_1: "Les factures et la rémunération du surplus d’énergie pour le regroupement dans le cadre de la consommation propre sont adressées par défaut à l’interlocutrice compétente ou l’interlocuteur compétent.",
    BILLING_PERSON_2: "Si la facturation doit être adressée à une autre personne/entreprise, il convient d’en faire la demande ci-après:",
    BILLING_CHECKBOX: "Le/La destinataire de la facture correspond à l’interlocutrice compétente ou l’interlocuteur compétent",
  },
  LANDOWNERS: {
    SUBTITLE: "Propriétaires foncières et propriétaires fonciers qui forment un regroupement dans le cadre de la consommation propre",
    SUBTITLE_TOOLTIP: "Un justificatif de propriété (extrait du registre foncier ou extrait du système cantonal d’information sur les données foncières) doit être téléchargé pour chaque propriétaire foncière et propriétaire foncier.",
    REQUEST: "Les propriétaires foncières et propriétaires fonciers, les copropriétaires et les titulaires du droit de superficie (selon l’inscription au registre foncier) doivent être mentionnés ci-après pour chaque site de consommation (logement, commerce, étable, etc.). D’un point de vue juridique, il est nécessaire que nous connaissions les différents sites de consommation (logements, commerces, étables, etc.) du regroupement et qui est la propriétaire foncière et le propriétaire foncier correspondant(e):",
    PLACE_OF_CONSUMPTION_1: "Site de consommation 1",
    PLACE_OF_CONSUMPTION: "Site de consommation",
    ADD_PLACE_OF_CONSUMPTION: "Saisir d’autres sites de consommation",
    REMOVE_PLACE_OF_CONSUMPTION: "Supprimer le site de consommation",
    PLACE_OF_CONSUMPTION_TOOLTIP: "Logement / Site de consommation: Pour les bâtiments existants, vous trouverez des détails concernant le site de consommation sur votre facture d’électricité. Exemples: Logement rez-de-chaussée. N° 42, Studio rez-de-chaussée droite, Locaux communs et chauffage, Comble dernier étage gauche, Société Exemple SA, Restaurant Bären",
    PLACE_OF_CONSUMPTION_FORM: {
      RESIDENTIAL_UNIT: "Logement / Site de consommation (rez-de-chaussée, étable, gauche/droite)",
      RESIDENTIAL_UNIT_LABEL: "Logement / Site de consommation",
      TYPE: "Type",
      TYPE_TOOLTIP: "Type: Nouvelle construction: se rapporte à la nouvelle construction d’une maison d’habitation, d’un commerce, etc. Transformation/Modification/Extension: se rapporte à la transformation, modification ou à l’extension d’une maison d’habitation, d’un commerce, etc. existant.",
      METER_NUMBER: "Numéro de compteur BKW",
      METER_NUMBER_TOOLTIP: "Vous trouverez le numéro de compteur sur votre facture d’électricité. (si disponible)",
      SUPPLY_POINT: "Lieu de prélèvement BKW",
      SUPPLY_POINT_TOOLTIP: "Vous trouverez le lieu de prélèvement sur votre facture d’électricité. Exemple: Lieu de prélèvement: N° 123456, rue des Exemples 3, 3333 Exempleville. Il est possible d’indiquer soit le numéro du lieu de prélèvement, soit une désignation textuelle du site de consommation.",
      SOLE_OWNERSHIP: "Propriété individuelle",
    },
    INFO: "Pour tous les sites de consommation appartenant à plusieurs personnes (p. ex. couples, frères et sœurs), toutes les personnes doivent être mentionnées individuellement.",
    LANDOWNER_DATA: "Renseignements propriétaire foncière/foncier 1",
    LANDOWNER: "Propriétaire foncière/foncier",
    ADD_LANDOWNER: "Saisir d’autres propriétaires fonciers",
    REMOVE_LANDOWNER: "Supprimer le propriétaire foncière/foncier",
    PRINT_POWER_OF_ATTORNEY: "Imprimer la procuration / déclaration de l’interlocuteur compétent",
    PRINT_MUTATION_LANDOWNER: "Imprimer l’avis de mutation du propriétaire foncier",
    PLACE_OF_CONSUMPTION_TYPES: {
      NEW: "Nouvelle construction",
      RECONSTRUCTION: "Transformation/Modification/Extension",
    },
    SOLE_OWNERSHIPS: {
      YES: "Oui",
      NO: "Non",
    },
    PROOF_OF_OWNERSHIP: "Justificatif de propriété:",
    PROOF_OF_OWNERSHIP_REQUEST: "Nous vous prions de télécharger ci-dessous, pour chaque bien-fonds faisant partie du regroupement, un justificatif de propriété de chaque propriétaire foncière et propriétaire foncier au moyen d’un extrait du registre foncier cantonal ou d’un extrait du système cantonal d’information sur les données foncières.",
    SIZE_ATTENTION: "Attention:",
    SIZE_INFO: "Le téléchargement est limité en taille de fichier ! La taille maximale d'un fichier est de 4 Mo (mégaoctets). Au total, il est possible de télécharger des pièces jointes d'une taille maximale de 35 Mo (mégaoctets).",
    SIZE_FILE_INFO: "Le téléchargement est limité en taille de fichier ! La taille maximale d'un fichier est de 4 Mo (méga-octets).",
    SIZE_ERROR_MESSAGE: "Le fichier «{{DOC_NAME}}» est plus grand que 4 Mo (mégaoctets) et ne peut pas être téléchargé. La taille d'un fichier ne doit pas dépasser 4 Mo (méga-octets).",
    CAN_BE_MORE: "(il peut y en avoir plusieurs)",
    PLOT_A: "Parcelle A",
    ADD_PLOT: "Ajouter une parcelle",
    REMOVE_PLOT: "Supprimer la parcelle",
    PLOT: "Parcelle",
    ADD_POWER_OF_ATTORNEY: "ajouter un autre formulaire de procuration et de déclaration avec signatures",
    ADD_MUTATION_LANDOWNER: "ajouter un autre avis de mutation Propriétaire foncier avec signature",
    REMOVE_POWER_OF_ATTORNEY: "Supprimer le formulaire de procuration et de déclaration avec signatures",
    REMOVE_MUTATION_LANDOWNER: "Supprimer l’avis de mutation du propriétaire foncier avec signature",
    POWER_OF_ATTORNEY: "Formulaire de procuration et de déclaration avec signatures",
    MUTATION_LANDOWNER: "Annonce de mutation du propriétaire foncier avec signature",
    UPLOAD_LAND_REGISTER: "Télécharger un extrait du registre foncier / un extrait du système cantonal d’information sur les données foncières",
    LINKS_LAND_REGISTERS: "Liens vers les systèmes cantonaux d’information sur les données foncières:",
    CANTON_OF_BERN: "Canton de Berne:",
    CANTON_OF_BERN_1: "Kantonales Grundbuchamt - Startseite (be.ch)",
    CANTON_OF_BERN_1_LINK: "https://www.gba.dij.be.ch/de/start.html",
    CANTON_OF_BERN_2: "GRUDIS Anmeldung (be.ch)",
    CANTON_OF_BERN_2_LINK: "https://sso.be.ch/auth/realms/capitastra_prod/protocol/openid-connect/auth?response_type=code&client_id=capitastra-client&state=RkdMUzJ4anV6Y3pQRi16V2FiTFhnYWZObVVtcGlrbG4yb3ZvU3VBaC11SDY1%3B%252Fhome%252Fdashboard&redirect_uri=https%3A%2F%2Fwww.grudis.apps.be.ch%2Fgrudis%2Fcapweb%2Fui%2F&scope=openid%20profile%20email%20roles&code_challenge=uusPwdtFlZiPBdBqSCODP-cFWhh6YU9DU49TVjVpbVA&code_challenge_method=S256&nonce=RkdMUzJ4anV6Y3pQRi16V2FiTFhnYWZObVVtcGlrbG4yb3ZvU3VBaC11SDY1&ui_locales=de",
    CANTON_OF_BERN_3: "Geoportal Kanton Bern Basiskarte mit Eigentumsauskunft von Grundstücken",
    CANTON_OF_BERN_3_LINK: "https://www.map.apps.be.ch/pub/synserver?project=a42pub_basisgreika&userprofile=geo&client=core&language=de",
    CANTON_OF_SOLOTHURN: "Canton de Soleure:",
    CANTON_OF_SOLOTHURN_1: "Grundbuchämter - Kanton Solothurn",
    CANTON_OF_SOLOTHURN_1_LINK: "https://so.ch/verwaltung/finanzdepartement/grundbuchaemter/",
    CANTON_OF_SOLOTHURN_2: "Web GIS Client Kanton Solothurn",
    CANTON_OF_SOLOTHURN_2_LINK: "https://geo.so.ch/map/?realty=&l=ch.so.agi.av.grundstuecke&bl=hintergrundkarte_sw&t=default&c=2618500%2C1238000&s=200000",
    CANTON_OF_JURA: "Canton du Jura:",
    CANTON_OF_JURA_1: "Registre foncier - République et Canton du Jura",
    CANTON_OF_JURA_1_LINK: "https://www.jura.ch/DIN/RFC/Registre-foncier/Registre-foncier.html",
    CANTON_OF_JURA_2: "SIT-Jura Geoportal",
    CANTON_OF_JURA_2_LINK: "https://geo.jura.ch/theme/Cadastre?lang=fr&tree_group_layers_Point%20d%27interets%20-%20Exclusive=Fermeture_provisoire_TourismeLoisirs%2CPOI_Defibrillateurs%2CPOI_Administration&baselayer_ref=background_plan_cadastral&baselayer_opacity=0&theme=Cadastre&tree_groups=Cadastre%2Ctilecloud_cadastre&tree_group_layers_Cadastre=cadastre_pfp_1_2%2Ccadastre_pfp_3%2Cbatiments_projetes%2Cbatiments",
    CANTON_OF_BASELLAND: "Canton de Bâle-Campagne:",
    CANTON_OF_BASELLAND_1: "Grundbuch — baselland.ch",
    CANTON_OF_BASELLAND_1_LINK: "https://www.baselland.ch/politik-und-behorden/direktionen/sicherheitsdirektion/zivilrechtsverwaltung/grundbuch",
    CANTON_OF_BASELLAND_2: "GeoView BL",
    CANTON_OF_BASELLAND_2_LINK: "https://geoview.bl.ch/",
    SIGNATURE_LANDOWNERS: "Signatures des propriétaires foncières/fonciers",
    SIGNATURE_LANDOWNERS_DESCRIPTION: "Déclaration: Pour créer de manière juridiquement valable un regroupement dans le cadre de la consommation propre, nous avons besoin d’une procuration et d’une signature de toutes les propriétaires foncières et tous les propriétaires fonciers. En outre, une déclaration et une signature correspondantes de l’interlocutrice compétente ou de l’interlocuteur compétent sont également nécessaires. Pour ce faire, il convient d’imprimer le formulaire de procuration et de déclaration suivant, de le signer intégralement et de le télécharger à nouveau ici dans la demande. Une photo ou un scan du document suffit.",
    SIGNATURE_LANDOWNERS_FORM_1: "Si le formulaire n’est pas entièrement rempli, ",
    SIGNATURE_LANDOWNERS_FORM_2: "les documents seront rejetés lors de leur soumission.",
    UPLOAD_POWER_OF_ATTORNEY: "Télécharger le formulaire de procuration et de déclaration avec signatures",
    UPLOAD_MUTATION_LANDOWNER: "Télécharger l'annonce de mutation du propriétaire foncier avec signature",
    ERROR_SIZE: "Le/les fichiers que vous avez téléchargés dépassent la taille maximale autorisée (35 Mo).",
  },
  INSTALLER_COMPONENT: {
    TITLE: "Installateur-électricien sous concession",
    TITLE_TOOLTIP: "Une fois la demande terminée, l’installatrice-électricienne ou l’installateur-électricien mandaté(e) reçoit une copie de la demande saisie et des annexes (PDF) par e-mail. Dans ce courrier, il/elle est également invité(e) à envoyer l’avis d’installation, le schéma de mesure et le plan de situation avec le formulaire de demande au service de raccordement de BKW en tant que demande officielle via le logiciel de la branche Elektroform15 / Elektroform online.",
    INFO: "C’est ici que vous pouvez choisir l’installateur-électricien chargé de l’exécution. Cela peut se faire via l’annuaire de l’ESTI (les installateurs sont proposés en indiquant le nom de l’entreprise). Si le partenaire spécialisé souhaité ne figure pas dans l’annuaire de l’ESTI, il peut également être saisi manuellement.",
    CHOOSE_INSTALLER: "Installatrice-électricienne ou installateur-électricien sous concession",
    MANUAL: "Veuillez saisir (manuellement) votre installatrice-électricienne ou installateur-électricien sous concession.",
    AUTO: "Sélectionnez une installatrice-électricienne ou un installateur-électricien sous concession dans l’annuaire de l’ESTI.",
    LABELS: {
      NAME: "Nom",
      FIRST_NAME: "Prénom",
      STREET: "Rue",
      ZIP: "NPA",
      CITY: "Localité",
      PHONE: "N° de téléphone",
      EMAIL: "E-mail",
    },
    FORM: {
      PLACEHOLDERS: {
        COMPANY: "Entreprise",
        LAST_NAME: "Nom",
        FIRST_NAME: "Prénom",
        PHONE: "N° de téléphone",
        EMAIL: "E-mail",
        EMAIL_CONFIRMATION: "Confirmation de l’e-mail",
      },
    },
  },
  APPLICATION_BASICS: {
    TITLE: "Fondements de la demande",
    TEXT_1: "1. Validité de la demande",
    TEXT_1_1: "1.1      Les documents suivants, qui font partie intégrante du formulaire de demande, doivent être remis à BKW en même temps que le formulaire de demande:",
    TEXT_A_1: "A)      Formulaire de procuration et de déclaration: Le formulaire de demande doit être accompagné du formulaire de procuration et de déclaration. Par la procuration, tous les propriétaires qui participent au regroupement confirment qu’ils souhaitent créer un regroupement dans le cadre de la consommation propre, acceptent les",
    TEXT_A_2: "Conditions particulières de BKW Energie SA Regroupement dans le cadre de la consommation propre (RCP)",
    TEXT_A_3: "et autorisent l’interlocutrice compétente ou l’interlocuteur compétent à les représenter pleinement vis-à-vis de BKW. Par cette déclaration, l’interlocutrice compétente ou l’interlocuteur compétent mandaté(e) confirme connaître et accepter les droits et obligations découlant de sa fonction et des",
    TEXT_A_4: "Conditions particulières de BKW Energie SA Regroupement dans le cadre de la consommation propre (RCP)",
    TEXT_A_5: ".",
    TEXT_B: "B)      Justificatif de propriété: Le formulaire de demande doit être accompagné des justificatifs de propriété (extraits du registre foncier ou du système d’information sur les données foncières) de tous les propriétaires qui participent au regroupement.",
    TEXT_1_2: "1.2      Le formulaire de demande et ses annexes doivent être remis à BKW en même temps que l’avis d’installation par une installatrice-électricienne ou un installateur-électricien sous concession. L’avis d’installation doit également être accompagné d’un schéma de mesure détaillé et d’un plan de situation indiquant à quel(le) propriétaire foncière ou propriétaire foncier les différents sites de consommation (logement, commerce, installation de production d’énergie, etc.) et les installations peuvent être attribués.",
    TEXT_1_3: "1.3      L’interlocutrice compétente ou l’interlocuteur compétent assume la responsabilité des sites de consommation qui ne peuvent être attribués à aucun(e) propriétaire foncière ou propriétaire foncier. Il s’ensuit que l’interlocutrice compétente ou l’interlocuteur compétent se substitue aux droits et obligations d’une propriétaire foncière ou d’un propriétaire foncier pour ce site de consommation (p. ex. obligation de fournir le justificatif de sécurité, paiement de l’énergie prélevée sur le réseau, y compris les taxes pour le site de consommation, etc.).",
    TEXT_1_4: "1.4      Le formulaire de demande ne peut être rempli que par une personne mandatée à cet effet par les propriétaires foncières et propriétaires fonciers de l’objet de raccordement et transmis à l’installatrice-électricienne compétente ou l’installateur-électricien compétent.",
    TEXT_1_5: "1.5      En cas de mutations (par exemple changement de propriétaires foncières ou de propriétaires fonciers ou d’interlocutrice compétente ou d’interlocuteur compétent, modifications concernant la facturation, etc.), celles-ci doivent être communiquées sans délai à BKW par l’interlocutrice compétente ou l’interlocuteur compétent au moyen du formulaire correspondant disponible sur le portail clients RCP de BKW.",
    TEXT_1_6: "1.6      Toute modification de l’installation du regroupement doit être signalée à BKW par l’installatrice-électricienne ou l’installateur-électricien au moyen des formulaires d’annonces correspondants. De telles modifications ne sont pas mises à jour sur ce portail.",
    TEXT_1_7: "1.7      En cas d’informations erronées ou manquantes ou de documents manquants, la demande et donc l’avis d’installation correspondant ne seront pas traités et seront retournés pour être améliorés ou complétés. La demande ne sera pas acceptée si elle n’a pas été complétée. Dans le cas d’un regroupement dans le cadre de la consommation propre déjà existant, BKW se réserve le droit de mettre fin au regroupement dans le cadre de la consommation propre. Les propriétaires foncières et propriétaires fonciers sont solidairement responsables de tous les coûts et dommages causés à BKW en raison de la dissolution du regroupement dans le cadre de la consommation propre ou d’autres mesures.",
    TEXT_2: "2. Fourniture d'énergie",
    TEXT_2_1: "2.1      La fourniture d'énergie électrique est effectuée dans le cadre de l'approvisionnement de base par BKW, pour autant que le RCP soit un consommateur captif selon l'article 6, alinéa 2 de la Loi sur l'approvisionnement en électricité (LApEl; RS 734.7) ou qu'il n'ait pas demandé l'accès au réseau selon l'article 13 LApEl.",
    TEXT_2_2: "2.2      Si le RCP dispose d'un libre accès au réseau, la fourniture d'énergie est effectuée par le fournisseur d'énergie choisi par le RCP.",
    TEXT_2_3: "2.3      Deux mois avant sa création ou la mise en service de son raccordement, le RCP doit communiquer à BKW par e-mail à <a href=\"mailto:s_verteilnetzbetreiber@bkw.ch\">s_verteilnetzbetreiber@bkw.ch</a> s'il fait usage de son droit d'accès au réseau (cf. article 11, al. 3 de l'Ordonnance sur l'approvisionnement en électricité, OApEl; RS 734.71).",
    TEXT_2_4: "2.4      La création d'un RCP n'a aucune incidence sur les obligations découlant des contrats de fourniture d’énergie (électrique) existants en cas d'accès libre au réseau. Les éventuelles conditions d'adaptation ou de résiliation des contrats de fourniture d’énergie (électrique) existants doivent être clarifiées avec le fournisseur d'énergie compétent.",
    TEXT_3: "3. Rapports juridiques",
    TEXT_3_1: "3.1      Les rapports juridiques entre les propriétaires foncières et propriétaires fonciers du regroupement dans le cadre de la consommation propre et BKW sont régis par le présent formulaire de demande, y compris les annexes correspondantes, les",
    TEXT_3_1_2: "Conditions particulières de BKW Energie SA Regroupement dans le cadre de la consommation propre (RCP)",
    TEXT_3_1_3: "à l’objet de raccordement susmentionné ainsi que les autres Conditions générales, prescriptions et normes spécialisées qui y sont mentionnées.",
    TEXT_3_2: "3.2      Il convient notamment de respecter les dispositions pertinentes de la législation sur l’énergie et l’approvisionnement en électricité, qui contiennent entre autres des règles sur les conditions du regroupement dans le cadre de la consommation propre, sur les obligations d’information et d’annonce des propriétaires foncières et propriétaires fonciers du regroupement dans le cadre de la consommation propre ainsi que sur les relations avec le gestionnaire de réseau. Les propriétaires foncières et propriétaires fonciers du regroupement dans le cadre de la consommation propre se conforment aux dispositions légales applicables et prennent connaissance du fait que BKW, en tant que gestionnaire de réseau, peut mettre fin au regroupement dans le cadre de la consommation propre ou prendre d’autres mesures appropriées en cas d’infraction.",
    LINK_TITLE: "Lien vers les CP RCP et autres CG",
    LINK_TEXT: "CCG et questions juridiques - BKW",
    LINK: "https://www.bkw.ch/fr/ccg-et-questions-juridiques",
    COMMENTS_1: "Avez-vous d’autres",
    COMMENTS_2: "remarques concernant le regroupement dans le cadre de la consommation propre?",
    COMMENTS_3: "Champ facultatif",
    COMMENTS_LABEL: "Remarques",
  },
  SUBMISSION: {
    TITLE: "Remise du formulaire de demande",
    TITLE_TOOLTIP: "La demande peut être modifiée à tout moment si elle a simplement été enregistrée. Il en va de même si la demande a été envoyée à votre installatrice-électricienne ou installateur-électricien. Dès que nous avons reçu et vérifié votre demande de la part de votre installatrice-électricienne ou installateur-électricien, le statut devient \"Transmise à BKW\". A partir de ce moment, aucune donnée ne peut plus être modifiée.",
    INFO: "Une fois la demande terminée, le formulaire de demande sera transmis automatiquement par e-mail à votre installatrice-électricienne ou installateur-électricien sous concession. Il ou elle établit ensuite l’avis d’installation ainsi que le schéma de mesure détaillé et le plan de situation et les envoie à BKW avec votre demande pour examen. Comme décrit au début, nous examinons votre demande après réception de tous les documents par votre installatrice-électricienne ou installateur-électricien sous concession. Nous ne remettons l’approbation de la demande qu’à votre installatrice-électricienne ou installateur-électricien sous concession. Vous ne pouvez plus modifier la demande qui nous a été transmise.",
    AGB_1: "En envoyant la demande, les propriétaires foncières et propriétaires fonciers du regroupement dans le cadre de la consommation propre et l’interlocutrice compétente ou l’interlocuteur compétent déclarent avoir pris connaissance, compris et accepté les",
    AGB_2: "Conditions particulières de BKW Energie SA Regroupement dans le cadre de la consommation propre (RCP) ",
    AGB_3: "ainsi que les prescriptions d’entreprise actuelles et en vigueur.",
    IMPACT_1: "Effet de la remise du formulaire de demande:",
    IMPACT_2: "La remise du formulaire de demande et de l’avis d’installation, accompagnés des annexes requises, crée une relation contractuelle entre les propriétaires foncières et propriétaires fonciers du regroupement dans le cadre de la consommation propre et BKW Energie SA. La condition préalable à l’entrée en vigueur d’une relation contractuelle est que les conditions techniques et juridiques soient remplies et que la demande RCP et les formulaires d’annonces correspondants (avis d’installation, schéma de mesure détaillé, plan de situation etc.) aient été approuvés.",
    IMPACT_3: "Veuillez noter qu’aucune modification ne sera apportée à la demande déposée, même si des mutations interviennent ultérieurement au sein du regroupement dans le cadre de la consommation propre existant.",
    AGREEMENT_LABEL: "J’approuve / Nous approuvons la présente demande.",
    EMAIL_INFO: "Une fois la demande déposée, vous recevrez un e-mail de confirmation contenant votre demande.",
  },
  UPLOAD: {
    HOVER_VIEW_DOCUMENT: "Pas encore de document téléchargé",
    HOVER_REMOVE_DOCUMENT: "Supprimer le document",
    ERROR: "Erreur de lecture ou de validation du fichier. Seuls les formats d’image JPG/JPEG/PNG ou PDF sont acceptés ainsi qu’une taille de fichier maximale de 35 Mo. Le nom du fichier ne doit contenir que des lettres, des chiffres et des underscores (tiret bas).",
  },
  VALIDATIONS: {
    REQUIRED_FIELD: "Ce champ est obligatoire",
    EMAIL_INVALID: "Adresse e-mail non valable",
    CONFIRMATION_EMAIL_INVALID: "Les adresses e-mail ne sont pas identiques",
    POSTAL_CODE_INVALID: "NPA non valable",
    METER_NUMBER_INVALID: "Numéro de compteur BKW non valable",
    PHONE_NUMBER_INVALID: "Numéro de téléphone non valable",
    HOUSE_NUMBER_INVALID: "Numéro de maison non valable",
    CONNECTION_OBJECT_NUMBER_INVALID: "Numéro AO non valable",
    GP_NUMBER_INVALID: "Numéro client non valable",
    CONFIRMATION_GP_NUMBER_INVALID: "Les numéros clients ne sont pas identiques",
    MAXIMUM_LENGTH: "la longueur maximale autorisée est dépassée",
    CANCELLATION_DATE: "le délai de résiliation est de 3 mois",
  },
  BUTTONS: {
    NEXT: "Suivant",
    BACK: "Précédent",
    SAVE: "Enregistrer temporairement",
    CANCEL: "Annuler",
    SUBMIT: "Soumettre la demande",
    RE_SUBMIT: "Resoumettre la demande",
  },
  PDF: {
    TITLE: "RCP ID demande n° {{id}}",
    TITLE_MUTATION: "Formulaire de mutation – Objet de raccordement RCP n° {{id}}",
    TITLE_CANCELLATION: "Formulaire de résiliation – Objet de raccordement RCP n° {{id}}",
    NOTE_MUTATION: "Le présent formulaire de mutation est envoyé directement à BKW Energie SA via le portail clients RCP après l’envoi du document. La mutation sera mise en œuvre rapidement. En cas de questions, veuillez vous adresser à notre service clients (service-clients@bkw.ch).",
    POWER_OF_ATTORNEY: {
      FILE_NAME: "_Procuration / Déclaration de l’interlocuteur compétent",
      TITLE: "Procuration / Déclaration de l’interlocuteur compétent",
      SUB_1: "I.      Procuration",
      TEXT_CONNECTION_OBJECT: "Les propriétaires foncières et propriétaires fonciers ci-après demandent à BKW Energie SA (ci-après BKW) de créer un regroupement dans le cadre de la consommation propre (RCP) au sens de l’article 17 de la loi sur l’énergie (LEne; RS 730.0) à l’adresse suivante:",
      TEXT_CONTACT_PERSON: "À cet effet, ils chargent l’interlocutrice compétente ou l’interlocuteur compétent suivant(e) de représenter et de défendre de manière contraignante les intérêts des propriétaires foncières et propriétaires fonciers vis-à-vis de BKW concernant le regroupement dans le cadre de la consommation propre:",
      TEXT_LANDOWNERS: "Les propriétaires foncières et propriétaires fonciers de l’objet de raccordement susmentionné confirment par la signature de la présente procuration, qu’ils acceptent la demande approuvée par BKW (y compris les annexes et les suppléments), y compris l’avis d’installation, les Conditions particulières de BKW Energie SA Regroupement dans le cadre de la consommation propre (RCP) ainsi que les Conditions générales de BKW dans leurs versions en vigueur, notamment les CG de BKW pour le raccordement au réseau et l’utilisation du réseau et les annonces de mutation respectives comme éléments constitutifs du contrat pour la création du regroupement dans le cadre de la consommation propre. Les propriétaires foncières et propriétaires fonciers de l’objet de raccordement susmentionné sont chacun entièrement responsables de la mise en œuvre du contrat.",
      SUB_2: "II.      Déclaration de l’interlocuteur compétent",
      TEXT_DECLARATION: "L’interlocutrice compétente ou l’interlocuteur compétent soussigné(e) prend acte d’avoir été désigné(e) et chargé(e) par les propriétaires foncières et propriétaires fonciers susmentionné(e)s comme interlocutrice compétente ou interlocuteur compétent du regroupement dans le cadre de la consommation propre et déclare accepter la fonction d’interlocutrice compétente ou d’interlocuteur compétent. \n\n" +
        "En signant cette déclaration, l’interlocutrice compétente ou l’interlocuteur compétent déclare connaître et accepter les droits et obligations découlant de sa fonction et des Conditions particulières de BKW Energie SA Regroupement dans le cadre de la consommation propre (RCP) ainsi que des Conditions générales de BKW dans leurs versions en vigueur, notamment les CG de BKW pour le raccordement au réseau et l’utilisation du réseau.",
      PLACE_DATE: "Lieu, date",
      SIGNATURE_CONTACT_PERSON: "Signature de l’interlocuteur compétent",
      NOTE: "La présente procuration doit être signée dans son intégralité et téléchargée sur le portail clients RCP en tant que partie intégrante du formulaire de demande de création d’un regroupement dans le cadre de la consommation propre. Une photo ou un scan du document suffit. En cas de questions, veuillez vous adresser à notre service clients (service-clients@bkw.ch).",
      TABLE: {
        NAME: "Nom et prénom",
        PLACE_DATE: "Lieu, date",
        SIGNATURE: "Signature",
      },
    },
    APPLICATION: {
      FILE_NAME: "_Formulaire de demande regroupement dans le cadre de la consommation propre",
      TITLE: "Formulaire de demande de création d’un regroupement dans le cadre de la consommation propre (RCP)",
      SUB_1: "1.      Données relatives à l’objet de raccordement et à l’interlocuteur compétent du regroupement dans le cadre de la consommation propre",
      TEXT_CONNECTION_OBJECT: "Les propriétaires foncières et propriétaires fonciers ci-après demandent à BKW Energie SA (ci-après BKW) de créer un regroupement dans le cadre de la consommation propre (RCP) au sens de l’article 17 de la loi sur l’énergie (LEne; RS 730.0) à l’adresse suivante:",
      TITLE_CONNECTION_OBJECT: "Objet de raccordement du regroupement dans le cadre de la consommation propre:",
      TITLE_CONTACT_PERSON: "Interlocutrice/Interlocuteur compétent du regroupement dans le cadre de la consommation propre:",
      TEXT_CONTACT_PERSON: "Les propriétaires foncières et propriétaires fonciers ci-après chargent l’interlocutrice compétente ou l’interlocuteur compétent suivant(e) de représenter et de défendre de manière contraignante le regroupement ainsi que leurs intérêts vis-à-vis de BKW concernant le regroupement dans le cadre de la consommation propre:",
      SUB_2: "2.      Données relatives aux différents sites de consommation (comme les logements, les bâtiments) du regroupement dans le cadre de la consommation propre et aux propriétaires foncières et propriétaires fonciers correspondant(e)s",
      SUB_3: "3.      Nouvelle/Nouveau destinataire de la facture du regroupement dans le cadre de la consommation propre",
      TEXT_INVOICE_RECIPIENT: "Les propriétaires foncières et propriétaires fonciers chargent la personne / l’entreprise ci-dessous à traiter les factures et la rémunération du surplus d’énergie pour le regroupement dans le cadre de la consommation propre:",
      SUB_4: "{{number}}.      Installatrice-électricienne/Installateur-électricien mandaté(e)",
      TEXT_INSTALLER: "Les propriétaires foncières et propriétaires fonciers du regroupement dans le cadre de la consommation propre chargent l’installatrice-électricienne ou l’installateur-électricien mentionné(e) ci-après de soumettre l’avis d’installation, y compris le schéma de mesure et le plan de situation, en même temps que le formulaire de demande en tant que demande officielle via le logiciel de la branche Elektroform15 / Elektroform online auprès du service de raccordement de BKW.",
      SUB_5: "{{number}}.      Fondements de la demande",
      BASICS: "1.      Validité de la demande\n\n" +
        "1.1      Les documents suivants, qui font partie intégrante du formulaire de demande, doivent être remis à BKW en même temps que le formulaire de demande:\n\n" +
        "A)       Formulaire de procuration et de déclaration: Le formulaire de demande doit être accompagné du formulaire de procuration et de déclaration. Par la procuration, tous les propriétaires qui participent au regroupement confirment qu’ils souhaitent créer un regroupement dans le cadre de la consommation propre, acceptent les Conditions particulières de BKW Energie SA Regroupement dans le cadre de la consommation propre (RCP) et autorisent l’interlocutrice compétente ou l’interlocuteur compétent à les représenter pleinement vis-à-vis de BKW. Par cette déclaration, l’interlocutrice compétente ou l’interlocuteur compétent mandaté(e) confirme connaître et accepter les droits et obligations découlant de sa fonction et des Conditions particulières de BKW Energie SA Regroupement dans le cadre de la consommation propre (RCP).\n\n" +
        "B)       Justificatif de propriété: Le formulaire de demande doit être accompagné des justificatifs de propriété (extraits du registre foncier ou du système d’information sur les données foncières) de tous les propriétaires qui participent au regroupement.\n\n" +
        "1.2      Le formulaire de demande et ses annexes doivent être remis à BKW en même temps que l’avis d’installation par une installatrice-électricienne ou un installateur-électricien sous concession. L’avis d’installation doit également être accompagné d’un schéma de mesure détaillé et d’un plan de situation indiquant à quel(le) propriétaire foncière ou propriétaire foncier les différents sites de consommation (logement, commerce, installation de production d’énergie, etc.) et les installations peuvent être attribués.\n\n" +
        "1.3      L’interlocutrice compétente ou l’interlocuteur compétent assume la responsabilité des sites de consommation qui ne peuvent être attribués à aucun(e) propriétaire foncière ou propriétaire foncier. Il s’ensuit que l’interlocutrice compétente ou l’interlocuteur compétent se substitue aux droits et obligations d’une propriétaire foncière ou d’un propriétaire foncier pour ce site de consommation (p. ex. obligation de fournir le justificatif de sécurité, paiement de l’énergie prélevée sur le réseau, y compris les taxes pour le site de consommation, etc.).\n\n" +
        "1.4      Le formulaire de demande ne peut être rempli que par une personne mandatée à cet effet par les propriétaires foncières et propriétaires fonciers de l’objet de raccordement et transmis à l’installatrice-électricienne compétente ou l’installateur-électricien compétent.\n\n" +
        "1.5      En cas de mutations (par exemple changement de propriétaires foncières ou de propriétaires fonciers ou d’interlocutrice compétente ou d’interlocuteur compétent, modifications concernant la facturation, etc.), celles-ci doivent être communiquées sans délai à BKW par l’interlocutrice compétente ou l’interlocuteur compétent au moyen du formulaire correspondant disponible sur le portail clients RCP de BKW.\n\n" +
        "1.6      Toute modification de l’installation du regroupement doit être signalée à BKW par l’installatrice-électricienne ou l’installateur-électricien au moyen des documents de signalement correspondants. De telles modifications ne sont pas mises à jour sur ce portail.\n\n" +
        "1.7      En cas d’informations erronées ou manquantes ou de documents manquants, la demande et donc l’avis d’installation correspondant ne seront pas traités et seront retournés pour être améliorés ou complétés. La demande ne sera pas acceptée si elle n’a pas été complétée. Dans le cas d’un regroupement dans le cadre de la consommation propre déjà existant, BKW se réserve le droit de mettre fin au regroupement dans le cadre de la consommation propre. Les propriétaires foncières et propriétaires fonciers sont solidairement responsables de tous les coûts et dommages causés à BKW en raison de la dissolution du regroupement dans le cadre de la consommation propre ou d’autres mesures.\n\n\n" +
        "2. Fourniture d'énergie\n\n" +
        "2.1      La fourniture d'énergie électrique est effectuée dans le cadre de l'approvisionnement de base par BKW, pour autant que le RCP soit un consommateur captif selon l'article 6, alinéa 2 de la Loi sur l'approvisionnement en électricité (LApEl; RS 734.7) ou qu'il n'ait pas demandé l'accès au réseau selon l'article 13 LApEl.\n\n" +
        "2.2      Si le RCP dispose d'un libre accès au réseau, la fourniture d'énergie est effectuée par le fournisseur d'énergie choisi par le RCP.\n\n" +
        "2.3      Deux mois avant sa création ou la mise en service de son raccordement, le RCP doit communiquer à BKW par e-mail à s_verteilnetzbetreiber@bkw.ch s'il fait usage de son droit d'accès au réseau (cf. article 11, al. 3 de l'Ordonnance sur l'approvisionnement en électricité, OApEl; RS 734.71).\n\n" +
        "2.4      La création d'un RCP n'a aucune incidence sur les obligations découlant des contrats de fourniture d’énergie (électrique) existants en cas d'accès libre au réseau. Les éventuelles conditions d'adaptation ou de résiliation des contrats de fourniture d’énergie (électrique) existants doivent être clarifiées avec le fournisseur d'énergie compétent.\n\n\n" +
        "3.       Rapports juridiques\n\n" +
        "3.1      Les rapports juridiques entre les propriétaires foncières et propriétaires fonciers du regroupement dans le cadre de la consommation propre et BKW sont régis par le présent formulaire de demande, y compris les annexes correspondantes, les Conditions particulières de BKW Energie SA Regroupement dans le cadre de la consommation propre (RCP) à l’objet de raccordement susmentionné ainsi que les autres Conditions générales, prescriptions et normes spécialisées qui y sont mentionnées.\n\n" +
        "3.2      Il convient notamment de respecter les dispositions pertinentes de la législation sur l’énergie et l’approvisionnement en électricité, qui contiennent entre autres des règles sur les conditions du regroupement dans le cadre de la consommation propre, sur les obligations d’information et d’annonce des propriétaires foncières et propriétaires fonciers du regroupement dans le cadre de la consommation propre ainsi que sur les relations avec le gestionnaire de réseau. Les propriétaires foncières et propriétaires fonciers du regroupement dans le cadre de la consommation propre se conforment aux dispositions légales applicables et prennent connaissance du fait que BKW, en tant que gestionnaire de réseau, peut mettre fin au regroupement dans le cadre de la consommation propre ou prendre d’autres mesures appropriées en cas d’infraction.\n\n",
      LINK_TITLE: "Lien vers les CP RCP et autres CG",
      LINK_TEXT: "CCG et questions juridiques - BKW",
      SUB_6: "{{number}}.      Remarques",
      SUBMISSION_TITLE: "Remise du formulaire de demande",
      SUBMISSION_TEXT: "En envoyant la demande, les propriétaires foncières et propriétaires fonciers du regroupement dans le cadre de la consommation propre et l’interlocutrice compétente ou l’interlocuteur compétent déclarent avoir pris connaissance, compris et accepté les Conditions particulières de BKW Energie SA Regroupement dans le cadre de la consommation propre (RCP) ainsi que les prescriptions d’entreprise actuelles et en vigueur.\n\n" +
        "La remise du formulaire de demande et de l’avis d’installation, accompagnés des annexes requises, crée une relation contractuelle entre les propriétaires foncières et propriétaires fonciers du regroupement dans le cadre de la consommation propre et BKW Energie SA. La condition préalable à l’entrée en vigueur d’une relation contractuelle est que les conditions techniques et juridiques soient remplies et que la demande ait été acceptée.",
    },
    POWER_OF_ATTORNEY_INVOICE_RECIPIENT: {
      FILE_NAME: "_Procuration du destinataire de la facture",
      TITLE: "Procuration du destinataire de la facture",
      INTRO: "Les propriétaires foncières et propriétaires fonciers du regroupement dans le cadre de la consommation propre demandent ci-après que les factures et la rémunération du surplus d’énergie pour le regroupement dans le cadre de la consommation propre ne soient pas traitées par l’interlocutrice compétente ou l’interlocuteur compétent, mais chargent à cet effet la personne / l’entreprise mentionnée ci-dessous:",
      INVOICE_RECIPIENT: "Destinataire de la facture de l’objet de raccordement suivant",
      TABLE_HEADER: "Objet de raccordement (lieu du point de raccordement au réseau du regroupement)",
      ADDRESS: "Adresse:",
      WILL: "devient ",
      INFO: "La notification des coupures de courant ainsi que le reste de la correspondance sont adressés à l’interlocutrice compétente ou à l’interlocuteur compétent du regroupement dans le cadre de la consommation propre.",
    },
    POWER_OF_ATTORNEY_INVOICE_RECIPIENT_ADDRESS: {
      FILE_NAME: "_Procuration du destinataire de la facture changement d’adresse",
      TITLE: "Procuration du destinataire de la facture",
      INTRO: "Ce formulaire de mutation sert à enregistrer un changement d’adresse du destinataire de la facture de l’objet de raccordement à l’adresse suivante: {{address}}",
      INVOICE_RECIPIENT: "Le changement d’adresse suivant du destinataire de la facture pour le regroupement dans le cadre de la consommation propre est enregistré:",
      INFO: "Le présent formulaire de mutation est envoyé directement à BKW Energie SA via le portail clients RCP après l’envoi du document. La mutation sera mise en œuvre rapidement. En cas de questions, veuillez vous adresser à notre service clients (service-clients@bkw.ch).",
    },
    CANCELLATION: {
      FILE_NAME: "_Résiliation du regroupement dans le cadre de la consommation propre",
      TITLE: "Résiliation du regroupement dans le cadre de la consommation propre",
      INTRO: "Les propriétaires foncières et propriétaires fonciers résilient pour fin {{cancellationDate}}, en respectant un délai de résiliation de trois mois, le regroupement dans le cadre de la consommation propre existant à l’adresse suivante:",
      TABLE_HEADER: "Objet de raccordement (lieu du point de raccordement au réseau du regroupement)",
      ADDRESS: "Adresse:",
      INFO: "La consommation propre à l’objet de raccordement prend fin à la fin du délai de résiliation.\n\n" +
        "Une résiliation du regroupement dans le cadre de la consommation propre n’entraîne pas la résiliation du raccordement au réseau. Si des adaptations doivent être effectuées du côté du raccordement au réseau, elles doivent être demandées au gestionnaire de réseau dans les délais, indépendamment de la présente résiliation.\n\n" +
        "Pour l’approvisionnement des différents sites de consommation, les propriétaires foncières et propriétaires fonciers ou une installatrice-électricienne ou installateur-électricien sous concession doivent remettre les documents de signalement correspondants et, dans ce contexte, mettre également à disposition de BKW les équipements nécessaires pour l’infrastructure de mesure.\n\n" +
        "La résiliation ne prend effet que si les documents de signalement sont remis en même temps.",
      TABLE: {
        NAME: "Nom et prénom",
        PLACE_DATE: "Lieu, date",
        SIGNATURE: "Signature",
      },
    },
    MUTATION_LANDOWNER_INTERN: {
      FILE_NAME: "_Changement de propriétaire",
      TITLE: "Annonce de mutation du propriétaire foncier",
      INTRO: "Ce formulaire de mutation sert à enregistrer un changement dans la composition des propriétaires foncières et propriétaires fonciers de l’objet de raccordement à l’adresse suivante: {{address}}",
      TEXT_OLD_LANDOWNER: "La propriétaire foncière sortante ou le propriétaire foncier sortant ainsi que la propriétaire foncière ou le propriétaire foncier qui le/la remplace sont indiqués ci-après:",
      EXITING_LANDOWNER: "Propriétaire foncière sortante, propriétaire foncier sortant:",
      NEW_LANDOWNER: "Propriétaire foncière ou propriétaire foncier reprenant du RCP:",
      INFO: "La propriétaire foncière ou le propriétaire foncier reprenant du RCP de l’objet de raccordement susmentionné succède sans réserve au statut juridique de la propriétaire foncière ou du propriétaire foncier sortant(e).",
    },
    MUTATION_LANDOWNER_EXTERN: {
      FILE_NAME: "_Changement de propriétaire",
      TITLE: "Annonce de mutation de propriétaire foncier",
      INTRO: "Ce formulaire de mutation sert à enregistrer un changement dans la composition des propriétaires foncières et propriétaires fonciers de l’objet de raccordement à l’adresse suivante: {{address}}",
      TEXT_OLD_NEW_LANDOWNER: "Les entrées et sorties suivantes des propriétaires foncières et propriétaires fonciers sont enregistrées:",
      EXITING_LANDOWNER: "Personne sortante resp. personnes sortantes:",
      NEW_LANDOWNER: "Personne entrante resp. personnes entrantes:",
      AGREEMENT_TEXT: "La nouvelle propriétaire foncière ou le nouveau propriétaire foncier entrant(e) resp. les propriétaires foncières et propriétaires fonciers entrant(e)s de l’objet de raccordement susmentionné reprend resp. reprennent sans réserve le statut juridique de la propriétaire foncière sortante ou du propriétaire foncier sortant resp. des propriétaires foncières et propriétaires fonciers sortant(e)s et confirme resp. confirment, par la signature de la présente annonce de mutation, qu’il(s)/elle(s) accepte resp. acceptent la demande approuvée par BKW (y compris les annexes et les suppléments), y compris l’avis d’installation, les Conditions particulières de BKW Energie SA Regroupement dans le cadre de la consommation propre (RCP) ainsi que les Conditions générales de BKW dans leurs versions en vigueur, notamment les CG de BKW pour le raccordement au réseau et l’utilisation du réseau et les annonces de mutation respectives comme éléments constitutifs du contrat du regroupement dans le cadre de la consommation propre. Les propriétaires foncières et propriétaires fonciers de l’objet de raccordement susmentionné sont chacun entièrement responsables de la mise en œuvre du contrat.",
    },
    MUTATION_CONTACT_PERSON_NEW: {
      FILE_NAME: "_Annonce de mutation d’interlocuteur compétent",
      TITLE: "Annonce de mutation d’interlocuteur compétent",
      INTRO: "Ce formulaire de mutation sert à enregistrer un changement d’interlocutrice compétente ou d’interlocuteur compétent de l’objet de raccordement à l’adresse suivante: {{address}}",
      TEXT_CHANGE_CONTACT_PERSON: "Le changement suivant d’interlocutrice compétente ou d’interlocuteur compétent pour le regroupement dans le cadre de la consommation propre est enregistré:",
      EXITING_CONTACT_PERSON: "Interlocutrice compétente ou interlocuteur compétent sortant(e):",
      NEW_CONTACT_PERSON: "Nouvelle interlocutrice compétente, nouvel interlocuteur compétent:",
      AGREEMENT_TEXT: "L’interlocutrice compétente ou l’interlocuteur compétent soussigné(e) prend connaissance du fait que les propriétaires foncières et propriétaires fonciers du regroupement dans le cadre de la consommation propre l’ont désigné(e) comme interlocutrice compétente ou interlocuteur compétent du regroupement dans le cadre de la consommation propre et l’ont chargé(e) de représenter et de défendre de manière contraignante les intérêts des propriétaires foncières et propriétaires fonciers vis-à-vis de BKW concernant le regroupement dans le cadre de la consommation propre. L’interlocutrice compétente ou l’interlocuteur compétent soussigné(e) déclare assumer la fonction d’interlocutrice compétente ou d’interlocuteur compétent.\n\n" +
        "En signant cette annonce de mutation, l’interlocutrice compétente ou l’interlocuteur compétent déclare connaître et accepter les droits et obligations découlant de sa fonction et des Conditions particulières de BKW Energie SA Regroupement dans le cadre de la consommation propre (RCP) ainsi que des Conditions générales de BKW dans leurs versions en vigueur, notamment les CG de BKW pour le raccordement au réseau et l’utilisation du réseau.",
      AGREEMENT_LABEL: "Lieu, date, signature de la nouvelle interlocutrice compétente ou du nouvel interlocuteur compé-tent:",
    },
    MUTATION_CONTACT_PERSON_ADDRESS: {
      FILE_NAME: "_Annonce de mutation d’interlocuteur compétent",
      TITLE: "Annonce de mutation d’interlocuteur compétent",
      INTRO: "Ce formulaire de mutation sert à enregistrer un changement d’adresse de l’interlocutrice compétente ou de l’interlocuteur compétent de l’objet de raccordement à l’adresse suivante: {{address}}",
      ADDRESS_CHANGE: "Le changement d’adresse suivant de l’interlocutrice compétente ou de l’interlocuteur compétent pour le regroupement dans le cadre de la consommation propre est enregistré:",
    },
    MUTATION_MIGRATION_ACTIVATION: {
      FILE_NAME: "_Formulaire de mutations -  « Mise en ligne des contrats sur le portail clients RCP»",
      SUBTITLE: "Formulaire de mutations -  « Mise en ligne des contrats sur le portail clients RCP»",
      TITLE: "Rapport de mutation « Mise en ligne des contrats sur leportail clients RCP»",
      INTRO: "Aperçu de la mise en ligne des contrats ZEV (contrat de mise en place de la consommation propre) sur le portail client ZEV.",
      TABLE: {
        ACCEPTED: "Contrat repris",
      },
      NOTE: "Une fois la mise en ligne des contrats ZEV effectuée avec succès, vous pouvez, via le portail ZEV soumettre des mutations concernant les différents regroupements pour la consommation propre.",
      MISSING_APPLICATIONS: "Les contrats RCP manquants",
    },
    TABLE: {
      COMPANY: "Entreprise:",
      NAME: "Nom:",
      PHONE: "N° de téléphone:",
      EMAIL: "E-mail:",
    },
    TABLE_LANDOWNER: {
      NAME: "Nom et prénom",
      STREET: "Rue, n°",
      ZIP: "NPA, localité",
      PLACE_OF_CONSUMPTION: "Logement / point de consommation du RCP concerné (comme RDC, gauche/droite, etc.)",
      METER_NUMBER: "Numéro de compteur BKW (numéro de série), si disponible",
    },
    ACTIVATE_MIGRATED_APPLICATIONS: {
      COMMENT: "Remarques:",
    },
  },
  APPLICATION_OVERVIEW: {
    TITLE: "Aperçu des demandes de RCP",
    NEW_APPLICATION: "Remplir un nouveau formulaire de demande",
    MIGRATE_APPLICATIONS: "Mise en ligne des contrats dans le portail clients RCP",
    ACTIVATE_MIGRATED_APPLICATIONS: "Mise en ligne des contrats sur le portail clients RCP",
    FIELD_LABEL: "Champ",
    SEARCH_PLACEHOLDER: "Recherche",
    SEARCH_HINT: "Veuillez appuyer sur l’icône de recherche ou sur la touche ENTER pour lancer la recherche.",
    TYPE_LABEL: "Type de formulaire",
    STATUS_LABEL: "Statut",
    FIELD_SELECTION_OPTIONS: {
      ID: "ID de la demande",
      ADDRESS: "Adresse du RCP",
      INSTALLER: "Installateur-électricien sous concession",
      AO: "N° AO",
      FORM_TYPE: "Type de formulaire",
      STATUS: "Statut",
    },
    COLUMN_TITLES: {
      ID: "ID de la demande",
      CONNECTION_OBJECT_NUMBER: "N° AO",
      ADDRESS: "Adresse du RCP",
      INSTALLER: "Installateur-électricien sous concession",
      LAST_UPDATE: "Dernière modification",
      FORM_TYPE: "Type de formulaire",
      STATUS: "Statut",
      AUTHOR: "Créateur",
    },
    FORM_TYPE: {
      APPLICATION: "Demande",
      INVOICE_RECIPIENT_ADDRESS: "Mutation du/de la destinataire de la facture (adresse)",
      INVOICE_RECIPIENT_NEW: "Mutation du/de la destinataire de la facture (nouveau) ",
      LANDOWNER_CHANGE: "Mutation propriétaire foncier",
      CONTACT_PERSON_ADDRESS: "Mutation de l’interlocutrice compétente/l’interlocuteur compétent (adresse)",
      CONTACT_PERSON_NEW: "Mutation de l’interlocutrice compétente/l’interlocuteur compétent (nouveau)",
      CANCELLATION: "Résiliation",
      LANDOWNER_CHANGE_INTERN: "Mutation de la propriétaire foncière/du propriétaire foncier",
      LANDOWNER_CHANGE_EXTERN: "Mutation de la propriétaire foncière/du propriétaire foncier",
      MIGRATED_MUTATION: "Mutation",
    },
    STATUS: {
      ALL: "Tout",
      DRAFT: "Saisie",
      COMPLETE: "Soumise",
      APPROVED: "Transmise à BKW",
      CANCELLED: "Résiliée",
      INVALID: "Non valide",
      CHECKED: "Vérifié",
      MIGRATED: "Migre",
    },
    STATUS_TOOLTIP:{
      DRAFT: "les données peuvent être modifiées",
      APPROVED: "Votre installatrice-électricienne ou installateur-électricien a transmis votre demande RCP à BKW. BKW traite les documents transmis et indique à votre installatrice-électricienne ou installateur-électricien si le RCP peut être constitué. Les modifications/ajouts apportés ultérieurement à la demande doivent nous être annoncés au moyen de formulaires de mutation.",
      CANCELLED: "a été approuvée par BKW. Ne peut plus faire l’objet d’une mutation.",
      CHECKED: "La signature a été vérifiée",
      MIGRATED: {
        APPLICATION: "Le contrat RCP a été migré mais pas encore mis en ligne.",
        MUTATION: "La mutation a été migré mais pas encore mis en ligne.",
      },
      INVALID: {
        APPLICATION: "La demande n’est pas valable",
        MUTATION: "La mutation n’est pas valable",
      },
      COMPLETE: {
        APPLICATION: "a été remise à l’installateur électricien pour soumission. Les données peuvent être modifiées et soumises à nouveau",
        MUTATION: "a été transmise aux services concernés pour mutation",
      },
    },
    ACTIONS: {
      EDIT: "Modifier",
      DOCUMENT_VIEW: "Vue du document",
      GENERATE_PDFS: {
        BUTTON_TEXT: "Génère des PDF",
        SUCCESS: "Les PDF ont pu être générés avec succès",
        FAILED: "Une erreur s'est produite. Les PDF n'ont pas pu être générés!",
      },
      MUTATION: "Mutation",
      CANCEL: "Résiliation",
      DELETE: "Supprimer",
    },
  },
  DOCUMENTS_OVERVIEW: {
    TITLE: "Vue du document",
    APPLICATION_DOCUMENT_LABEL: "Détails de la demande",
    APPLICATION_DOCUMENT: "Le formulaire de demande",
    LAND_REGISTERS_LABEL: "Justificatif de propriété",
    POWER_OF_ATTORNEY_LABEL: "Signatures des propriétaires foncières/fonciers",
    POWER_OF_ATTORNEY: "Procuration / Déclaration de l’interlocuteur compétent",
    POWER_OF_ATTORNEY_INVOICE_RECIPIENT: "Procuration du destinataire de la facture",
    POWER_OF_ATTORNEY_INVOICE_RECIPIENT_ADDRESS: "Procuration du destinataire de la facture",
    CANCELLATION: "Résiliation",
    MERGED_PDF: "Total PDF",
    MUTATION_LANDOWNER: "Annonce de mutation de propriétaire foncier",
    MUTATION_CONTACT_PERSON_ADDRESS: "Changement d’adresse de l’interlocutrice compétente/l’interlocuteur compétent",
    MUTATION_CONTACT_PERSON_NEW: "Changement d’interlocutrice compétente/d’interlocuteur compétent",
    MIGRATED_MUTATION_LABEL: "Mutations",
    MIGRATED_MUTATION: "Mutation",
    MUTATION_MIGRATION_ACTIVATION: "Rapport de mutation « Mise en ligne des contrats sur leportail clients RCP»",
  },
  MUTATION_OVERVIEW: {
    TITLE: "Sélection de la mutation",
    SUBTITLE: "Vous pouvez sélectionner ici la mutation souhaitée",
    LANDOWNER: "Mutation de la propriétaire foncière/du propriétaire foncier",
    INVOICE_RECIPIENT_ADDRESS: "Mutation du/de la destinataire de la facture (adresse)",
    INVOICE_RECIPIENT_NEW: "Mutation du/de la destinataire de la facture (nouveau)",
    CONTACT_PERSON_ADDRESS: "Mutation de l’interlocutrice compétente/l’interlocuteur compétent (adresse)",
    CONTACT_PERSON_NEW: "Mutation de l’interlocutrice compétente/l’interlocuteur compétent (nouveau)",
  },
  APPLICATION_APPROVAL_DIALOG: {
    ID: "ID de la demande: ",
    CONNECTION_OBJECT_NUMBER: "N° AO",
    APPROVED: "Demande acceptée",
    CANCEL: "Annuler",
    CONFIRM: "Confirmer",
  },
  CHECK_CONTACT_PERSON_MUTATION: {
    ID: "ID de la mutation: ",
    CHECKED: "Signature vérifiée",
    INVALID: "Mutation Non valide",
    CANCEL: "Annuler",
    CONFIRM: "Confirmer",
  },
  CANCELLATION_APPROVAL_DIALOG: {
    ID: "ID de la résiliation: ",
    DRAFT: "Réinitialiser le statut à “Saisi“",
    APPROVED: "Résiliation acceptée",
    CANCEL: "Annuler",
    CONFIRM: "Confirmer",
  },
  APPLICATION_NOTIFICATION_MESSAGES: {
    APPLICATION: {
      DRAFT: "La demande a été enregistrée avec succès. Vous pouvez continuer à traiter la demande en cliquant sur le bouton “Modifier“.",
      COMPLETE: "Nous vous remercions pour votre demande. La demande a été envoyée à l’installateur-électricien pour qu’il soumette les formulaires d’annonces.",
      UPDATED: "La demande a été actualisée avec succès.",
      DELETED: "La demande a été supprimée avec succès.",
      DELETION_FAILED: "Une erreur s’est produite lors de la suppression de la demande.",
      UPDATE_FAILED: "Une erreur s’est produite lors de la réactualisation de la demande.",
    },
    MUTATION: {
      DRAFT: "Enregistré avec succès. Pour que la mutation soit envoyée par e-mail, veuillez cliquer sur le bouton “Soumettre la demande”.",
      COMPLETE: "Nous vous remercions pour votre mutation. La mutation sera mise en œuvre rapidement.",
      UPDATED: "La mutation a été actualisée avec succès.",
      DELETED: "La mutation a été supprimée avec succès.",
      DELETION_FAILED: "Une erreur s’est produite lors de la suppression de la mutation.",
      UPDATE_FAILED: "Une erreur s’est produite lors de la réactualisation de la mutation.",
    },
    CONNECTION_OBJECT_NUMBER_UPDATED: "Le numéro AO a été actualisé avec succès.",
    APPROVED: "La demande a été acceptée avec succès.",
    CANCELLATION_COMPLETE: "Nous vous remercions pour votre mutation. Nous allons examiner votre demande de résiliation.",
    CANCELLATION_APPROVED: "La résiliation a été accepté avec succès.",
    CANCELLATION_REVERTED: "Le statut de résiliation a été réinitialisé avec succès.",
    STATE_CHANGED: "Le statut a été modifié avec succès.",
    FAILED: "Vos données sont incorrectes, veuillez vérifier les champs. Merci beaucoup.",
    MIGRATION_IN_PROGRESS: "La migration des contrats est en cours. Veuillez patienter.",
    MIGRATION_FAILED: "Une erreur s’est produite lors de la migration des contrats RCP.",
    MIGRATED: "La migration des contrats RCP est terminée.",
    ACTIVATED: "Nous vous remercions pour votre mutation.",
    ERROR_DOCUMENTS_UPDATE: "Une erreur s’est produite lors de la récupération des documents.",
  },
  DELETE_APPLICATION_DIALOG: {
    APPLICATION: {
      TITLE: "Supprimer la demande",
      CONFIRMATION_TEXT: "Êtes-vous sûr de vouloir supprimer la demande?",
    },
    MUTATION: {
      TITLE: "Supprimer la mutation",
      CONFIRMATION_TEXT: "Êtes-vous sûr de vouloir supprimer la mutation?",
    },
    CANCEL: "Annuler",
    CONFIRM: "Supprimer",
  },
  MUTATION_INVOICE_RECIPIENT_DIALOG: {
    TITLE_NEW: "Mutation du/de la destinataire de la facture (nouveau)",
    TITLE_ADDRESS: "Mutation du/de la destinataire de la facture (adresse)",
    INTRO: "Si les factures et la rémunération du surplus d’énergie pour le regroupement dans le cadre de la consommation propre ne doivent pas être traitées par l’interlocutrice compétente ou l’interlocuteur compétent, mais par une tierce personne ou une entreprise, le formulaire «Procuration du destinataire de la facture» doit nous être remis sans délai.",
    INVOICE_RECIPIENT: "Destinataire de la facture de l’objet de raccordement suivant",
    CANCEL: "Annuler",
    SAVE: "Enregistrer",
    COMPLETE: "Soumettre l’annonce",
  },
  MUTATION_CONTACT_PERSON_DIALOG: {
    TITLE_ADDRESS: "Mutation Coordonnées de l’interlocuteur compétent",
    TITLE_NEW: "Mutation nouvel interlocuteur compétent",
    HELPTEXT: "Si l’interlocutrice compétente ou l’interlocuteur compétent existant(e) quitte le RCP et qu’une autre interlocutrice compétente ou interlocuteur compétent reprend cette fonction et cette responsabilité, le formulaire «Annonce de mutation de l’interlocuteur compétent» doit nous être remis sans délai.",
    TOOLTIP: "Chaque regroupement dans le cadre de la consommation propre est légalement tenu de communiquer au gestionnaire de réseau de distribution (ci-après BKW) le nom d’une interlocutrice compétente ou d’un interlocuteur compétent. La relation contractuelle est gérée par ce dernier ou cette dernière. Concrètement, cela signifie notamment que l’avis de coupure de courant, les factures d’électricité et toute autre correspondance sont adressés uniquement à l’interlocutrice compétente ou à l’interlocuteur compétent du regroupement dans le cadre de la consommation propre. Les propriétaires foncières et propriétaires fonciers ou autres participants au regroupement ne sont pas contactés par BKW, à l’exception de la demande de contrôle périodique. L’interlocutrice compétente ou l’interlocuteur compétent dispose en outre du droit d’annoncer à BKW les mutations (comme entre autres le changement de propriétaire foncier ou de destinataire de la facture).",
    SUBTITLE_ADDRESS: "Données relatives au changement d’adresse de l’interlocuteur compétent",
    SUBTITLE_NEW: "Informations sur le nouvel interlocuteur compétent",
    INTRO_ADDRESS: "Si vous changez d’adresse, vous devez nous communiquer immédiatement votre nouvelle adresse afin que nous puissions vous envoyer toute correspondance future à votre nouvelle adresse. Indiquez votre nouvelle adresse dans les champs ci-dessous et soumettez-nous les informations en cliquant sur le bouton «Soumettre l’annonce».",
    INTRO_NEW: "Les propriétaires foncières et propriétaires fonciers chargent l’interlocutrice compétente ou l’interlocuteur compétent suivant(e) de représenter et de défendre de manière contraignante le regroupement ainsi que leurs intérêts vis-à-vis de BKW concernant le regroupement dans le cadre de la consommation propre:",
    SUBTITLE_MUTATION: "Annonce de mutation d’interlocuteur compétent",
    MUTATION_1: "Déclaration: En signant cette annonce de mutation, l’interlocutrice compétente ou l’interlocuteur compétent déclare connaître et accepter les droits et obligations découlant de sa fonction et des",
    MUTATION_2: "Conditions particulières de BKW Energie SA Regroupement dans le cadre de la consommation propre",
    MUTATION_3: "dans leurs versions en vigueur, notamment les CG de BKW pour le raccordement au réseau et l’utilisation du réseau.",
    NOTE_1: "Si le formulaire n’est pas entièrement rempli,",
    NOTE_2: "les documents seront rejetés lors de leur soumission.",
    PRINT: "Imprimer l’annonce de mutation de l’interlocuteur compétent",
    UPLOAD: "Télécharger l’annonce de mutation de l’interlocuteur compétent avec les signatures",
    NEW_CONTACT_PERSON_NOTE: "Pour que nous puissions attribuer le RCP à la nouvelle interlocutrice compétente ou au nouvel interlocuteur compétent, celle-ci ou celui-ci doit s’enregistrer sur le portail clients RCP. Nous vous informerons par e-mail dès que la demande RCP aura été attribuée à la nouvelle interlocutrice compétente ou au nouvel interlocuteur compétent.",
    CANCEL: "Annuler",
    SAVE: "Enregistrer",
    COMPLETE: "Soumettre l’annonce",
  },
  CANCELLATION_DIALOG: {
    INTRO_1: "Voulez-vous vraiment résilier le RCP complet?",
    INTRO_2: "Souhaitez-vous dissoudre le regroupement dans le cadre de la consommation propre à une date précise? Dans ce cas, le formulaire «Résiliation du regroupement dans le cadre de la consommation propre» doit nous être remis au moins trois mois à l’avance. Veuillez noter que la résiliation ne sera effective que lorsque votre installatrice-électricienne ou installateur-électricien aura remis en même temps les documents de signalement correspondants pour l’ approvisionnement des différents sites de consommation.",
    INTRO_3: "Vous pouvez imprimer ici le formulaire de résiliation, le compléter avec les signatures de toutes les propriétaires foncières et tous les propriétaires fonciers et l’envoyer.",
    DATE_CONFIRMATION: "Je confirme/Nous confirmons la présente résiliation pour fin",
    SIGNATURES_LANDOWNERS: "Signatures des propriétaires fonciers",
    NOTE_1: "Si le formulaire n’est pas entièrement rempli,",
    NOTE_2: "le formulaire de résiliation sera rejeté pour être complété.",
    PRINT_CANCELLATION_FORM: "Imprimer le formulaire de résiliation",
    UPLOAD_CANCELLATION_FORM: "Télécharger le formulaire «Résiliation du regroupement dans le cadre de la consommation propre» avec signatures n",
    COMPLETE: "Soumettre la résiliation",
  },
  MIGRATE_APPLICATIONS_DIALOG: {
    QUESTION_1: "Voulez-vous vraiment lancer la migration des contrats RCP vers le portail clients ?",
    QUESTION_2: "L’Excel \"Transfert de données\" est-il mis à jour pour la migration ? L’Excel ne doit pas être en cours de traitement pendant la migration.",
    STATEMENT: "Pour la migration, {{count}} interlocuteurs compétents sont prêts.",
  },
  LANDOWNER_MUTATION: {
    TITLE: "Informations sur le changement de propriétaire foncier",
    SUBTITLE_1: "Désinscription : vous avez la possibilité de désinscrire une resp. un ou tous les propriétaires foncières resp. propriétaires fonciers d'un site de consommation. Si vous souhaitez désinscrire certaines propriétaires foncières resp. propriétaires fonciers d'un site de consommation, vous devez nous faire parvenir une annonce de mutation séparée pour chaque propriétaire foncière resp. propriétaire foncier désinscrit.",
    SUBTITLE_2: "Inscription : vous pouvez inscrire une resp. un ou plusieurs propriétaires foncières resp. propriétaires fonciers par site de consommation.",
    TOOLTIP: "Si une propriétaire foncière existante ou un propriétaire foncier existant quitte le RCP et qu’une propriétaire foncière existante ou nouvelle ou un propriétaire foncier existant ou nouveau le/la remplace dans le RCP, le formulaire «Annonce de mutation du propriétaire foncier» doit nous être remis sans délai. Saisir le changement de propriétaire foncier Veuillez sélectionner la propriétaire foncière existante ou le propriétaire foncier existant qui quitte le RCP. Chaque changement de propriétaire foncier doit faire l’objet d’une annonce de mutation séparée.",
    DEREGISTER: "désinscrire",
    BACK_LINK: "« Retour à la sélection de la mutation",
    PLACE_OF_CONSUMPTION: "Site de consommation concerné: ",
    LANDOWNER: "Propriétaires foncières/fonciers à désinscrire",
    NEW_LANDOWNER: "Veuillez saisir les données relatives à la nouvelle propriétaire foncière ou au nouveau propriétaire foncier",
    INPUT_CONFIRMATION: "Confirmation de saisie",
  },
  ACTIVATE_MIGRATED_APPLICATIONS_DIALOG: {
    INTRO_1: "Ici, vous pouvez mettre en ligne sur ce portail tous vos contrats RCP papier (regroupement de consommation propre = RCP)",
    INTRO_12: "établis avant août 2022.",
    INTRO_13: " Veuillez saisir le numéro de client dans le champ ci-dessous et sélectionner les contrats Papier RCP dont vous vous occupez en tant que personne de contact compétente. Vous trouverez le numéro de client sur votre facture RCP.",
    INTRO_2: "Après la mise en ligne réussie des contrats RCP papier, vous pouvez soumettre des mutations pour les différents regroupements dans le cadre de la consommation propre via ce portail.",
    INTRO_3: "Remarque : La mise en ligne pour les contrats RCP n’est pas destinée aux demandes RCP qui ont déjà été soumises via ce portail.",
    GP_NUMBER: "Numéro client",
    GP_NUMBER_TOOLTIP: "Vous trouverez le numéro client sur votre facture RCP.",
    GP_NUMBER_CONFIRMATION: "Confirmation du numéro client",
    INPUT_CONFIRMATION: "Confirmation de saisie",
    CONFIRM_TEXT: "Veuillez confirmer la prise en charge et la mise en ligne des contrats RCP mentionnés par Oui/Non.",
    RADIO_BUTTONS: {
      YES: "Oui",
      NO: "Non",
    },
    ACTIVATION_MISSING: "Il manque une sélection. Veuillez confirmer avec Oui/Non.",
    ACCEPTANCE_INFO: "Les propriétaires foncières et propriétaires fonciers du regroupement dans le cadre de la consommation propre et l’interlocutrice compétente ou l’interlocuteur compétent déclarent avoir pris connaissance, compris et accepté les Conditions particulières de BKW Energie SA Regroupement dans le cadre de la consommation propre (RCP) ainsi que les prescriptions d’entreprise actuelles et en vigueur.",
    LINK_TITLE: "Lien vers les CP RCP et autres CG",
    LINK_TEXT: "CCG et questions juridiques - BKW",
    LINK: "https://www.bkw.ch/fr/ccg-et-questions-juridiques",
    AGREEMENT_LABEL: "J’approuve / Nous approuvons les Conditions particulières de BKW Energie SA Regroupement dans le cadre de la consommation propre (RCP).",
    NOT_FOUND: {
      APPLICATIONS_NOT_FOUND: "Nous n'avons pas trouvé de contrats RCP à mettre en ligne pour votre numéro client.",
      MISSING: "Il vous manque des contrats RCP pour la mise en ligne?",
      FILL_IN: "Veuillez indiquer les données concernant les contrats RCP manquants.",
      FILL_IN_TEXT: "ne s'applique qu'aux clients ayant d'anciens contrats papier",
      CONNECTION_OBJECT_NUMBER: "Numéro d'objet raccordé",
      ADDRESS: "Adresse du RCP (rue, numéro, NPA, lieu)",
      ADD_APPLICATION: "Saisir un autre contrat RCP",
      REMOVE_APPLICATION: "Supprimer le contrat RCP",
      COMMENT: "Avez-vous d'autres remarques?",
      INFO: "Vos indications concernant les contrats RCP manquants et vos remarques seront transmises lors de \"Soumettre l'annonce\" au collaborateur compétent, qui prendra contact avec vous. Le traitement prendra un certain temps. Nous vous prions de bien vouloir patienter.",
    },
    CANCEL: "Annuler",
    COMPLETE: "Soumettre l'annonce",
  },
  DOCUMENT_DOWNLOAD: {
    NOT_FOUND: "Le document n’a pas pu être trouvé.",
    ERROR: "Une erreur s’est produite.",
    HINT: "Remarque",
    HINT_TEXT_1: "Si le document ne s’ouvre pas automatiquement, vous devriez désactiver le bloqueur de fenêtres publicitaires intempestives. En haut à droite de votre navigateur, vous pouvez désactiver le bloqueur de fenêtres publicitaires intempestives.",
    HINT_TEXT_2: "Vous pouvez également cliquer sur le lien ci-dessous pour ouvrir le document.",
    OPEN_DOCUMENT: "Ouvrir le document",
  },
  APPLICATION_CREATION_HINT: {
    TITLE: "Attention",
    TEXT_1_1: "Le formulaire de demande ne peut être soumis que par",
    TEXT_1_2: "l’interlocutrice compétente resp. l’interlocuteur compétent mandanté(e)",
    TEXT_1_3: "par les propriétaires foncières et propriétaires fonciers.",
    TEXT_2_1: "Le login est spécifique au client. Les changements ou mutations ultérieurs doivent être annoncés par ce portail (login).",
  },
  BACK_LINK: "« Retour à vos demandes",
};
