import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BfcConfigurationService } from "@bfl/components/configuration";
import { Observable, of } from "rxjs";
import { Application } from "../../generated/zev-backend/model/application";
import { PdfType } from "../pdf-helper/pdf-type.enum";
import { PageRequestParams } from "../../application-creation/application-overview/application-params.interface";
import { Sort } from "@angular/material/sort";
import { PageApplication } from "../../generated/zev-backend/model/pageApplication";
import { MigratedApplicationsStatusUpdate } from "../../generated/zev-backend/model/migratedApplicationsStatusUpdate";
import { DatePipe } from "@angular/common";
import { UploadedFile } from "../../generated/zev-backend/model/uploadedFile";
import { catchError } from "rxjs/operators";
import { UserDetail } from "../../generated/zev-backend/model/userDetail";

@Injectable()
export class ApplicationService {
  private readonly apiUrl;

  private readonly userApiUrl;

  private readonly documentTypeParameter: string = "documentType";

  private readonly documentIds: string = "documentIds";

  constructor(private httpClient: HttpClient,
    private bfcConfigurationService: BfcConfigurationService,
    private datePipe: DatePipe) {
    this.apiUrl = this.bfcConfigurationService.configuration.apiUrl + "/applications";
    this.userApiUrl = this.bfcConfigurationService.configuration.apiUrl + "/me/applications";
  }

  getAllApplications(pageFilter: PageRequestParams): Observable<PageApplication> {
    return this.httpClient.get<PageApplication>(this.apiUrl, { params: this.getPageAsParams(pageFilter) });
  }

  getUserApplications(pageFilter: PageRequestParams): Observable<PageApplication> {
    return this.httpClient.get<PageApplication>(this.userApiUrl, { params: this.getPageAsParams(pageFilter) });
  }

  getApplication(applicationId: string): Observable<Application> {
    return this.httpClient.get<Application>(this.apiUrl + "/" + applicationId);
  }

  deleteDocHubDocumentForApplication(applicationId: string, documentId: string): Observable<void> {
    return this.httpClient.delete<void>(this.apiUrl + "/" + applicationId + "/document/" + documentId);
  }

  deleteDocHubDocumentsForApplication(applicationId: string, documentIds: string[]): Observable<void> {
    const params: HttpParams = new HttpParams().set(this.documentIds, documentIds.join(","));
    return this.httpClient.delete<void>(this.apiUrl + "/" + applicationId + "/documents", { params: params });
  }

  saveApplication(application: Application): Observable<Application> {
    return this.httpClient.post<Application>(this.apiUrl, application);
  }

  updateApplication(application: Application): Observable<Application> {
    return this.httpClient.put<Application>(this.apiUrl, application);
  }

  updateMigratedApplications(migratedApplicationsStatusUpdates: MigratedApplicationsStatusUpdate[]): Observable<void> {
    return this.httpClient.post<void>(this.apiUrl + "/update-migration-status", migratedApplicationsStatusUpdates);
  }

  getMigrationCount(): Observable<number> {
    return this.httpClient.get<number>(this.apiUrl + "/migration-count");
  }

  getMigratedApplications(migrationIdentificationCode: string): Observable<Application[]> {
    return this.httpClient.get<Application[]>(this.apiUrl + "/migration/" + migrationIdentificationCode?.trim());
  }

  triggerMigrationApplications(): Observable<void> {
    return this.httpClient.post<void>(this.apiUrl + "/migrate-applications", null);
  }

  checkMigrationStatus(): Observable<boolean> {
    return this.httpClient.get<boolean>(this.apiUrl + "/migration-status");
  }

  uploadDocumentsToApplication(
    applicationId: string, applicationDocuments: UploadedFile[], documentType: PdfType): Observable<Application> {
    const params: HttpParams = new HttpParams().set(this.documentTypeParameter, documentType);

    return this.httpClient.patch(`${this.apiUrl}/${applicationId}/documents`, applicationDocuments, { params: params });
  }

  deleteApplication(applicationId: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.apiUrl}/${applicationId}`);
  }

  getDisplayId(application: Application, isForTable: boolean = false) {
    let displayId: string = "";

    if (!!application?.applicationId) {
      if (isForTable) {
        displayId = "ZEV-";
      }
      displayId = displayId.concat(this.addPadForId(application.applicationId));
      if (!!application.revisionId) {
        displayId = displayId.concat(`.${application.revisionId}`);
      }
      if (!!application.mutationId) {
        displayId = displayId.concat(`.${application.mutationId}`);
      }
    }

    return displayId;
  }

  addPadForId(id: number) {
    // the ID should be shown with max 5 Characters with leading zeros
    return String(id).padStart(5, "0");
  }

  getNextFormNumber(titles: string[]): number {
    // additional form, so begin from 2
    let formNumber: number = 2;

    const titlesLength: number = titles.length;

    if (titlesLength > 0) {
      const lastIndexOfBlank: number = titles[titlesLength - 1].lastIndexOf(" ");
      const lastNumber: number = +titles[titlesLength - 1].substring(lastIndexOfBlank);
      formNumber = lastNumber + 1;
    }

    return formNumber;
  }

  getAddress(application: Application): string {
    let address: string = "";
    const connectionObjectDetails = application?.connectionObjectDetails;

    if (connectionObjectDetails?.street) {
      address = `${connectionObjectDetails?.street} ${connectionObjectDetails?.houseNumber}, 
    ${connectionObjectDetails?.postalCode} ${connectionObjectDetails?.city}`;
    }

    return address;
  }

  formatDate(inputDate: Date): string {
    let formattedDate: string = "";

    if (inputDate) {
      formattedDate = this.datePipe.transform(inputDate, this.bfcConfigurationService.configuration.dateFormat);
    }
    return formattedDate;
  }

  getUserDetailByApplicationId(applicationId: string): Observable<UserDetail> {
    return this.httpClient.get<UserDetail>(`${this.apiUrl}/${applicationId}/userdetail`).pipe(
      catchError(() => {
        return of(null);
      }),
    );
  }

  private getPageAsParams(attr: PageRequestParams): HttpParams {
    let httpParams = new HttpParams();

    httpParams = httpParams.set("page", String(attr.page.index));
    httpParams = httpParams.set("pageSize", String(attr.page.size));

    // sortBy=name;ASC
    if (attr.sortBy && attr.sortBy.length > 0) {
      attr.sortBy.forEach((sort: Sort) => {
        httpParams = httpParams.append("sortBy", `${sort.active};${sort.direction}`);
      });
    }

    if (!!attr.filter) {
      httpParams = httpParams.append("searchField", attr.filter.searchField);
      httpParams = httpParams.append("searchValue", attr.filter.searchValue);
      httpParams = httpParams.append("selectedType", attr.filter.selectedTypeOption);
      httpParams = httpParams.append("selectedStatus", attr.filter.selectedStatusOption);
    }

    return httpParams;
  }

}
